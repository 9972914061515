import React, { useState } from 'react';
import Select from 'react-select';
import { EyeOpen, EyeClosed, Plus, Minus } from 'svg';

export default function Input(props) {
    const {
        type,
        label,
        placeholder,
        id,
        value,
        setValue,
        rows,
        autoRows,
        disabled,
        invisible,
        checkedValue,
        error,
        hideRevealPass,
        step,
        min,
        max,
        limit,
        options,
        isClearable,
        isSearchable,
        isMulti,
        onKeyUp,
        onBlur,
        allowAddIngredient,
        onAddIngredient,
    } =
        props;

    const [showContent, setShowContent] = useState(false);
    const [open, setOpen] = useState(false);

    let inputBody = null;
    switch (type) {
        case "textarea":
            inputBody = (
                <textarea
                    id={id}
                    rows={rows ? rows : "4"}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => {
                        if (!limit || e.target.value.length <= limit) {
                            setValue(e.target.value);
                        } else if (limit && e.target.value.length > limit) {
                            setValue(e.target.value.substring(0, 255));
                        }
                    }}
                    onPaste={(e) => {
                        if (!limit || e.target.value.length <= limit) {
                            setValue(e.target.value);
                        } else if (limit && e.target.value.length > limit) {
                            setValue(e.target.value.substring(0, 255));
                        }
                    }}
                    onKeyUp={() => {
                        if (autoRows) {
                            let element = document.getElementById(id);
                            element.style.height = element.scrollHeight + 6 + 'px';
                        } else if (onKeyUp) {
                            onKeyUp()
                        }
                    }}
                />
            )
            break;
        case 'number':
            inputBody = (
                <input
                    id={id}
                    type="number"
                    step={step}
                    min={min}
                    max={max}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onKeyUp={onKeyUp}
                />
            );
            break;

        case 'deco-number':
            inputBody = (
                <div className='deco-number-input'>
                    <button className='carousel-btn' onClick={(e) => {
                        e.preventDefault();
                        if (step ? value - step >= 1 : value >= 2) {
                            setValue(parseInt(value) - (step ? step : 1));
                        }
                    }}>

                        <Minus />
                    </button>
                    <input
                        id={id}
                        type="text"
                        step={step}
                        min={min}
                        max={max}
                        placeholder={placeholder}
                        value={value}
                        disabled={disabled}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        onKeyUp={onKeyUp}
                    />

                    <button className='carousel-btn' onClick={(e) => {
                        e.preventDefault();
                        setValue(parseInt(value) + (step ? step : 1));

                    }}>
                        <Plus />
                    </button>
                </div>
            );
            break;
        case 'select':
            inputBody = (
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder={placeholder}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    isDisabled={disabled}
                    isMulti={isMulti}
                    name={id}
                    id={id}
                    options={options}
                    value={value}
                    onChange={(e) => {
                        setValue(e);
                    }}
                    menuIsOpen={open}
                    onMenuOpen={() => setOpen(true)}
                    onMenuClose={() => setOpen(false)}
                    closeMenuOnSelect={true}
                    noOptionsMessage={(eventValue) => {
                        if (allowAddIngredient) {
                            return (<div>
                                <label>Žiadne výsledky.</label><button className='btn-link' onClick={(e) => { e.preventDefault(); onAddIngredient(eventValue.inputValue); setOpen(false) }}>Pridať</button>
                            </div>)
                        }
                    }}
                />
            )
            break;
        case 'password':
            inputBody = (
                <input
                    id={id}
                    type={showContent ? "text" : "password"}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                />
            );
            break;
        case 'radio':
            inputBody = (
                <div className='radio-group'>
                    {
                        options.map((option) => (
                            <div key={option.value}>
                                <input
                                    type="radio"
                                    id={`radio-btn-${option.value}`}
                                    name={`radio-btn-${option.value}`}
                                    value={option.value}
                                    checked={checkedValue === option.value}
                                    onChange={(e) => {
                                        setValue(option);
                                    }}
                                />
                                <label htmlFor={`radio-btn-${option.value}`}>{option.label}</label>
                            </div>
                        ))
                    }
                </div>
            );
            break;
        default:
            inputBody = (
                <input
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    type={type}
                    onChange={(e) => {
                        if (!limit || e.target.value.length <= limit) {
                            setValue(e.target.value);
                        } else if (limit && e.target.value.length > limit) {
                            setValue(e.target.value.substring(0, 255));
                        }
                    }}
                    onPaste={(e) => {
                        if (!limit || e.target.value.length <= limit) {
                            setValue(e.target.value);
                        } else if (limit && e.target.value.length > limit) {
                            setValue(e.target.value.substring(0, 255));
                        }
                    }}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                />
            );
            break;
    }



    return (
        <div className={invisible ? 'form-group-invisible' : 'form-group'}>
            <label htmlFor={id} style={!label ? { display: 'none' } : {}}>
                {label} {limit && <span className={'char-limit ' + (value.length > limit - 25 ? "warning " : "") + (value.length > limit - 5 ? "danger " : "")}>{`${value.length} / ${limit}`}</span>}
            </label>
            {inputBody}
            {
                type === "password" && !hideRevealPass &&
                <button className={showContent ? 'btn-hide' : 'btn-show'} onClick={(e) => {
                    e.preventDefault()
                    setShowContent(!showContent)
                }}
                >
                    {
                        !showContent &&
                        <EyeOpen />
                    }
                    {
                        showContent &&
                        <EyeClosed />
                    }
                </button>
            }
            {error && <p className='error m-l-15 m-r-15 m-b-0'>{typeof error === 'string' ? error : error.join(" ")}</p>}
        </div >
    );
}
