import React, { useState, useMemo, useEffect } from 'react';
import {
    Spinner
} from 'reactstrap';
import { useQuery, useSubscription, useMutation } from '@apollo/client';

import { baseStages } from 'constants';
import Input from 'components/Input';
import RecipeHeader from 'components/RecipeHeader';
import {
    UPDATE_RECIPE_STEP_2,
    INGREDIENTS,
    INGREDIENTS_SUBSCRIPTION,
} from 'queries';
import { getItemFromTranslation, getLabel, useGetMyData } from 'utils';
import { DotsSix, Plus, PlusCircle, TrashCircle } from 'svg';
import { useTranslation } from 'react-i18next';

let fakeID = -1;

const emptyIngredient = {
    ingredient: null,
    unit: null,
    amount: 0,
    alternatives: [],
}

const emptyPreparation = {
    id: fakeID--,
    order: 0,
    text: "",
}

const initialDnDState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: []
}

const incrementOptions = Object.freeze([
    Object.freeze({
        value: "serving",
        label: "byOne"
    }),
    Object.freeze({
        value: "multiplier",
        label: "multiplicatively"
    }),
]);

const UNITS = [
    {
        label: 'piece',
        value: 'piece',
    },
    {
        label: 'liter',
        value: 'liter',
    },
    {
        label: 'kilo',
        value: 'kilo',
    },
    {
        label: 'teaspoon',
        value: 'teaspoon',
    },
    {
        label: 'pinch',
        value: 'pinch',
    },
    {
        label: 'tablespoon',
        value: 'tablespoon',
    },
    {
        label: 'handful',
        value: 'handful',
    },
];


export default function RecipeInfo(props) {
    const {
        recipeId,
        recipe,
        setSaving,
        onNext,
        onPrevious,
        closeModal,
    } = props;

    const { t } = useTranslation();
    const currentChef = useGetMyData();

    const [updateRecipeStep2] = useMutation(UPDATE_RECIPE_STEP_2);

    const [servings, setServings] = useState(2);
    const [servingIncrementType, setServingIncrementType] = useState({
        ...incrementOptions[0],
        label: t(incrementOptions[0].label)
    });
    const [ingredients, setIngredients] = useState([]);
    const [deleteIngredients, setDeleteIngredients] = useState([]);
    const [newIngredient, setNewIngredient] = useState(emptyIngredient);
    const [preparation, setPreparation] = useState([emptyPreparation]);
    const [dragAndDrop, setDragAndDrop] = React.useState(initialDnDState);

    const {
        data: ingredientsData,
        loading: ingredientsLoading,
        refetch: ingredientsRefetch,
    } = useQuery(INGREDIENTS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(INGREDIENTS_SUBSCRIPTION, {
        onData: () => {
            ingredientsRefetch();
        },
    });

    useEffect(() => {
        if (recipe && currentChef) {
            setServings(recipe.servings)
            let newSerivingIncrementType = incrementOptions.find((option) => option.value === recipe.servingIncrementType)
            setServingIncrementType(newSerivingIncrementType ? {
                ...newSerivingIncrementType,
                label: t(newSerivingIncrementType.label)
            } : {
                ...incrementOptions[0],
                label: t(incrementOptions[0].label)
            })
            setIngredients(recipe.recipeIngredients.map((recipeIngredient, index) => ({
                recipeIngredientId: recipeIngredient.id,
                id: recipeIngredient.ingredient.id,
                ingredient: {
                    ...recipeIngredient.ingredient,
                    value: recipeIngredient.ingredient.id,
                    label: getLabel(recipeIngredient.ingredient.translations, currentChef.language.id),
                    units: recipeIngredient.ingredient.units.map((unit) => ({
                        label: t(`${unit}`),
                        value: unit,
                    }))
                },
                unit: {
                    label: t(`${recipeIngredient.unit}`),
                    value: recipeIngredient.unit,
                },
                amount: recipeIngredient.amount,
                alternatives: recipeIngredient.alternatives.map((alternative) => ({
                    value: alternative.id,
                    label: getLabel(alternative.translations, currentChef.language.id, "title", "noTitle", t),
                    order: alternative.order,
                })),
            })));
            const prep = getItemFromTranslation(recipe.recipeTranslations, currentChef.language.id, "preparation");
            setPreparation(prep && prep.length > 0 ? prep.map((preparationStep, index) => ({
                id: preparationStep.id,
                order: preparationStep.order,
                text: preparationStep.text,
            })) : [emptyPreparation])
        } else {
            setServings(2);
            setServingIncrementType({
                ...incrementOptions[0],
                label: t(incrementOptions[0].label)
            })
            setIngredients([]);
            setDeleteIngredients([]);
            setNewIngredient(emptyIngredient);
            setPreparation([emptyPreparation]);
        }
    }, [recipe, currentChef, t]);

    const onAddRecipeIngredient = () => {
        setIngredients([...ingredients, {
            ...newIngredient,
            amount: !newIngredient.amount ? 0 : newIngredient.amount,
            id: newIngredient.ingredient.id,
            add: true,
        }]);

        setNewIngredient(emptyIngredient);
    }

    const onDeleteRecipeIngredient = (ingredient) => {
        setIngredients(ingredients.filter((ing) => ing.id !== ingredient.id));
        if (ingredient.id) {
            setDeleteIngredients([...deleteIngredients, ingredient.recipeIngredientId]);
        }
    }

    const onDragStart = (event) => {
        const initialPosition = Number(event.currentTarget.dataset.position);

        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: initialPosition,
            isDragging: true,
            originalOrder: preparation
        });
    }

    const onDragOver = (event) => {
        // the default is to cancel out the drop
        event.preventDefault();

        let newList = dragAndDrop.originalOrder;
        const draggedFrom = dragAndDrop.draggedFrom;

        const draggedTo = Number(event.currentTarget.dataset.position);

        const itemDragged = newList[draggedFrom];

        const remainingItems = newList.filter((item, index) => index !== draggedFrom);

        newList = [
            ...remainingItems.slice(0, draggedTo),
            itemDragged,
            ...remainingItems.slice(draggedTo)
        ];

        if (draggedTo !== dragAndDrop.draggedTo) {
            setDragAndDrop({
                ...dragAndDrop,

                updatedOrder: newList.map((item, index) => ({ ...item, order: index })),
                draggedTo: draggedTo
            })
        }
    }

    const onDrop = () => {
        setPreparation(dragAndDrop.updatedOrder);
        setDragAndDrop({
            ...dragAndDrop,
            draggedFrom: null,
            draggedTo: null,
            isDragging: false
        });
    }

    const updateRecipeStep2Func = () => {
        // setSaving(true);

        let variables = {
            id: recipeId,
            servingIncrementType: servingIncrementType.value,
            servings: parseInt(servings),
            preparation: preparation.map((prep) => ({
                text: prep.text,
                order: prep.order,
            })),
            addIngredients: ingredients.filter((ing) => ing.add).map((ing) => ({
                amount: parseFloat(ing.amount),
                ingredientId: ing.ingredient.id,
                unit: ing.unit.value,
                alternatives: [...ing.alternatives].map((alt, index) => ({ id: alt.id, order: index })),
            })),
            updateIngredients: ingredients.filter((ing) => ing.edit && !ing.add).map((ing) => ({
                id: ing.recipeIngredientId,
                amount: parseFloat(ing.amount),
                ingredientId: ing.ingredient.id,
                unit: ing.unit.value,
                alternatives: [...ing.alternatives].map((alt, index) => ({ id: alt.id, order: index })),
            })),
            deleteIngredients: deleteIngredients,
        };

        updateRecipeStep2({
            variables,
        }).then(() => {
            setSaving(false);
            onNext();
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }

    const ingredientsOptions = useMemo(() => {
        const getLocalLabel = (ingredient) => {
            const currentChefTranslation =
                ingredient.translations.find(
                    (translation) =>
                        translation.language.id ===
                        currentChef.language.id
                );
            if (currentChefTranslation) {
                return currentChefTranslation.title;
            }
            if (ingredient.translations.length > 0) {
                return ingredient.translations
                    .map(
                        (translation) =>
                            `${translation.title} (${translation.language.languageCode})`
                    )
                    .join(', ');
            }
            return t('noTitle');
        };

        return ingredientsData && ingredientsData.ingredients ? ingredientsData.ingredients
            .map((ingredient) => ({
                ...ingredient,
                value: ingredient.id,
                label: getLocalLabel(ingredient),
                units: ingredient.units.map((unit) => ({
                    label: t(`${unit}`),
                    value: unit,
                })),
            })) : [];
    }, [ingredients, ingredientsData, currentChef, t]);

    if (
        ingredientsLoading
    ) {
        return (
            <div className='modal-body-recipe'>
                <RecipeHeader stage={baseStages.recipe} closeModal={closeModal} />
                <div className='spinner'>
                    <Spinner />
                </div>
            </div>
        );
    }

    const getAlternativeOnIndex = (ingredient, index) => {
        let alternatives = ingredient.alternatives;
        if (index >= alternatives.length) {
            return null;
        }
        return alternatives[index];
    }

    const renderIngredientRow = (ingredient) => (
        <tr key={ingredient.id ? ingredient.id : "new"}>
            <td>
                <Input
                    invisible={ingredient.id}
                    id="new-ingredient-type"
                    type="select"
                    placeholder={""}
                    isClearable={false}
                    isSearchable={true}
                    options={ingredientsOptions}
                    value={ingredient.ingredient}
                    allowAddIngredient={ingredient.id ? false : true}
                    onAddIngredient={(value) => {
                        const newFakeId = fakeID--;
                        setNewIngredient({
                            ...newIngredient,
                            ingredient: {
                                units: UNITS,
                                value: newFakeId,
                                id: newFakeId,
                                label: value,
                            },
                        })
                    }}
                    setValue={(e) => {
                        if (ingredient.id) {
                            setDeleteIngredients([...deleteIngredients, ingredient.recipeIngredientId]);
                            setIngredients(ingredients.map((ing) => {
                                if (ing.id === ingredient.id) {
                                    return ({
                                        ...ing,
                                        ingredient: e,
                                        unit: e.units.find((unit) => unit.value === ingredient.unit?.value) ? ingredient.unit : null,
                                        add: true,
                                        alternatives: []
                                    })
                                }
                                return ing;
                            }));
                        } else {
                            setNewIngredient({
                                ...newIngredient,
                                ingredient: e,
                                unit: e.units.find((unit) => unit.value === ingredient.unit?.value) ? ingredient.unit : null,
                            })
                        }
                    }}
                />
            </td>
            <td>
                <Input
                    invisible={ingredient.id}
                    id="new-ingredient-amount"
                    type="number"
                    min={0}
                    step={0.05}
                    value={ingredient.amount}
                    setValue={(targetValue) => {
                        if (ingredient.id) {
                            setIngredients(ingredients.map((ing) => {
                                if (ing.id === ingredient.id) {
                                    return ({
                                        ...ing,
                                        amount: targetValue,
                                        edit: true,
                                    })
                                }
                                return ing;
                            }));
                        } else {
                            setNewIngredient({
                                ...newIngredient,
                                amount: targetValue
                            })
                        }
                    }}
                />
            </td>
            <td>
                <Input
                    invisible={ingredient.id}
                    id="new-ingredient-unit"
                    type="select"
                    placeholder={""}
                    isClearable={false}
                    isSearchable={true}
                    options={ingredient.ingredient ? ingredient.ingredient.units : []}
                    value={ingredient.unit}
                    setValue={(e) => {
                        if (ingredient.id) {
                            setIngredients(ingredients.map((ing) => {
                                if (ing.id === ingredient.id) {

                                    return ({
                                        ...ing,
                                        unit: e,
                                        edit: true,
                                        alternatives: ing.alternatives.filter((alt) => alt.units.some((unit) => unit.value === e.value))
                                    })
                                }
                                return ing;
                            }));
                        } else {
                            setNewIngredient({
                                ...newIngredient,
                                unit: e,
                                alternatives: newIngredient.alternatives.filter((alt) => alt.units.some((unit) => unit.value === e.value))
                            })
                        }
                    }}
                />
            </td>
            <td>
                <Input
                    invisible={ingredient.id}
                    id="new-ingredient-alternative-1"
                    type="select"
                    placeholder={""}
                    isClearable={true}
                    isSearchable={true}
                    options={[...ingredientsOptions].filter((ing) => {
                        if (ingredient.unit) {
                            return ing.units.some((unit) => unit.value === ingredient.unit.value) && ing.id !== ingredient.ingredient.id;
                        }
                        return false;
                    })}
                    value={getAlternativeOnIndex(ingredient, 0)}
                    setValue={(e) => {
                        if (ingredient.id) {
                            setIngredients(ingredients.map((ing) => {
                                if (ing.id === ingredient.id) {
                                    let newAlternatives = [...ing.alternatives];
                                    if (newAlternatives.length === 0) {
                                        newAlternatives.push(e);
                                    } else {
                                        newAlternatives[0] = e;
                                    }
                                    newAlternatives = newAlternatives.filter((alt) => alt);
                                    return ({
                                        ...ing,
                                        alternatives: newAlternatives,
                                        edit: true,
                                    })
                                }
                                return ing;
                            }));
                        } else {
                            let newAlternatives = [...newIngredient.alternatives];
                            if (newAlternatives.length === 0) {
                                newAlternatives.push(e);
                            } else {
                                newAlternatives[0] = e;
                            }
                            newAlternatives = newAlternatives.filter((alt) => alt);
                            setNewIngredient({
                                ...newIngredient,
                                alternatives: newAlternatives,
                            })
                        }
                    }}
                />
            </td>
            <td>
                <Input
                    invisible={ingredient.id}
                    id="new-ingredient-alternative-2"
                    type="select"
                    placeholder={""}
                    isClearable={true}
                    isSearchable={true}
                    options={[...ingredientsOptions].filter((ing) => {
                        if (ingredient.unit) {
                            return ing.units.some((unit) => unit.value === ingredient.unit.value) && ing.id !== ingredient.ingredient.id;
                        }
                        return false;
                    })}
                    value={getAlternativeOnIndex(ingredient, 1)}
                    setValue={(e) => {
                        if (ingredient.id) {
                            setIngredients(ingredients.map((ing) => {
                                if (ing.id === ingredient.id) {
                                    let newAlternatives = [...ing.alternatives];
                                    if (newAlternatives.length === 1) {
                                        newAlternatives.push(e);
                                    } else {
                                        newAlternatives[1] = e;
                                    }
                                    newAlternatives = newAlternatives.filter((alt) => alt);
                                    return ({
                                        ...ing,
                                        alternatives: newAlternatives,
                                        edit: true,
                                    })
                                }
                                return ing;
                            }));
                        } else {
                            let newAlternatives = [...newIngredient.alternatives];
                            if (newAlternatives.length === 1) {
                                newAlternatives.push(e);
                            } else {
                                newAlternatives[1] = e;
                            }
                            newAlternatives = newAlternatives.filter((alt) => alt);
                            setNewIngredient({
                                ...newIngredient,
                                alternatives: newAlternatives,
                            })
                        }
                    }}
                />
            </td>
            <td>
                {
                    !ingredient.id &&
                    <button
                        className='carousel-btn'
                        disabled={
                            !newIngredient.ingredient ||
                            !newIngredient.unit
                        }
                        onClick={onAddRecipeIngredient}
                    >
                        <PlusCircle />
                    </button>
                }
                {
                    ingredient.id &&
                    <button
                        className='carousel-btn'
                        style={{ paddingLeft: "5px" }}
                        onClick={() => { onDeleteRecipeIngredient(ingredient) }}
                    >
                        <TrashCircle />
                    </button>
                }
            </td>
        </tr>
    )

    const renderIngredients = () => (
        <table>
            <thead>
                <tr>
                    <th width="">{t('ingredient')}</th>
                    <th width="11.7%">{t('amount')}</th>
                    <th width="17.6%">{t('unit')}</th>
                    <th width="17.6%">{`${t('alternative')} 1`}</th>
                    <th width="17.6%">{`${t('alternative')} 2`}</th>
                    <th width="1%"></th>
                </tr>
            </thead>
            <tbody>
                {
                    ingredients.map(renderIngredientRow)
                }
                {renderIngredientRow(newIngredient)}
            </tbody>
        </table>
    )

    const renderUpperForm = () => (
        <>
            <div className='language-title-row'>
                <Input
                    label={t('servings')}
                    id="recipe-servings"
                    type="deco-number"
                    min={1}
                    step={1}
                    placeholder={""}
                    value={servings}
                    setValue={setServings}
                />
                <Input
                    label={t('servingIncrement')}
                    id="serving-increment"
                    type="radio"
                    checkedValue={servingIncrementType?.value}
                    value={servingIncrementType}
                    setValue={setServingIncrementType}
                    options={incrementOptions.map((option) => ({
                        ...option,
                        label: t(option.label)
                    }))}
                />
            </div>
        </>
    )

    const renderPreparationStep = (step, index) => (
        <li
            key={index}
            data-position={index}
            draggable={true}
            onDragStart={onDragStart}
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <button
                tabIndex={-1}
                className='carousel-btn' onClick={(e) => {
                    e.preventDefault();

                    setPreparation(preparation.toSpliced(index + 1, 0, {
                        ...emptyPreparation,
                        order: preparation.length
                    }))
                }}>
                <Plus />
            </button>
            <button
                tabIndex={-1}
                className='carousel-btn m-r-32 m-l-12' onClick={() => { }}>
                <DotsSix />
            </button>
            <label className='m-r-12'>{`Krok ${index + 1}`}</label>
            <Input
                id="recipe-servings"
                type="text"
                placeholder={""}
                limit={255}
                value={step.text}
                setValue={(targetValue) => {
                    setPreparation(preparation.map((prep) => {
                        if (prep.order === step.order) {
                            return ({
                                ...prep,
                                text: targetValue
                            })
                        }
                        return prep;
                    }))
                }}
            />
            {
                preparation.length > 1 &&
                <button
                    tabIndex={-1}
                    className='carousel-btn p-l-12'
                    onClick={(e) => {
                        e.preventDefault();
                        setPreparation(preparation.toSpliced(index, 1));
                    }}
                >
                    <TrashCircle />
                </button>
            }
        </li>
    )

    const renderLowerForm = () => (
        <div className='preparation'>
            <label>{t("description")}</label>
            <ul>
                {
                    preparation.map(renderPreparationStep)
                }
            </ul>
        </div>
    )

    const renderButtonRow = () => (
        <div className='button-row'>
            <button className="btn-solid" onClick={() => {
                onPrevious();
            }}
            >
                {t('back')}
            </button>
            <button className="btn-solid" onClick={(e) => {
                e.preventDefault();
                updateRecipeStep2Func()
            }}
            >
                {t('continueArrow')}
            </button>
        </div>
    )

    return (
        <div className='modal-body-recipe'>
            <div>
                <RecipeHeader stage={baseStages.recipe} closeModal={closeModal} />
                <form className='recipe-form-small'>
                    {renderUpperForm()}
                    <hr />
                    {renderIngredients()}
                    <hr />
                    {renderLowerForm()}
                    {renderButtonRow()}
                </form>
            </div>
        </div>
    );
}
