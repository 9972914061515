import { gql } from '@apollo/client';

export const REGISTER_POTENTIAL_CHEF = gql`
mutation registerPotentialChef(
  $nickname: String!, 
  $name: String!, 
  $surname: String!, 
  $email: String!, 
  $password: String!, 
  $phoneNumber: String!, 
  $languageId: Int!, 
  $youtube: String, 
  $instagram: String
) {
  registerPotentialChef(
    nickname: $nickname, 
    name: $name, 
    surname: $surname, 
    email: $email, 
    password: $password, 
    phoneNumber: $phoneNumber, 
    languageId: $languageId, 
    youtube: $youtube, 
    instagram: $instagram
  ) {
    id
  }
}
`;

export const CONFIRM_EMAIL = gql`
mutation confirmEmailPotentialChef($confirmationCode: String!) {
  confirmEmailPotentialChef(confirmationCode: $confirmationCode)
}`

export const RESEND_POTENTIAL_CHEF_VERIFICATION_EMAIL = gql`
    mutation resendPotentialChefEmailVerification($email: String) {
        resendPotentialChefEmailVerification(email: $email)
    }
`;
