import AboutUsSectionContent from "./AboutUsSectionContent";
const AboutUsBackground = () => {
  return (
    <div className="about-us-section-background">
      <AboutUsSectionContent />
    </div>
  );
};

export default AboutUsBackground;
