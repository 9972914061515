export const ArrowRight = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <g clipPath="url(#clip0_1_7979)">
            <path d="M29 0H0V29H29V0Z" fill="#FFCB05" />
            <path d="M15.7429 22.841L14.3139 21.4316L20.2158 15.5297H4.92773V13.4841H20.2158L14.3139 7.60186L15.7429 6.17288L24.0819 14.5118L15.7429 22.8508V22.841Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_1_7979">
                <rect width="29" height="29" rx="14.5" fill="white" />
            </clipPath>
        </defs>
    </svg>
)