import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import sk from "./translations/sk.json";
import en from "./translations/en.json";
import cs from "./translations/cs.json";
import de from "./translations/de.json";
import ro from "./translations/ro.json";
import hu from "./translations/hu.json";
import it from "./translations/it.json";
import es from "./translations/es.json";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
    sk: {
        translation: sk
    },
    en: {
        translation: en
    },
    cs: {
        translation: cs
    },
    de: {
        translation: de
    },
    ro: {
        translation: ro
    },
    hu: {
        translation: hu
    },
    it: {
        translation: it
    },
    es: {
        translation: es
    },
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        // debug: true,
        interpolation: {
            escapeValue: false,
        }
    });


export default i18n;