export const baseStages = Object.freeze({
    media: 'media',
    recipe: 'recipe',
    extra: 'extra',
    preview: 'preview',
});

export const extraStages = Object.freeze({
    //transcription: 'transcription',
    //check: 'check',
    translation: 'translation',
    published: 'published',
});