import LandingSectionContent from "./LandingSectionContent";
import Strip from "../ui/Strip";
const LandingSection = () => {
  return (
    <section id="landing-section">
      <Strip alt="landing-strip" className="landing-section-strip" />

      <LandingSectionContent />
    </section>
  );
};

export default LandingSection;
