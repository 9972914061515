import React from 'react';
import { useGetMyData } from 'utils';
import { REST_URL } from 'configs/restAPI';
import { useTranslation } from 'react-i18next';

export const fields = Object.freeze({
    nickname: 'nickname',
    title: 'title',
    location: 'location',
    description: 'description',
});

export default function Profile() {

    const { t } = useTranslation();

    const currentChef = useGetMyData();

    return (
        <form className="profile">
            <div className="main-info">
                <div className="user-image">
                    {currentChef.iconPath && (
                        <img
                            src={`${REST_URL}/${currentChef.iconPath}-300.png?=${Math.floor(Math.random() * 10000)}`
                            }
                            alt="chef"
                        />
                    )}
                </div>
                <div className="user-data">
                    <div>
                        <h1>{currentChef.nickname}</h1>
                    </div>
                    <div>
                        <h2>
                            {currentChef.individual ?
                                t('individual') : t('company')}
                        </h2>
                    </div>
                    <div>
                        <h3>
                            {currentChef.location ??
                                t('noLocation')}
                        </h3>
                    </div>
                </div>
            </div>

            <div className="statistics">
                <div>
                    <label>
                        {currentChef.recipesCount}
                    </label>
                    <label>{t('recipes')}</label>
                </div>
                <div>
                    <label>
                        {currentChef.followersCount}
                    </label>
                    <label>{t('followers')}</label>
                </div>
                <div>
                    <label>{currentChef.ordersCount}</label>
                    <label>{t('orders')}</label>
                </div>
            </div>

            <div className="description">
                <div>
                    {currentChef.description.length > 0
                        ? currentChef.description
                        : t('noDescription')}
                </div>
            </div>

        </form>
    );
}
