import React from "react";

/*interface SwiperBtnIconProps {
  previous: () => void;
  next: () => void;
}*/

const SwiperBtnIconRight = ({ previous, next }/*: SwiperBtnIconProps*/) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="77" viewBox="0 0 78 77" fill="none">
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />

      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="77" viewBox="-25 -27 78 77" fill="none">
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" fill="black" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" fill="black" fillOpacity="0.2" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" fill="black" fillOpacity="0.2" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" fill="black" fillOpacity="0.2" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" fill="black" fillOpacity="0.2" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" stroke="black" strokeWidth="1.32712" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.32712" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.32712" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.32712" />
        <path d="M26.3193 11.7448L26.3208 11.7464C26.3399 11.7664 26.3903 11.8355 26.4361 11.9421C26.4805 12.0455 26.4932 12.1208 26.4942 12.1481C26.4919 12.2545 26.481 12.3147 26.4646 12.36C26.4501 12.4002 26.4206 12.4587 26.3374 12.5394L26.3282 12.5483L26.3193 12.5576L16.127 23.2444L16.1255 23.246C15.9007 23.4832 15.4931 23.493 15.2672 23.2748C15.0425 23.0557 15.0407 22.6359 15.2354 22.4322L15.2357 22.432L23.4175 13.8669L24.4892 12.7449H22.9377H2.1549C1.80885 12.7449 1.54442 12.4714 1.54442 12.1512C1.54442 11.831 1.80884 11.5575 2.1549 11.5575H22.9377H24.4892L23.4175 10.4356L15.2357 1.87045L15.2354 1.87021C15.0221 1.64712 15.0486 1.22254 15.2461 1.04722L15.2466 1.04677C15.5302 0.794498 15.8973 0.825512 16.1303 1.06149L26.3193 11.7448Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.32712" />
      </svg>
    </svg>
  );
};

export default SwiperBtnIconRight;
