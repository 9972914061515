import React from "react";

interface SignInBtnProps {
  setClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignInBtn = ({ setClicked }: SignInBtnProps) => {
  return (
    <div
      className="signin-btn"
      onClick={() => setClicked((prev: boolean) => !prev)}
    >
      SignIn
    </div>
  );
};

export default SignInBtn;
