import { gql } from '@apollo/client';

export const LANGUAGES_SUBSCRIPTION = gql`
    subscription languagesSubscription {
        languagesSubscription
    }
`;

export const GET_LANGUAGES = gql`
    query {
        languages {
            id
            languageCode
        }
    }
`;
