const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="207"
      height="39"
      viewBox="0 0 207 39"
      fill="none"
    >
      <g clipPath="url(#clip0_0_340)">
        <path
          d="M43.83 10.17H28.6V38.49H15.18V10.17H0V0H43.83V10.17Z"
          fill="#F24030"
        />
        <path
          d="M89.75 28.82V38.5H47.46V0H89.14V9.68H60.6V14.57H83.86V23.48H60.6V28.81H89.75V28.82Z"
          fill="#F24030"
        />
        <path d="M95.89 38.5V0H109.31V38.5H95.89Z" fill="#F24030" />
        <path
          d="M163.29 27.99C163.29 34.42 157.13 38.49 147.45 38.49H116.76V0H145.24C154.92 0 161.08 3.96 161.08 10.01C161.08 14.35 157.84 18.15 152.01 18.53C158.17 18.92 163.28 22.16 163.28 27.99H163.29ZM129.96 15.07H143.05C145.8 15.07 147.78 14.24 147.78 11.82C147.78 9.4 145.8 8.63 143.05 8.63H129.96V15.06V15.07ZM149.54 26.34C149.54 23.87 147.67 22.88 144.65 22.88H129.97V29.86H144.65C147.67 29.86 149.54 28.82 149.54 26.34Z"
          fill="#F24030"
        />
        <path
          d="M206.66 28.21V38.49H168.55V0H181.97V28.21H206.66Z"
          fill="#F24030"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_340">
          <rect width="206.66" height="38.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
