import { ReactNode } from "react";
import parse from "html-react-parser";
import { Trans } from "react-i18next";
/*
interface WhyUsItemProps {
  icon: ReactNode;
  text: string;
}*/

const WhyUsItem = ({ icon, value }/*: WhyUsItemProps*/) => {
  return (
    <div className="why-us-item">
      <div className="why-us-item-content">
        {icon}
        <Trans
          i18nKey={value}
        />
      </div>
    </div>
  );
};

export default WhyUsItem;
