import React from "react";
import AboutUsList from "./AboutUsList";
import { useTranslation, Trans } from "react-i18next";

const AboutUsSectionContent = () => {
  const { t } = useTranslation()
  return (
    <div className="about-us-section-content">
      <div className="about-us-section-wrapper">
        <h2>
          <Trans
            i18nKey='spaceToGrow'
          />
        </h2>
        <AboutUsList />
      </div>
    </div>
  );
};

export default AboutUsSectionContent;
