import QuestionsLeft from "./QuestionsLeft";
import QuestionsRight from "./QuestionsRight";
import ImageStrip from "assets/images/strip/footer_strip.png";

const QuestionsContent = () => {
  return (
    <>
      <div className="questions-content">
        <QuestionsLeft />
        <QuestionsRight />
      </div>
      <img src={ImageStrip} alt="footer-strip" className="footer-strip" />
    </>
  );
};

export default QuestionsContent;
