import React from "react";
import Strip from "../ui/Strip";
import WhyUsContent from "./WhyUsContent";

const WhyUsSection = () => {
  return (
    <section id="why-us-section">
      <Strip alt="why-us-strip" className="why-us-strip" />
      <WhyUsContent />
    </section>
  );
};

export default WhyUsSection;
