import { ReactNode } from "react";
/*
interface SwiperItemTextProps {
  className: string;
  children: ReactNode;
}
*/
const SwiperItemText = ({ className, children }/*: SwiperItemTextProps*/) => {
  return <div className={`swiper-item-text ${className}`}>{children}</div>;
};

export default SwiperItemText;
