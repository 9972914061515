export const ArrowLeft = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
        <g clipPath="url(#clip0_1_7988)">
            <path d="M0 29L29 29L29 0L2.53526e-06 -2.53526e-06L0 29Z" fill="#FFCB05" />
            <path d="M13.2571 6.159L14.6861 7.5684L8.78419 13.4703L24.0723 13.4703L24.0723 15.5159L8.78419 15.5159L14.6861 21.3981L13.2571 22.8271L4.91813 14.4882L13.2571 6.14922L13.2571 6.159Z" fill="black" />
        </g>
        <defs>
            <clipPath id="clip0_1_7988">
                <rect x="29" y="29" width="29" height="29" rx="14.5" transform="rotate(-180 29 29)" fill="white" />
            </clipPath>
        </defs>
    </svg>
)