import React from "react";
import LandingSection from "./components/landingSection/LandingSection";
import AboutUsSection from "./components/aboutUsSection/AboutUsSection";
import BannerSection from "./components/bannerSection/BannerSection";
import StarSection from "./components/starSection/StarSection";
import WhyUsSection from "./components/whyUsSection/WhyUsSection";
import BusinessSection from "./components/businessSection/BusinessSection";
import ResponsibilitySection from "./components/responsibilitySection/ResponsibilitySection";
import SwiperSection from "./components/swiperSection/SwiperSection";
import QuestionsSection from "./components/questionsSection/QuestionsSection";
import Footer from "./components/footer/Footer";

import 'scss/landing-style.scss';

const Landing = () => {
    return (
        <>
            <main className="landing-page-main">
                <LandingSection />
                <AboutUsSection />
                <BannerSection />
                <StarSection />
                <WhyUsSection />
                <BusinessSection />
                <ResponsibilitySection />
                <SwiperSection />
                <QuestionsSection />
            </main>
            <Footer />
        </>
    );
};

export default Landing;
