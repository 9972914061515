import React from "react";

const BannerStarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="105"
      height="117"
      viewBox="0 0 105 117"
      fill="none"
    >
      <g clipPath="url(#clip0_0_835)">
        <mask
          id="mask0_0_835"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="105"
          height="117"
        >
          <path d="M105 0H0V116.959H105V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_0_835)">
          <path
            d="M0.280631 58.8508C-0.0701875 58.7336 -0.0701875 58.1865 0.280631 58.1083L31.5035 49.1205C37.6779 47.3621 42.5192 41.9694 44.0979 35.0917L52.1667 0.312856C52.2719 -0.0779181 52.7631 -0.0779181 52.8333 0.312856L60.9021 35.0917C62.4808 41.9694 67.3221 47.3621 73.4965 49.1205L104.719 58.1083C105.07 58.2256 105.07 58.7727 104.719 58.8508L73.4965 67.8386C67.3221 69.5971 62.4808 74.9898 60.9021 81.8674L52.8333 116.646C52.728 117.037 52.2369 117.037 52.1667 116.646L44.0979 81.8674C42.5192 74.9898 37.6779 69.5971 31.5035 67.8386L0.280631 58.8508Z"
            fill="#F24030"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_0_835">
          <rect width="105" height="116.959" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BannerStarIcon;
