import Money from 'assets/images/business/money.png'
const BusinessLeft = () => {
  return (
    <div className="business-left">
      <img src={Money} alt="money" />
    </div>
  );
};

export default BusinessLeft;
