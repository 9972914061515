const IngredientsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M74.8 36.3055V63.9355H49.75H24.695V36.3055H19V70.1755H80.5V36.3055H74.8Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.75 29V36.305V63.935H74.8V36.305V29H49.75Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6953 29V36.305V63.935H49.7503V36.305V29H24.6953Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1895 48.64H66.4145L68.8895 35.835H71.2245"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.8301 41.6055L55.1901 48.6405H66.4151L67.7751 41.6055H53.8301Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1892 48.6406H55.1592C54.1492 48.6406 53.3242 49.4606 53.3242 50.4756C53.3242 51.4856 54.1442 52.3106 55.1592 52.3106H66.8242"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 33.9443H44.7852"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 40.2051H44.7852"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 46.4648H44.7852"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 52.7246H44.7852"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.6602 58.9844H44.7852"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.4199 56.4902H57.9549"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4355 56.4902H64.9655"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IngredientsIcon;
