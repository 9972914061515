import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
    GET_RECIPE,
} from 'queries';

export default function RecipeLoader(props) {

    const { recipeId, stage, setRecipeData, setDataLoading, children } = props;

    const {
        data: recipeData,
        loading: recipeLoading,
        refetch: recipeRefetch,
    } = useQuery(GET_RECIPE, {
        variables: {
            recipeId,
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        recipeRefetch({
            recipeId,
        })
    }, [recipeId, stage])

    useEffect(() => {
        if (recipeData && recipeData.recipe) {
            setRecipeData(recipeData.recipe)
            setDataLoading(false);
        }
        if (!recipeLoading) {
            setDataLoading(false);
        }
    }, [recipeData, recipeLoading])

    return (
        <>
            {
                children
            }
        </>
    );
}
