import { ReactNode } from "react";
/*
interface ButtonProps {
  type: "submit" | "reset" | "button" | undefined;
  children: ReactNode;
  className: string;
}*/

const Button = ({ type, children, className, onClick }/*: ButtonProps*/) => {
  return (
    <button type={type} className={className} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
