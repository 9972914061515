import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Modal,
} from 'reactstrap';
import Input from 'components/Input';
import { CHEF_CHANGE_PASSWORD } from 'queries';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
    const { t } = useTranslation();
    const [chefChangePassword] = useMutation(
        CHEF_CHANGE_PASSWORD
    );

    const [newPassword, setNewPassword] = useState('');

    const [newPassword2, setNewPassword2] = useState('');

    const [passwordErrors, setPasswordErrors] = useState(
        []
    );

    const [confirmationCode, setConfirmationCode] =
        useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [
        resentConfirmationCode,
        setResentConfirmationCode,
    ] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [passChanged, setPassChanged] = useState(false);

    const toggle = () => {
        setNewPassword('');
        setNewPassword2('');
        setConfirmationCode('');
        setRequestSent(false);
        setResentConfirmationCode(false);
        setPassChanged(false);
        setSaveError(null);
        setPasswordErrors([]);
        setModalOpen(!modalOpen);
    };

    const checkPasswords = (calledFromFirstPassword) => {
        if (
            newPassword.length + newPassword2.length ===
            0
        ) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword &&
                newPassword !== newPassword2) ||
            (newPassword !== newPassword2 &&
                newPassword2.length > 0)
        ) {
            newErrors.push(t('differentPassowrds'));
        }
        if (newPassword.length < 6) {
            newErrors.push(
                t('passwordMinSixChar')
            );
        }
        setPasswordErrors(newErrors);
    };

    const chefChangePasswordFunc = () => {
        let data = {
            newPass: newPassword,
        };
        if (requestSent) {
            data = {
                ...data,
                confirmationCode,
            };
        }
        chefChangePassword({
            variables: data,
        })
            .then(() => {
                if (requestSent) {
                    setPassChanged(true);
                } else {
                    setRequestSent(true);
                }
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    return (
        <>
            <button
                className="btn-link"
                onClick={(e) => {
                    e.preventDefault();
                    toggle();
                }}
            >
                {t('changePassword')}
            </button>
            <Modal
                isOpen={modalOpen}
                toggle={toggle}
                size='xl'
                contentClassName='settings-modal'
            >
                <div className="settings-header">
                    <h1>
                        {t('passwordChange')}
                    </h1>
                </div>
                <form>
                    <Input
                        label={t('newPassword')}
                        id="chef-new-pass"
                        type="password"
                        invalid={
                            passwordErrors.length >
                            0
                        }
                        value={newPassword}
                        setValue={(value) => {
                            setNewPassword(
                                value
                            );
                            setSaveError(
                                null
                            );

                            setPassChanged(
                                false
                            );
                        }}
                        onBlur={() =>
                            checkPasswords(
                                true
                            )
                        }
                    />

                    <Input
                        label={t('repeatPassword')}
                        id="chef-new-pass-2"
                        type='password'
                        value={newPassword2}
                        invalid={
                            passwordErrors.length >
                            0
                        }
                        setValue={(value) => {
                            setNewPassword2(
                                value
                            );
                            setSaveError(
                                null
                            );
                            setPassChanged(
                                false
                            );
                        }}
                        onBlur={() =>
                            checkPasswords()
                        }
                    />


                    {passwordErrors.map(
                        (error, index) => (
                            <p key={index} className='error'>
                                {error}
                            </p>
                        )
                    )}

                    {requestSent && (
                        <p>
                            {t('confirmationCodeSent')}
                        </p>
                    )}
                    {requestSent && (
                        <Input
                            label={t('confirmationCode')}
                            id="chef-password-confirmation"
                            type="text"
                            invalid={saveError}
                            value={confirmationCode}
                            setValue={(value) => {
                                setConfirmationCode(
                                    value
                                );
                            }}
                        />
                    )}

                    {requestSent && (
                        <button
                            className="btn-link"
                            onClick={() => {
                                setResentConfirmationCode(
                                    true
                                );
                                chefChangePassword({
                                    variables: {
                                        newPass:
                                            newPassword,
                                    },
                                });
                            }}
                        >
                            {t('resendCode')}
                        </button>
                    )}
                    {resentConfirmationCode && (
                        <p>
                            {t('codeResent')}
                        </p>
                    )}

                    {saveError && (
                        <p className='error'>{saveError}</p>
                    )}

                    {passChanged && (
                        <p>
                            {t('passwordChanged')}
                        </p>
                    )}


                    <div className='button-row'>
                        <button
                            className="btn-solid"
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            {t('discardChanges')}
                        </button>
                        <button
                            className="btn-solid"
                            disabled={
                                passwordErrors.length >
                                0 || passChanged
                            }
                            onClick={() => {
                                chefChangePasswordFunc();
                            }}
                        >
                            {t('saveChanges')}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
}
