import React from "react";

const PlayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M67.795 75.04V52.885L68.21 52.85C74.585 52.255 79.025 46.89 79.025 40.365C79.025 33.44 73.41 27.825 66.485 27.825C64.9 27.825 63.38 28.12 61.985 28.655L61.95 28.595C60.94 23.135 56.15 19 50.4 19C44.65 19 39.515 23.44 38.755 29.19L38.585 29.165C37.09 28.545 35.45 28.205 33.73 28.205C26.7 28.205 21 33.905 21 40.935C21 47.965 26.7 53.665 33.73 53.665C36.365 53.665 38.815 52.865 40.85 51.49"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5078 54.4766V75.0416"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.5645 32.6854C57.9545 30.8904 59.8245 29.4804 61.9845 28.6504"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1808 80.496C59.9176 80.496 67.8108 78.3515 67.8108 75.706C67.8108 73.0606 59.9176 70.916 50.1808 70.916C40.444 70.916 32.5508 73.0606 32.5508 75.706C32.5508 78.3515 40.444 80.496 50.1808 80.496Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.39 48.435L47 55.865V41L59.39 48.435Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlayIcon;
