interface VideoIconProps {
  width: number;
  height: number;
}

const VideoIcon = ({ width, height }: VideoIconProps) => {
  const circleRadius = Math.min(width, height) / 2;
  const circleCx = width / 2;
  const circleCy = height / 2;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <g transform="translate(0, 12)">
        <path
          d="M81 32H19V68H81V32Z"
          stroke="#373E44"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 64C58.8366 64 66 56.8366 66 48C66 39.1634 58.8366 32 50 32C41.1634 32 34 39.1634 34 48C34 56.8366 41.1634 64 50 64Z"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59 48L45 56V40L59 48Z"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81 14H19V32H81V14Z"
          stroke="#373E44"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 14L18 32"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 14L4 32"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44 14L32 32"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58 14L46 32"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72 14L60 32"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_539">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoIcon;
