import React from "react";

const DiamondIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M70.005 25.1951L69.88 25.0001H68.84H31.15H30.02L29.705 25.4751L19 41.5551L49.245 82.9301L50.075 84.0601L50.875 82.9401L80.5 41.5551L70.005 25.1951Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 41.5557L49.245 82.9307L35.175 41.5557H19Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.8404 25H31.1504L49.9504 41.555L68.8404 25Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.705 25.4756L19 41.5556H35.175L29.705 25.4756Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.585 41.5557L50.875 82.9407L80.5 41.5557H64.585Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.501 41.5546L70.006 25.1945L64.586 41.5546H80.501Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9501 41.555L31.1501 25H30.0201L29.7051 25.475L35.1751 41.555H49.9501Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.5862 41.555L70.0062 25.195L69.8812 25H68.8412L49.9512 41.555H64.5862Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9508 41.5557H35.1758L49.2458 82.9307L50.0758 84.0607L50.8758 82.9407L64.5858 41.5557H49.9508Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DiamondIcon;
