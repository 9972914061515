import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import RecipeHeader from 'components/RecipeHeader';
import { baseStages } from 'constants';
import { REST_URL } from 'configs/restAPI';
import Input from 'components/Input';


import ReactPlayer from 'react-player/vimeo';

import {
    UPDATE_RECIPE_STEP_4,
} from 'queries';

import { getItemFromTranslation, getLabel, useGetMyData } from 'utils';
import { Spinner, UncontrolledCarousel } from 'reactstrap';
import { Trans, useTranslation } from 'react-i18next';
import { difficultyOptions, priceRange } from 'constants';

export default function RecipePreview(props) {
    const {
        recipe,
        recipeId,
        setSaving,
        onNext,
        onPrevious,
        closeModal,
    } = props;

    const { t } = useTranslation();

    const currentChef = useGetMyData();
    const [videoLoading, setVideoLoading] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [servings, setServings] = useState(0);
    const [videoSrc, setVideoSrc] = useState("");
    const [updateRecipeStep4] = useMutation(UPDATE_RECIPE_STEP_4);

    const defaultRecipeLanguage = useMemo(() => {
        if (recipe) {
            const defaultLanguage = recipe.recipeTranslations.find((translation) => translation.def);
            if (defaultLanguage) {
                return defaultLanguage.language;
            }
        }
        if (currentChef) {
            const recipeHasChefLanguage = recipe.recipeTranslations.any((translation) => translation.language.id === currentChef.language.id);
            if (recipeHasChefLanguage) {
                return currentChef.language;
            }
        }
        const backupTranslation = recipe.recipeTranslations[0];
        return backupTranslation ? backupTranslation.language : {};
    }, [recipe, currentChef]);

    useEffect(() => {
        if (recipe && defaultRecipeLanguage) {
            setServings(recipe.servings);
            const recipeVideo = getItemFromTranslation(recipe.recipeTranslations, defaultRecipeLanguage.id, "video");
            if (recipeVideo) {
                setVideoSrc(recipeVideo);
                setVideoLoading(true)
            } else {
                setVideoLoading(false);
                setVideoSrc("");
            }
        }
    }, [recipe, defaultRecipeLanguage]);

    const images = useMemo(() => {
        if (!recipe) {
            return [];
        }
        if (recipe.videoSrc) {
            return [];
        }
        const sortedImages = [...recipe.images].sort((i1, i2) => i1.order > i2.order ? -1 : 1).map((image, index) => ({
            ...image,
            altText: `recipe step ${index + 1}`,
            caption: '',
            key: image.id,
            src: `${REST_URL}/${image.path}-300.png`
        }));
        return sortedImages;
    }, [recipe]);


    const updateRecipeStep4Func = () => {
        setSaving(true);

        updateRecipeStep4({
            variables: {
                id: recipeId
            },
        }).then(() => {
            setSaving(false);
            onNext();
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }

    const renderMedia = () => {
        const video = getItemFromTranslation(recipe.recipeTranslations, defaultRecipeLanguage.id, "video")
        return (
            <div className="main-form-content">
                {videoLoading && showVideo &&
                    <>
                        <Spinner />
                        <span>{t('loadingVideoTooLong')}</span>
                    </>
                }
                {
                    (!video || !showVideo) &&
                    <UncontrolledCarousel
                        ride={false}
                        items={images}
                        interval={false}
                        style={video ? { cursor: 'pointer' } : {}}
                        onClick={() => {
                            if (video) {
                                setShowVideo(true);
                            }
                        }}
                    />
                }
                {
                    !videoLoading && video &&
                    <ReactPlayer
                        url={videoSrc}
                        onReady={() => { console.log("Ready"); setVideoLoading(false) }}
                        controls={true}
                        className="recipe-video"
                        style={showVideo ? { opacity: 1 } : { display: "none", opacity: 0, transition: 'opacity 5s' }}

                    />
                }
                {
                    renderButtonRow()
                }
            </div>
        )
    }

    const renderButtonRow = () => (
        <div className='button-row'>
            <button className="btn-solid" onClick={() => {
                onPrevious()
            }}
            >
                {t('back')}
            </button>
            <button className="btn-solid" onClick={() => {
                updateRecipeStep4Func()
            }}
            >
                {t('finishRecipe')}
            </button>
        </div>
    )

    const roundToX = (num = 0, decimals = 2) => Math.round(num * 10 ** decimals) / 10 ** decimals;

    const renderIngredients = () => {
        const multiplicator = recipe.servings !== servings ? servings / recipe.servings : 1;
        return (
            <ul className='ingredients'>
                {recipe.recipeIngredients.map((ingredient) => (<li key={ingredient.id}>
                    <Trans
                        i18nKey={`${ingredient.unit}-short`}
                        defaults={`<b>{{ingredientAmount}} {{ingredientUnit}}</b> {{ingredientName}}`}
                        count={Math.round(ingredient.amount)}
                        values={{ ingredientAmount: roundToX(ingredient.amount * multiplicator, 2), ingredientUnit: t(ingredient.unit), ingredientName: getLabel(ingredient.ingredient.translations, defaultRecipeLanguage.id, "title", "noTitle", t) }}
                        components={{ b: <b /> }}
                    />
                </li>))}
            </ul>
        )
    }

    const renderPreparation = () => {
        const preparation = [...getLabel(recipe.recipeTranslations, defaultRecipeLanguage.id, "preparation", `${t('noPreparation')} (${defaultRecipeLanguage.languageCode})`)].sort((p1, p2) => p1.order < p2.order ? -1 : 1);

        return (
            <ul>
                {
                    preparation.map((prep, index) => (
                        <li key={index}>
                            <h3>{`${t('step')} ${index + 1}`}</h3>
                            <p>{prep.text}</p>
                        </li>
                    ))
                }
            </ul>
        )
    }

    const renderTags = () => {
        let tags = [];
        if (recipe.preparationTime) {
            tags.push({
                value: "prep " + recipe.preparationTime.id,
                label: t(recipe.preparationTime.title)
            });
        };
        if (recipe.mealType) {
            tags.push({
                value: "type " + recipe.mealType.id,
                label: t(recipe.mealType.title)
            });
        };
        recipe.diets.forEach((diet) => {
            tags.push(
                {
                    value: "diet " + diet.id,
                    label: t(diet.title)
                }
            )
        });
        if (recipe.calories) {
            tags.push({
                value: "cal " + recipe.calories,
                label: `${recipe.calories} kcal`
            });
        };
        recipe.allergens.forEach((allergen) => {
            tags.push(
                {
                    value: "allergen " + allergen,
                    label: allergen
                }
            )
        });
        const diff = difficultyOptions.find((difficulty) => difficulty.value === recipe.difficulty);
        tags.push({ value: "diff " + diff.value, label: t(diff.label) });

        const price = priceRange.find((p) => p.value === recipe.priceRange);
        tags.push({ value: "price " + price.value, label: t(price.label) });

        return (
            <div>
                {
                    tags.map((tag) => (
                        <span key={tag.value} className='tag'>{tag.label}</span>
                    ))}
            </div>
        )
    }
    console.log(recipe);
    const renderContent = () => (
        <>
            <p className='preparation'>
                <b>{getLabel(recipe.recipeTranslations, defaultRecipeLanguage.id, 'title', `${t('noTitle')} (${defaultRecipeLanguage.languageCode})`)}{` `}</b>
                {
                    getLabel(recipe.recipeTranslations, defaultRecipeLanguage.id, "description", `${t('noDescription')} (${defaultRecipeLanguage.languageCode})`)
                }
            </p>
            <hr />
            <div className='preview-ingredients-heading'>
                <h2>{t('ingredients')}</h2>
                <div>
                    <h2>{`${t('servings')}:`}</h2>
                    <Input
                        label={null}
                        id="recipe-servings"
                        type="deco-number"
                        hidden={true}
                        min={1}
                        step={recipe.servingIncrementType === "serving" ? 1 : recipe.servings}
                        placeholder={""}
                        value={servings}
                        setValue={setServings}
                    />
                </div>
            </div>
            {
                renderIngredients()
            }
            <h2>{t('description')}</h2>
            {
                renderPreparation()
            }
            <hr />
            {
                renderTags()
            }
        </>
    )

    return (
        <div className='modal-body-preview'>
            <div>
                <RecipeHeader stage={baseStages.preview} closeModal={closeModal} />
                {renderMedia()}
            </div>
            <div className="recipe-content-preview">
                {
                    renderContent()
                }
            </div>
        </div>
    );
}
