import React, { useState } from 'react';
import {
    Modal,
    Spinner,
} from 'reactstrap';

import RecipeLoader from './loader';
import RecipeMedia from './media';
import RecipeInfo from './recipe';
import RecipeExtraInfo from './extra';

import { baseStages, extraStages } from 'constants';
import { getStage, getStageLabel } from 'utils';

import { MarkerCircle, PlusCircle } from 'svg';
import RecipePreview from './preview';
import RecipeThankYou from './thank-you';
import RecipeWaitForTranslation from './waitForTranslation';
import { useTranslation } from 'react-i18next';

export default function RecipeForm(props) {
    const { t } = useTranslation();

    const { recipeId, stageId, fullSizeBtn, setMediaUploading, uploadImages, uploadVideo } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalFullscreen, setModalFullscreen] = useState(false);
    const [stage, setStage] = useState(null);

    const [saving, setSaving] = useState(false);
    const [showThankYouCard, setShowThankYouCard] = useState(false);
    const [dataLoading, setDataLoading] = useState(recipeId ? true : false);

    const [recipeData, setRecipeData] = useState(null);
    const [newRecipeId, setNewRecipeId] = useState(null);

    const resetData = () => {
        setShowThankYouCard(false);
        setSaving(false);
        setRecipeData(null);
        setNewRecipeId(null);
    }

    const toggle = () => {
        if (modalOpen) {
            resetData()
            setStage(baseStages.media);
            setSaving(false);
            setModalFullscreen(false);
        }
        setModalOpen(!modalOpen);
    };

    const nextStep = () => {
        const keys = Object.keys(baseStages);
        const stages = baseStages;
        for (let index = 0; index < keys.length; index++) {
            if (index + 1 < keys.length && stages[keys[index]] === stage) {
                if ([baseStages.extra].includes(stage)) {
                    setModalFullscreen(true);
                } else {
                    setModalFullscreen(false);
                }
                setStage(stages[keys[index + 1]]);
            } else if (stages[keys[index]] === stage.toString()) {
                setModalFullscreen(false);
                setStage(stages.media);
                toggle();
            }
        }
    };

    const previousStep = () => {
        const keys = Object.keys(baseStages);
        const stages = baseStages;
        for (let index = 0; index < keys.length; index++) {
            if (index - 1 >= 0 && stages[keys[index]] === stage) {
                setStage(stages[keys[index - 1]]);
            } else if (stages[keys[index]] === stage) {
                setStage(stages.media);
                toggle();
            }
        }
    };


    const uploadMedia = (recipeId, titleImage, images, video) => {
        setMediaUploading(true);
        setNewRecipeId(recipeId);
        setSaving(false);
        setModalFullscreen(false);
        nextStep();
        if (video) {
            uploadVideo(recipeId, titleImage, video)
        } else if (images.length > 0) {
            uploadImages(recipeId, images)
        }
    }

    const renderContent = () => {
        switch (stage) {
            case baseStages.media:
                return (
                    <RecipeMedia
                        recipe={recipeData}
                        setSaving={setSaving}
                        modalFullscreen={modalFullscreen}
                        setModalFullscreen={setModalFullscreen}
                        closeModal={toggle}
                        uploadMedia={uploadMedia}
                        onNext={() => {
                            setModalFullscreen(false);
                            setSaving(false);
                            nextStep();
                        }}
                        onPrevious={() => {
                            setModalFullscreen(false);
                            previousStep();
                        }}
                    />
                )
            case baseStages.recipe:
                return (
                    <RecipeInfo
                        recipe={recipeData}
                        recipeId={newRecipeId ? newRecipeId : recipeId}
                        setSaving={setSaving}
                        closeModal={toggle}
                        onNext={() => {
                            nextStep();
                        }}
                        onPrevious={() => {
                            setModalFullscreen(true);
                            previousStep();
                        }}
                    />
                )
            case baseStages.extra:
                return (
                    <RecipeExtraInfo
                        recipe={recipeData}
                        recipeId={newRecipeId ? newRecipeId : recipeId}
                        setSaving={setSaving}
                        closeModal={toggle}
                        onNext={() => {
                            setModalFullscreen(true);
                            nextStep();
                        }}
                        onPrevious={() => {
                            setModalFullscreen(false);
                            previousStep();
                        }}
                    />
                )
            case baseStages.preview:
                return (
                    <RecipePreview
                        recipe={recipeData}
                        recipeId={newRecipeId ? newRecipeId : recipeId}
                        setSaving={setSaving}
                        closeModal={toggle}
                        onNext={() => {
                            setModalFullscreen(false)
                            setShowThankYouCard(true);
                        }}
                        onPrevious={() => {
                            setModalFullscreen(false);
                            previousStep();
                        }}
                    />
                )
            case extraStages.translation:
                return (
                    <RecipeWaitForTranslation toggle={() => {
                        toggle()
                    }} />
                )
            default:
                return (<div>
                    {getStageLabel(stage, t)}
                    <button onClick={previousStep}>prev</button>
                    <button onClick={nextStep}>next</button>
                </div>)
        }
    }

    const renderAddBtn = () => (
        <div className='flex' onClick={(e) => {
            e.preventDefault()
            resetData()
            setStage(baseStages.media);
            toggle()
        }}>
            <button
                className='carousel-btn m-r-8'
            >
                <PlusCircle color={"#FFCB05"} />
            </button>
            <label className='btn-label-bold'>
                {t('addRecipe')}
            </label>
        </div>
    )

    const openEdit = () => {
        resetData()
        const actualStageId = stageId === 5 ? 0 : stageId;
        setStage(getStage(actualStageId));
        if ([0, 3].includes(actualStageId)) {
            setModalFullscreen(true);
        }
        toggle()
    }

    const renderEditBtn = () => (
        <button
            className='carousel-btn'
            onClick={() => {
                openEdit();
            }}
        >
            <MarkerCircle />
        </button>
    )

    const renderFullBtn = () => (
        <button
            className='full-invisible-btn'
            onClick={() => {
                openEdit();
            }}
        ></button>)

    const unexpectedError = recipeId && !dataLoading && !recipeData && modalOpen;
    const showSpinner = saving || dataLoading;

    const renderContentContainer = () => (
        <>
            {
                !showThankYouCard && unexpectedError &&
                <div className='spinner'>
                    <p className='error'>{t('unexpectedError')}</p>
                </div>
            }
            {
                !showThankYouCard && !unexpectedError && showSpinner &&
                <div className='spinner'>
                    <Spinner />
                </div>
            }
            {
                showThankYouCard &&
                <RecipeThankYou toggle={() => {
                    toggle()
                }} />
            }
            {
                !showThankYouCard && !unexpectedError && !showSpinner &&
                renderContent()
            }

        </>
    )

    return (
        <>
            {
                fullSizeBtn && recipeId &&
                renderFullBtn()
            }
            {
                !fullSizeBtn && recipeId &&
                renderEditBtn()
            }
            {
                !fullSizeBtn && !recipeId &&
                renderAddBtn()
            }

            <Modal
                isOpen={modalOpen}
                toggle={toggle}
                fullscreen={modalFullscreen}
                size='xl'
                contentClassName='recipe-modal'
            >
                {
                    !recipeId && !newRecipeId &&
                    renderContentContainer()
                }
                {
                    (recipeId || newRecipeId) &&
                    <RecipeLoader
                        recipeId={newRecipeId ? newRecipeId : recipeId}
                        setDataLoading={setDataLoading}
                        setRecipeData={setRecipeData}
                        stage={stage}
                    >
                        {renderContentContainer()}
                    </RecipeLoader>
                }

            </Modal>

        </>
    );
}
