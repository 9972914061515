import React from "react";

const MessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M30.485 61.8955C24.145 61.8955 19 67.0705 19 73.4505C19 74.2755 19.085 75.0755 19.25 75.8505C22.43 77.5155 26.3 78.4955 30.485 78.4955C34.67 78.4955 38.54 77.5155 41.72 75.8505C41.885 75.0755 41.97 74.2705 41.97 73.4505C41.97 67.0655 36.83 61.8955 30.485 61.8955Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4844 65.8945C34.5144 65.8945 37.8144 69.1295 37.9644 73.1645C35.6744 74.0395 33.1294 74.4995 30.4844 74.4995"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.5602 53.1306C36.5602 56.5056 33.8402 59.2456 30.4852 59.2456C27.1302 59.2456 24.4102 56.5056 24.4102 53.1306C24.4102 49.7556 27.1302 47.0156 30.4852 47.0156C33.8402 47.0156 36.5602 49.7556 36.5602 53.1306Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.0202 61.8955C62.6802 61.8955 57.5352 67.0705 57.5352 73.4505C57.5352 74.2755 57.6202 75.0755 57.7852 75.8505C60.9652 77.5155 64.8352 78.4955 69.0202 78.4955C73.2052 78.4955 77.0752 77.5155 80.2552 75.8505C80.4202 75.0755 80.5052 74.2705 80.5052 73.4505C80.5052 67.0655 75.3652 61.8955 69.0202 61.8955Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.0195 65.8945C73.0495 65.8945 76.3495 69.1295 76.4995 73.1645C74.2095 74.0395 71.6645 74.4995 69.0195 74.4995"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.0203 59.2447C72.3755 59.2447 75.0953 56.5069 75.0953 53.1297C75.0953 49.7525 72.3755 47.0147 69.0203 47.0147C65.6652 47.0147 62.9453 49.7525 62.9453 53.1297C62.9453 56.5069 65.6652 59.2447 69.0203 59.2447Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.916 21.945V31.795C31.916 34.525 34.116 36.74 36.831 36.74V45.335L45.371 36.74H62.681C65.396 36.74 67.596 34.525 67.596 31.795V21.945C67.596 19.215 65.396 17 62.681 17H36.831C34.116 17 31.916 19.215 31.916 21.945Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8151 26.4202C41.8151 27.9702 40.5601 29.2252 39.0101 29.2252C37.4601 29.2252 36.2051 27.9702 36.2051 26.4202C36.2051 24.8702 37.4601 23.6152 39.0101 23.6152C40.5601 23.6152 41.8151 24.8702 41.8151 26.4202Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5553 26.4202C52.5553 27.9702 51.3003 29.2252 49.7503 29.2252C48.2003 29.2252 46.9453 27.9702 46.9453 26.4202C46.9453 24.8702 48.2003 23.6152 49.7503 23.6152C51.3003 23.6152 52.5553 24.8702 52.5553 26.4202Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.2956 26.4202C63.2956 27.9702 62.0405 29.2252 60.4905 29.2252C58.9405 29.2252 57.6855 27.9702 57.6855 26.4202C57.6855 24.8702 58.9405 23.6152 60.4905 23.6152C62.0405 23.6152 63.2956 24.8702 63.2956 26.4202Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
