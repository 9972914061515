import React from "react";
import BannerContentWrapper from "./BannerContentWrapper";

const BannerSection = () => {
  return (
    <section id="banner-section">
      <BannerContentWrapper />
    </section>
  );
};

export default BannerSection;
