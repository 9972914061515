const CategoriesIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M76.7002 62.8599V46.0898H68.1602"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.295 46.0898H49.75V62.8599"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.7501 62.2949V46.0898H41.2051"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3438 46.09H22.7988V62.86"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.2256 42.3804V25.6104H54.6855"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.8204 25.6104H36.2754V42.3804"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.5004 63.9902H72.9004V69.1502H80.5004V63.9902Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.5492 63.9902H45.9492V69.1502H53.5492V63.9902Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.6 63.9902H19V69.1502H26.6V63.9902Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.0258 43.5098H59.4258V48.6698H67.0258V43.5098Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0746 43.5098H32.4746V48.6698H40.0746V43.5098Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.5492 23H45.9492V28.16H53.5492V23Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CategoriesIcon;
