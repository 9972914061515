import React from "react";
import { useTranslation, Trans } from "react-i18next";
import StarIcon from "svg/StarIcon";

const LandingSectionContentLeft = () => {
  const { t } = useTranslation();

  return (
    <div className="landing-section-content-left">
      <h1>
        <Trans
          i18nKey="homeOfChefs"
        />
      </h1>
      <div className="landing-section-left-wrapper">
        <p>
          <Trans
            i18nKey="homeOfChefsCaption"
          />

        </p>
        <StarIcon width={85} height={95} color="#F24030" />
      </div>
    </div>
  );
};

export default LandingSectionContentLeft;
