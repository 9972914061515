export const Images = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <g clipPath="url(#clip0_79_19018)">
            <path d="M9.7104 5H10.8778H12.4092H13.7587H16.4575H17.8802H19.1931H20.5058H23.2046H27.2529H31.3012C32.0433 5 32.6776 5.27295 33.2038 5.8052C33.7301 6.33745 34 6.97888 34 7.72949V24.1065C34 24.8571 33.7301 25.4985 33.2038 26.0307C32.6776 26.563 32.0433 26.8359 31.3012 26.8359H29C28.2578 26.8359 28.5263 13.0323 28 12.5C27.4737 11.9677 7.01156 11.7199 7.01156 10.9693V7.72949C7.01156 6.97888 7.28144 6.33745 7.80771 5.8052C8.33399 5.27295 8.96822 5 9.7104 5ZM9.7104 7.72949L9.74711 11.7297L29 11.5L28 24.1065H31.3012V7.72949H9.7104Z" fill="white" />
            <path d="M5.69884 10.1641H6.8662H8.39769H9.74711H12.446H13.8686H15.1816H16.4942H19.1931H23.2413H27.2896C28.0318 10.1641 28.666 10.437 29.1923 10.9693C29.7186 11.5015 29.9884 12.1429 29.9884 12.8935V29.2705C29.9884 30.0211 29.7186 30.6625 29.1923 31.1948C28.666 31.7271 28.0318 32 27.2896 32H5.69884C4.95666 32 4.32243 31.7271 3.79616 31.1948C3.26988 30.6625 3 30.0211 3 29.2705V12.8935C3 12.1429 3.26988 11.5015 3.79616 10.9693C4.32243 10.437 4.95666 10.1641 5.69884 10.1641ZM5.69884 12.8935V29.2705H27.2896V12.8935H5.69884Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_79_19018">
                <rect width="37" height="37" fill="white" />
            </clipPath>
        </defs>
    </svg>

)