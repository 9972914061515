import React from "react";

const GlobeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M49.441 73.5998C61.2599 73.5998 70.841 64.0187 70.841 52.1998C70.841 40.3809 61.2599 30.7998 49.441 30.7998C37.6221 30.7998 28.041 40.3809 28.041 52.1998C28.041 64.0187 37.6221 73.5998 49.441 73.5998Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4404 73.5998C56.5732 73.5998 62.3554 64.0187 62.3554 52.1998C62.3554 40.3809 56.5732 30.7998 49.4404 30.7998C42.3076 30.7998 36.5254 40.3809 36.5254 52.1998C36.5254 64.0187 42.3076 73.5998 49.4404 73.5998Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5899 64.0049V73.605H27.8399"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5898 40.3998V30.7998H27.8398"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.3594 35.1448H70.4444V24.8398"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.8867 59.1807H76.3167V63.8607"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.4395 30.7998V73.6048"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.9759 64.0049H31.3809"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.841 52.1992H28.041"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5009 40.3994H31.3809"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.895 26.9941C25.045 26.9941 26.79 28.7391 26.79 30.8891C26.79 33.0391 25.045 34.7841 22.895 34.7841C20.745 34.7841 19 33.0391 19 30.8891C19 28.7391 20.745 26.9941 22.895 26.9941ZM22.895 24.9941C19.645 24.9941 17 27.6391 17 30.8891C17 34.1391 19.645 36.7841 22.895 36.7841C26.145 36.7841 28.79 34.1391 28.79 30.8891C28.79 27.6391 26.145 24.9941 22.895 24.9941Z"
        fill="#373E44"
      />
      <path
        d="M22.895 69.71C25.045 69.71 26.79 71.455 26.79 73.605C26.79 75.755 25.045 77.5 22.895 77.5C20.745 77.5 19 75.755 19 73.605C19 71.455 20.745 69.71 22.895 69.71ZM22.895 67.71C19.645 67.71 17 70.355 17 73.605C17 76.855 19.645 79.5 22.895 79.5C26.145 79.5 28.79 76.855 28.79 73.605C28.79 70.355 26.145 67.71 22.895 67.71Z"
        fill="#373E44"
      />
      <path
        d="M70.4439 16C72.5939 16 74.3389 17.745 74.3389 19.895C74.3389 22.045 72.5939 23.79 70.4439 23.79C68.2939 23.79 66.5489 22.045 66.5489 19.895C66.5489 17.745 68.2939 16 70.4439 16ZM70.4439 14C67.1939 14 64.5489 16.645 64.5489 19.895C64.5489 23.145 67.1939 25.79 70.4439 25.79C73.6939 25.79 76.3389 23.145 76.3389 19.895C76.3389 16.645 73.6939 14 70.4439 14Z"
        fill="#373E44"
      />
      <path
        d="M76.3149 64.9102C78.4649 64.9102 80.2099 66.6551 80.2099 68.8051C80.2099 70.9551 78.4649 72.7002 76.3149 72.7002C74.1649 72.7002 72.4199 70.9551 72.4199 68.8051C72.4199 66.6551 74.1649 64.9102 76.3149 64.9102ZM76.3149 62.9102C73.0649 62.9102 70.4199 65.5551 70.4199 68.8051C70.4199 72.0552 73.0649 74.7002 76.3149 74.7002C79.5649 74.7002 82.2099 72.0552 82.2099 68.8051C82.2099 65.5551 79.5649 62.9102 76.3149 62.9102Z"
        fill="#373E44"
      />
    </svg>
  );
};

export default GlobeIcon;
