import parse from "html-react-parser";

interface QuestionItemProps {
  question: string;
}

const QuestionsItem = ({ question }: QuestionItemProps) => {
  return (
    <div className="questions-item">
      {parse(question)}
      <div className="arrow">↓</div>
    </div>
  );
};

export default QuestionsItem;
