import React from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
    Outlet,
} from 'react-router-dom';

import {
    useQuery,
    useMutation,
    useSubscription,
} from '@apollo/client';
import {
    LOGOUT_CHEF,
} from 'queries';
import { GET_MY_DATA, CHEF_DATA_SUBSCRIPTION } from 'apollo/queries';
import { useGetMyData } from 'utils';

import { Spinner } from 'reactstrap';
import Redirect from 'redirect';
import Profile from 'pages/profile';
import Sidebar from 'components/sidebar';
import ChangePassword from 'pages/intro/forgotten-password';
import Notifications from 'pages/notifications/notifications';
import { useTranslation } from 'react-i18next';
import { DoorOut } from 'svg';
import Settings from 'pages/settings';

export default function Navigation() {
    const { t } = useTranslation();
    const [logoutChef] = useMutation(LOGOUT_CHEF);
    const { loading: chefLoading, refetch: chefRefetch } =
        useQuery(GET_MY_DATA, {
            fetchPolicy: 'network-only',
        });
    useSubscription(CHEF_DATA_SUBSCRIPTION, {
        onData: () => {
            chefRefetch();
        },
    });

    const currentChef = useGetMyData();

    if (!chefLoading && currentChef === null) {
        logoutChef().then(() => { window.location.reload(false); });
        return null;
    }

    if (chefLoading) {
        return <Spinner color="primary"></Spinner>;
    }

    return (<BrowserRouter>
        <Routes>
            <Route
                path="/"
                element={
                    <div className='content'>
                        <div className='sidebar'>
                            <Sidebar />
                            <button className='btn-link' onClick={(e) => {
                                e.preventDefault();
                                logoutChef().then(() => { window.location.reload(false); });
                            }}><DoorOut />{t('logout')}</button>
                        </div>
                        <Outlet />
                    </div>
                }
            >
                <Route
                    path=""
                    element={<Profile />}
                />
                <Route
                    path="notifications"
                    element={<Notifications />}
                />
                <Route
                    path="settings"
                    element={<Settings />}
                />
            </Route>


            <Route
                path="/password_reset/:confirmationData"
                element={<ChangePassword />}
            />

            <Route path="/*" element={<Redirect />} />
        </Routes>
    </BrowserRouter>)
}
