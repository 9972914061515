import { useSwiper } from "swiper/react";
import SwiperBtnIconLeft from "svg/SwiperBtnIconLeft";
import SwiperBtnIconRight from "svg/SwiperBtnIconRight";

const SwiperButtons = () => {
  const swiper = useSwiper();

  return (
    <div className="swiper-btn">
      <button className="carousel-btn m-r-42" onClick={(e) => { e.preventDefault(); swiper.slidePrev() }}>
        <SwiperBtnIconLeft />
      </button>
      <button className="carousel-btn" onClick={(e) => { e.preventDefault(); swiper.slideNext() }}>
        <SwiperBtnIconRight />
      </button>
    </div>
  );
};

export default SwiperButtons;
