interface SubtitlesIconProps {
  width: number;
  height: number;
}

const SubtitlesIcon = ({ width, height }: SubtitlesIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <g transform="translate(19, 20)">
        <path
          d="M62.5 13H1V43.04H62.5V13Z"
          stroke="#373E44"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99902 27.8262H42.3996"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.6807 27.8262H53.4207"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.4207 35.8262L21.0201 35.8262"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.739 35.8262L9.99903 35.8262"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.0994 19.8262H53.4988"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0808 19.8262H14.8208"
          stroke="#373E44"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_521">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubtitlesIcon;
