import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
    LOGIN_CHEF,
    CHEF_RESET_PASSWORD,
} from 'queries';
import { GET_TESTED_TOKEN } from 'apollo/queries';

import { setIsLoggedIn, setTestedToken } from 'apollo/localSchema/actions';
import refreshToken from 'apollo/refreshToken';

import Input from 'components/Input';

import {
    Spinner,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

export default function Login() {
    const { t } = useTranslation();

    const [loginChef] = useMutation(LOGIN_CHEF);
    const [chefResetPassword] = useMutation(
        CHEF_RESET_PASSWORD
    );

    const { data: testedTokenData, loading: testedTokenLoading } = useQuery(GET_TESTED_TOKEN);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signingIn, setSigningIn] = useState(false);
    const [error, setError] = React.useState(null);

    const [forgottenParrsword, setForgottenPassword] =
        useState(false);
    const [
        passwordResetRequestSent,
        setPasswordResetRequestSent,
    ] = useState(false);

    const login = () => {
        setSigningIn(true);
        setError(null);

        loginChef({
            variables: {
                email,
                password,
            },
        })
            .then((response) => {
                setSigningIn(false);
                sessionStorage.setItem('acctok', response.data.loginChef.accessToken);
                setIsLoggedIn(true);
            })
            .catch((err) => {
                setSigningIn(false);
                setError(err.message);
            });
    };


    React.useEffect(() => {
        if (!testedTokenData.testedToken) {
            setTestedToken(true);
            tryLogin();
        }
    }, [testedTokenLoading, testedTokenData.testedToken]);

    const tryLogin = () => {
        setSigningIn(true);
        refreshToken()
            .then((response) => {
                const { ok, accessToken } = response.data;
                if (ok) {
                    sessionStorage.setItem('acctok', accessToken);
                    setIsLoggedIn(true);
                    setSigningIn(false);
                } else {
                    sessionStorage.removeItem('acctok');
                    setIsLoggedIn(false);
                    setSigningIn(false);
                }
            })
            .catch(() => {
                setIsLoggedIn(false);
                setSigningIn(false);
            });
    };

    const chefResetPasswordFunc = () => {
        chefResetPassword({
            variables: {
                email,
            },
        })
            .then((response) => {
                setPasswordResetRequestSent(true);
            })
            .catch((err) => { setError(err.message) });
    };

    const cantLogin = signingIn || email.length === 0 || password.length === 0;

    if (signingIn) {
        return (
            <div className='login-container m-t-120'>
                <Spinner />
            </div>
        )

    }

    if (forgottenParrsword) {
        return (
            <div className='login-container'>
                <button className='btn-link' onClick={() => {
                    setForgottenPassword(
                        false
                    );
                    setPasswordResetRequestSent(false)
                }}
                >
                    {t('inputEmail')}

                </button>

                <form>
                    <Input
                        id={"email"}
                        label={t('email')}
                        type="text"
                        placeholder=""
                        value={email}
                        setValue={setEmail}
                        onKeyUp={(e) => {
                            if (!signingIn &&
                                email.length > 0 &&
                                password.length > 0 &&
                                e.key === 'Enter') {
                                login();
                            }
                        }}
                    />

                    <button
                        className='btn-outline'
                        disabled={cantLogin}
                        onClick={(e) => {
                            e.preventDefault();
                            chefResetPasswordFunc();
                        }}
                    >
                        {t('sendRequest')}
                    </button>
                    {passwordResetRequestSent && (
                        <p>
                            {t('requestSent')}
                        </p>
                    )}
                    {error && <p className='error'>{error}</p>}
                </form>
            </div>
        )
    }

    return (
        <div className='login-container'>
            <h1>
                {t('loginNoun')}
            </h1>
            <form>
                <Input
                    id={"email"}
                    label={t('email')}
                    type="text"
                    placeholder=""
                    value={email}
                    setValue={setEmail}
                    onKeyUp={(e) => {
                        if (!signingIn &&
                            email.length > 0 &&
                            password.length > 0 &&
                            e.key === 'Enter') {
                            login();
                        }
                    }}
                />
                <Input
                    id={"password"}
                    label={t('password')}
                    type="password"
                    placeholder=""
                    value={password}
                    setValue={setPassword}
                    onKeyUp={(e) => {
                        if (!signingIn &&
                            email.length > 0 &&
                            password.length > 0 &&
                            e.key === 'Enter') {
                            login();
                        }
                    }}
                />
                <button
                    className='btn-link'
                    onClick={() => {
                        setForgottenPassword(
                            true
                        );
                    }}
                >
                    {t('forgottenPassword')}
                </button>
                <button
                    className='btn-outline'
                    disabled={cantLogin}
                    onClick={() => {
                        login();
                    }}
                >
                    {t('login')}
                </button>
                {error && <p className='error'>{error}</p>}
            </form>
        </div>
    );
}