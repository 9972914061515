import React from "react";

/*interface SwiperBtnIconProps {
  previous: () => void;
  next: () => void;
}*/

const SwiperBtnIconLeft = ({ previous, next }/*: SwiperBtnIconProps*/) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="78" height="77" viewBox="0 0 78 77" fill="none">
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" fill="black" fillOpacity="0.2" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />
      <path d="M76.8574 38.4864C76.8574 17.5761 59.9063 0.625 38.996 0.625C18.0857 0.625 1.13454 17.5761 1.13454 38.4864C1.13454 59.3967 18.0857 76.3479 38.996 76.3479C59.9063 76.3479 76.8574 59.3967 76.8574 38.4864ZM1.21166 38.4864C1.21166 17.6187 18.1283 0.702119 38.996 0.702119C59.8637 0.702119 76.7803 17.6187 76.7803 38.4864C76.7803 59.3541 59.8637 76.2708 38.996 76.2708C18.1283 76.2708 1.21166 59.3541 1.21166 38.4864Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.25" />

      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="77" viewBox="-25 -27 78 77" fill="none">
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" fill="black" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" fill="black" fill-opacity="0.2" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" fill="black" fill-opacity="0.2" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" fill="black" fill-opacity="0.2" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" fill="black" fill-opacity="0.2" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" stroke="black" stroke-width="1.32712" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" stroke="black" stroke-opacity="0.2" stroke-width="1.32712" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" stroke="black" stroke-opacity="0.2" stroke-width="1.32712" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" stroke="black" stroke-opacity="0.2" stroke-width="1.32712" />
        <path d="M1.16703 11.7448L1.16554 11.7464C1.14639 11.7664 1.09601 11.8355 1.05027 11.9421C1.00587 12.0455 0.99309 12.1208 0.99214 12.1481C0.994411 12.2545 1.00537 12.3147 1.02171 12.36C1.03625 12.4002 1.06575 12.4587 1.14895 12.5394L1.15816 12.5483L1.16703 12.5576L11.3593 23.2444L11.3608 23.246C11.5857 23.4832 11.9932 23.493 12.2191 23.2748C12.4438 23.0557 12.4456 22.6359 12.2509 22.4322L12.2507 22.432L4.06878 13.8669L2.99708 12.7449H4.5486H25.3314C25.6775 12.7449 25.9419 12.4714 25.9419 12.1512C25.9419 11.831 25.6775 11.5575 25.3314 11.5575H4.5486H2.99708L4.06878 10.4356L12.2507 1.87045L12.2509 1.87021C12.4642 1.64712 12.4377 1.22254 12.2402 1.04722L12.2397 1.04677C11.9561 0.794498 11.589 0.825512 11.3561 1.06149L1.16703 11.7448Z" stroke="black" stroke-opacity="0.2" stroke-width="1.32712" />
      </svg>
    </svg>
  );
};

export default SwiperBtnIconLeft;
