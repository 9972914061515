import React, { useState } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

import axios from 'axios';

import {
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
    REGISTER_POTENTIAL_CHEF,
} from 'queries';
import Input from 'components/Input';

import {
    isEmail,
    isValidPhoneNumber,
    isValidNickname,
    isValidName,
} from 'utils';
import { Trans, useTranslation } from 'react-i18next';
import { COMPULSORY_DATA } from 'constants';
import { HUMAN_NAME_TOO_SHORT } from 'constants';
import { INVALID_PHONE_NUMBER } from 'constants';
import { DIFFERENT_PASSWORDS } from 'constants';
import { INVALID_EMAIL } from 'constants';
import { NICKNAME_HAS_PROFANITY } from 'constants';
import { USE_ONLY_LATIN } from 'constants';
import { Spinner } from 'reactstrap';


export default function ContactForm() {
    const { t } = useTranslation();

    const [registerPotentialChef] = useMutation(REGISTER_POTENTIAL_CHEF);

    const [nickname, setNickname] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [instagram, setInstagram] = useState('');
    const [youtube, setYoutube] = useState('');
    const [language, setLanguage] = useState(null);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [dataErrors, setDataErrors] = useState({});

    const [requestSent, setRequestSent] = useState(false);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const languageOptions = languagesData && languagesData.languages ? languagesData.languages.map((lang) => ({
        ...lang,
        value: lang.id,
        label: `${t(lang.languageCode)} (${lang.languageCode.toUpperCase()})`,
    })) : [];

    async function getDataErrors() {
        let result = {
            "nickname": [],
            "name": [],
            "surname": [],
            "email": [],
            "phoneNumber": [],
            "language": [],
            "password": [],
            "repeatPassword": [],
        };

        let nickHasProfanity = false;

        try {
            const formData = new FormData();
            formData.append(`message`, nickname);
            const resp = await axios
                .post(`https://vector.profanity.dev`, formData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            nickHasProfanity = resp.data.isProfanity;
        } catch (e) {
            console.log(e);
        }

        if (nickname.length === 0) {
            result.nickname.push(t(COMPULSORY_DATA));
        } else if (nickname.length < 2) {
            result.nickname.push(t(HUMAN_NAME_TOO_SHORT));
        } else if (nickHasProfanity) {
            result.nickname.push(t(NICKNAME_HAS_PROFANITY));
        }

        if (name.length === 0) {
            result.name.push(t(COMPULSORY_DATA));
        } else if (name.length < 2) {
            result.name.push(t(HUMAN_NAME_TOO_SHORT));
        } else if (!isValidName(name)) {
            result.name.push(t(USE_ONLY_LATIN));
        }

        if (surname.length === 0) {
            result.surname.push(t(COMPULSORY_DATA));
        } else if (surname.length < 2) {
            result.surname.push(t(HUMAN_NAME_TOO_SHORT));
        } else if (!isValidName(surname)) {
            result.surname.push(t(USE_ONLY_LATIN));
        }

        if (phoneNumber.length === 0) {
            result.phoneNumber.push(t(COMPULSORY_DATA));
        } else if (!isValidPhoneNumber(phoneNumber)) {
            result.phoneNumber.push(t(INVALID_PHONE_NUMBER));
        }

        if (email.length === 0) {
            result.email.push(t(COMPULSORY_DATA));
        } else if (!isEmail(email)) {
            result.email.push(t(INVALID_EMAIL));
        }

        if (!language) {
            result.language.push(t(COMPULSORY_DATA));
        }

        if (password.length === 0) {
            result.password.push(t(COMPULSORY_DATA));
        }

        if (password.length > 0 && password !== repeatPassword) {
            result.repeatPassword.push(t(DIFFERENT_PASSWORDS));
        }

        return result;
    }

    async function register() {
        setSaving(true);

        const newDataErrors = await getDataErrors();
        if (Object.keys(newDataErrors).some((key) => newDataErrors[key].length > 0)) {
            setDataErrors(newDataErrors);
            setSaving(false);
            return;
        }

        registerPotentialChef({
            variables: {
                nickname,
                name,
                surname,
                email,
                password,
                phoneNumber,
                languageId: language.value,
                instagram,
                youtube,
            }
        }).then((response) => {
            setRequestSent(true);
            setSaving(false);
            setDataErrors([]);
            setError(null)
        }).catch((err) => {
            setRequestSent(false);
            setSaving(false);
            setError(err.message)
        })
    }

    const renderForm = () => (
        <form>
            <Input id={"nickname"} label={null} type="text" placeholder={`${t('nickname')}*`} error={dataErrors.nickname} value={nickname} setValue={setNickname} />
            <Input id={"name"} label={null} type="text" placeholder={`${t('firstName')}*`} error={dataErrors.name} value={name} setValue={setName} />
            <Input id={"surname"} label={null} type="text" placeholder={`${t('surname')}*`} error={dataErrors.surname} value={surname} setValue={setSurname} />
            <Input id={"email"} label={null} type="text" placeholder={`${t('email')}*`} error={dataErrors.email} value={email} setValue={setEmail} />
            <Input id={"phoneNumber"} label={null} type="text" placeholder={`${t('phoneNumber')}* (+99 999 999 999)`} error={dataErrors.phoneNumber} value={phoneNumber} setValue={setPhoneNumber} />
            <Input id={"language"} label={null} type="select" placeholder={`${t('language')}*`} error={dataErrors.language} value={language} setValue={setLanguage} options={languageOptions} />
            <Input id={"instagram"} label={null} type="text" placeholder={`${t('instagram')}`} value={instagram} setValue={setInstagram} />
            <Input id={"youtube"} label={null} type="text" placeholder={`${t('youtube')}`} value={youtube} setValue={setYoutube} />
            <Input id={"password"} label={null} type="password" hideRevealPass={true} placeholder={`${t('password')}*`} error={dataErrors.password} value={password} setValue={setPassword} />
            <Input id={"repeatPassword"} label={null} type="password" hideRevealPass={true} placeholder={`${t('repeatPassword')}*`} error={dataErrors.repeatPassword} value={repeatPassword} setValue={setRepeatPassword} />
        </form>
    )
    const renderSummary = () => (
        <div className='request'>
            <h2>{t('registrationSummary')}</h2>
            <div>
                <label>{`${name} ${surname} (${nickname})`}</label>
                <p>{`${email}`}</p>
            </div>
            <div>
                <label>{t('phoneNumber')}</label>
                <p>{`${phoneNumber}`}</p>
            </div>
            <div>
                <label>{t('language')}</label>
                <p>{`${language.label}`}</p>
            </div>
            <div>
                <label>{t('instagram')}</label>
                <p>{`${instagram}`}</p>
            </div>
            <div>
                <label>{t('youtube')}</label>
                <p>{`${youtube}`}</p>
            </div>

        </div>
    )

    return (
        <div
            className='contact-form-container'
        >
            <div className='heading'>
                {
                    requestSent &&
                    <button className='btn-link' onClick={() => setRequestSent(false)}>{t('backArrow')}</button>
                }
                {
                    !requestSent &&
                    <h1 style={{ position: "fixed", top: "30vh" }}>
                        <Trans
                            i18nKey="letUsKnowAboutYou"
                        />
                    </h1>
                }
                {
                    requestSent &&
                    <h1>
                        {t('thankYou')}

                    </h1>
                }
                {
                    requestSent &&
                    <p>
                        <Trans
                            i18nKey="registrationRequestSent"
                        />
                    </p>
                }
                {
                    requestSent &&
                    <p>

                        <Trans
                            i18nKey="reviewMayTakeFewDays"
                        />
                    </p>
                }
            </div>

            <div className='form'>

                {
                    requestSent &&
                    renderSummary()
                }

                {
                    !requestSent &&
                    renderForm()
                }

                {
                    error &&
                    <p className='error'>{error}</p>
                }

                {
                    !requestSent &&
                    <div>
                        <button
                            className='btn-outline'
                            disabled={saving}
                            onClick={() => {
                                register()
                            }}
                        >
                            {
                                saving &&
                                <Spinner className='m-r-8' style={{ borderWidth: "4px" }} />
                            }
                            <span>

                                {t('sendRequest')}
                            </span>

                        </button>
                    </div>
                }

            </div>
        </div>
    );
}
