import React from "react";

const ListItemIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
    >
      <path
        d="M0.0719868 16.1016C-0.0239956 16.0695 -0.0239956 15.9198 0.0719868 15.8984L8.61442 13.4385C10.3037 12.9572 11.6283 11.4813 12.0602 9.59893L14.2678 0.0802139C14.2966 -0.026738 14.431 -0.026738 14.4502 0.0802139L16.6578 9.59893C17.0897 11.4813 18.4142 12.9572 20.1035 13.4385L28.646 15.8984C28.7419 15.9305 28.7419 16.0802 28.646 16.1016L20.1035 18.5615C18.4142 19.0428 17.0897 20.5187 16.6578 22.4011L14.4502 31.9198C14.4214 32.0267 14.287 32.0267 14.2678 31.9198L12.0602 22.4011C11.6283 20.5187 10.3037 19.0428 8.61442 18.5615L0.0719868 16.1016Z"
        fill="black"
      />
      <path
        d="M0.0719868 16.1016C-0.0239956 16.0695 -0.0239956 15.9198 0.0719868 15.8984L8.61442 13.4385C10.3037 12.9572 11.6283 11.4813 12.0602 9.59893L14.2678 0.0802139C14.2966 -0.026738 14.431 -0.026738 14.4502 0.0802139L16.6578 9.59893C17.0897 11.4813 18.4142 12.9572 20.1035 13.4385L28.646 15.8984C28.7419 15.9305 28.7419 16.0802 28.646 16.1016L20.1035 18.5615C18.4142 19.0428 17.0897 20.5187 16.6578 22.4011L14.4502 31.9198C14.4214 32.0267 14.287 32.0267 14.2678 31.9198L12.0602 22.4011C11.6283 20.5187 10.3037 19.0428 8.61442 18.5615L0.0719868 16.1016Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M0.0719868 16.1016C-0.0239956 16.0695 -0.0239956 15.9198 0.0719868 15.8984L8.61442 13.4385C10.3037 12.9572 11.6283 11.4813 12.0602 9.59893L14.2678 0.0802139C14.2966 -0.026738 14.431 -0.026738 14.4502 0.0802139L16.6578 9.59893C17.0897 11.4813 18.4142 12.9572 20.1035 13.4385L28.646 15.8984C28.7419 15.9305 28.7419 16.0802 28.646 16.1016L20.1035 18.5615C18.4142 19.0428 17.0897 20.5187 16.6578 22.4011L14.4502 31.9198C14.4214 32.0267 14.287 32.0267 14.2678 31.9198L12.0602 22.4011C11.6283 20.5187 10.3037 19.0428 8.61442 18.5615L0.0719868 16.1016Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M0.0719868 16.1016C-0.0239956 16.0695 -0.0239956 15.9198 0.0719868 15.8984L8.61442 13.4385C10.3037 12.9572 11.6283 11.4813 12.0602 9.59893L14.2678 0.0802139C14.2966 -0.026738 14.431 -0.026738 14.4502 0.0802139L16.6578 9.59893C17.0897 11.4813 18.4142 12.9572 20.1035 13.4385L28.646 15.8984C28.7419 15.9305 28.7419 16.0802 28.646 16.1016L20.1035 18.5615C18.4142 19.0428 17.0897 20.5187 16.6578 22.4011L14.4502 31.9198C14.4214 32.0267 14.287 32.0267 14.2678 31.9198L12.0602 22.4011C11.6283 20.5187 10.3037 19.0428 8.61442 18.5615L0.0719868 16.1016Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M0.0719868 16.1016C-0.0239956 16.0695 -0.0239956 15.9198 0.0719868 15.8984L8.61442 13.4385C10.3037 12.9572 11.6283 11.4813 12.0602 9.59893L14.2678 0.0802139C14.2966 -0.026738 14.431 -0.026738 14.4502 0.0802139L16.6578 9.59893C17.0897 11.4813 18.4142 12.9572 20.1035 13.4385L28.646 15.8984C28.7419 15.9305 28.7419 16.0802 28.646 16.1016L20.1035 18.5615C18.4142 19.0428 17.0897 20.5187 16.6578 22.4011L14.4502 31.9198C14.4214 32.0267 14.287 32.0267 14.2678 31.9198L12.0602 22.4011C11.6283 20.5187 10.3037 19.0428 8.61442 18.5615L0.0719868 16.1016Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default ListItemIcon;
