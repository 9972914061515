import { questionsItems } from "configs/questionsItems";
import QuestionsItem from "./QuestionsItem";

const QuestionsRight = () => {
  return (
    <div className="questions-right">
      {questionsItems.map(({ id, question }) => (
        <QuestionsItem key={id} question={question} />
      ))}
    </div>
  );
};

export default QuestionsRight;
