export const swiperBreakPoints = () => {
  return {
    100: {
      slidesPerView: 1.1,
    },
    200: {
      slidesPerView: 1.1,
    },
    300: {
      slidesPerView: 0.9,
    },
    390: {
      slidesPerView: 0.9,
    },
    450: {
      slidesPerView: 1.1,
    },
    624: {
      slidesPerView: 1.2,
    },
    768: {
      slidesPerView: 1.4,
    },
    850: {
      slidesPerView: 1.6,
    },
    1024: {
      slidesPerView: 2.2,
    },
    1168: {
      slidesPerView: 2.3,
    },
    1300: {
      slidesPerView: 2.6,
    },
    1500: {
      slidesPerView: 2.7,
    },
    1700: {
      slidesPerView: 2.8,
    },
    1850: {
      slidesPerView: 3.3,
    },
  };
};
