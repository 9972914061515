export const List = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <g clipPath="url(#clip0_79_19049)">
            <path d="M2.15011 5.13958C2.62349 5.13958 3.00725 4.75582 3.00725 4.28244C3.00725 3.80905 2.62349 3.42529 2.15011 3.42529C1.67673 3.42529 1.29297 3.80905 1.29297 4.28244C1.29297 4.75582 1.67673 5.13958 2.15011 5.13958Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.15137 4.28223H23.5799" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.15011 12.8534C2.62349 12.8534 3.00725 12.4697 3.00725 11.9963C3.00725 11.5229 2.62349 11.1392 2.15011 11.1392C1.67673 11.1392 1.29297 11.5229 1.29297 11.9963C1.29297 12.4697 1.67673 12.8534 2.15011 12.8534Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.15137 11.9961H23.5799" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.15011 20.5654C2.62349 20.5654 3.00725 20.1815 3.00725 19.7082C3.00725 19.2349 2.62349 18.8511 2.15011 18.8511C1.67673 18.8511 1.29297 19.2349 1.29297 19.7082C1.29297 20.1815 1.67673 20.5654 2.15011 20.5654Z" stroke="#8A8A8A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.15137 19.7114H23.5799" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_79_19049">
                <rect width="24" height="24" fill="white" transform="translate(0.4375)" />
            </clipPath>
        </defs>
    </svg>
)