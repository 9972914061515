export const foodAllergens = Object.freeze([
    Object.freeze({ value: 1, label: 1 }),
    Object.freeze({ value: 2, label: 2 }),
    Object.freeze({ value: 3, label: 3 }),
    Object.freeze({ value: 4, label: 4 }),
    Object.freeze({ value: 5, label: 5 }),
    Object.freeze({ value: 6, label: 6 }),
    Object.freeze({ value: 7, label: 7 }),
    Object.freeze({ value: 8, label: 8 }),
    Object.freeze({ value: 9, label: 9 }),
    Object.freeze({ value: 10, label: 10 }),
    Object.freeze({ value: 11, label: 11 }),
    Object.freeze({ value: 12, label: 12 }),
    Object.freeze({ value: 13, label: 13 }),
    Object.freeze({ value: 14, label: 14 })
])
