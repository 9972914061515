import CategoriesIcon from "../svg/CategoriesIcon";
import DiamondIcon from "../svg/DiamondIcon";
import GlobeIcon from "../svg/GlobeIcon";
import IngredientsIcon from "../svg/IngredientsIcon";
import MenuIcon from "../svg/MenuIcon";
import MessageIcon from "../svg/MessageIcon";
import MicrophoneIcon from "../svg/MicrophoneIcon";
import PlayIcon from "../svg/PlayIcon";
import SearchIcon from "../svg/SearchIcon";
import SmilingEmojiIcon from "../svg/SmilingEmojiIcon";
import SubtitlesIcon from "../svg/SubtitlesIcon";
import VideoIcon from "../svg/VideoIcon";

export const whyUsItems = [
  {
    id: 1,
    icon: <VideoIcon width={100} height={100} />,
    value: "whyUs-1",
    text: "<p>adding video recipes and <br/> building a fan base</p>",
  },
  {
    id: 2,
    icon: <SmilingEmojiIcon width={100} height={100} />,
    value: "whyUs-2",
    text: "<p>user friendly interface <br/> and management of <br/> video recipes</p>",
  },
  {
    id: 3,
    icon: <SubtitlesIcon width={100} height={100} />,
    value: "whyUs-3",
    text: "<p>recipes subtitled and <br/> translated using AI into <br/> the user's local language</p>",
  },
  {
    id: 4,
    icon: <MicrophoneIcon width={100} height={100} />,
    value: "whyUs-4",
    text: "<p>start the video controlled <br/> by voice when your<br/> hands are tired</p>",
  },
  {
    id: 5,
    icon: <MenuIcon />,
    value: "whyUs-5",
    text: "<p>creation of multiple<br/> course menus</p>",
  },
  {
    id: 6,
    icon: <IngredientsIcon />,
    value: "whyUs-6",
    text: "<p>the ingredients from the <br/> recipe are transformed <br/>into a shopping list</p>",
  },
  {
    id: 7,
    icon: <CategoriesIcon />,
    value: "whyUs-7",
    text: "<p>the possibility to profile <br/> your work in categories <br/> and trends (vegan, <br/> Italian, lowcarb...)</p>",
  },
  {
    id: 8,
    icon: <PlayIcon />,
    value: "whyUs-8",
    text: "<p>live streams, live cooking <br/> and online cooking <br/> courses (we are <br/> preparing)</p>",
  },

  {
    id: 9,
    icon: <GlobeIcon />,
    value: "whyUs-9",
    text: "<p>global community with <br/> added value</p>",
  },
  {
    id: 10,
    icon: <MessageIcon />,
    value: "whyUs-10",
    text: "<p>support and background <br/> of a professional team</p>",
  },
  {
    id: 11,
    icon: <SearchIcon />,
    value: "whyUs-11",
    text: "<p>useful analyzes and <br/> statistics</p>",
  },
  {
    id: 12,
    icon: <DiamondIcon />,
    value: "whyUs-12",
    text: "<p>VIP club with personal <br/> service</p>",
  },
];
