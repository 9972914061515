import { ReactNode } from "react";
import parse from "html-react-parser";
import { Trans } from "react-i18next";
/*
interface ListItemProps {
  id: number;
  icon: ReactNode;
  text: string;
}*/
const ListItem = ({ id, icon, value }/*: ListItemProps*/) => {
  return (
    <div className="star-section-item">
      {icon}
      <Trans i18nKey={value} />
    </div>
  );
};

export default ListItem;
