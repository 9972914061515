import React from "react";
import ResponsibilityContent from "./ResponsibilityContent";

const ResponsibilitySection = () => {
  return (
    <section id="responsibility-section">
      <ResponsibilityContent />
    </section>
  );
};

export default ResponsibilitySection;
