import Likes from "assets/images/star_section/likes.png";

const StarSectionLeft = () => {
  return (
    <>
      <div className="star-section-left">
        {" "}
        <img src={Likes} alt="likes" />
      </div>
    </>
  );
};

export default StarSectionLeft;
