import React, { useState } from 'react';
import {
    useMutation,
    useQuery,
    useSubscription,
} from '@apollo/client';
import {
    GET_NOTIFICATIONS,
    NOTIFICATIONS_SUBSCRIPTION,
    SET_NOTIFICATION_SEEN,
    SET_ALL_NOTIFICATIONS_SEEN,
    DELETE_NOTIFICATION,
} from 'queries';
import {
    Button,
    Card,
    Row,
    Col,
    Table,
    Spinner,
} from 'reactstrap';
import { timestampToStringFNS } from 'utils';
import { MaterialSymbol } from 'react-material-symbols';
import { useTranslation } from 'react-i18next';

export default function Notifications() {
    const { t } = useTranslation();
    const [setNotificationSeen] = useMutation(
        SET_NOTIFICATION_SEEN
    );
    const [setAllNotificationsSeen] = useMutation(
        SET_ALL_NOTIFICATIONS_SEEN
    );
    const [deleteNotification] = useMutation(
        DELETE_NOTIFICATION
    );

    const [showDetail, setShowDetail] = useState(false);
    const [chosenNotification, setChosenNotification] =
        useState(null);

    const {
        data: notificationsData,
        loading: notificationsLoading,
        refetch: notificationsRefetch,
    } = useQuery(GET_NOTIFICATIONS, {
        fetchPolicy: 'network-only',
    });

    useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
        onData: () => {
            notificationsRefetch();
        },
    });

    const setNotificationSeenFunc = (
        setNotificationSeenId
    ) => {
        setNotificationSeen({
            variables: {
                setNotificationSeenId,
            },
        });
    };

    const setAllNotificationsSeenFunc = () => {
        setAllNotificationsSeen();
    };

    const deleteNotificationFunc = (
        deleteNotificationId
    ) => {
        deleteNotification({
            variables: {
                deleteNotificationId,
            },
        });
    };

    if (notificationsLoading) {
        return (
            <Card style={{ padding: '1em' }}>
                <Row>
                    <Spinner color="primary" />
                </Row>
            </Card>
        );
    }

    return (
        <Row style={{ padding: '1em' }}>
            <Col>
                <Row className="flex jc-space-between">
                    <Col>
                        <Button
                            color="link"
                            onClick={() => {
                                setAllNotificationsSeenFunc();
                            }}
                        >
                            {t('markAllAsRead')}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('subject')}</th>
                                <th className="ta-center">
                                    {t('read')}
                                </th>
                                <th>{t('sentOn')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notificationsData.notifications
                                .length === 0 && (
                                    <tr>
                                        <td colSpan={3}>
                                            {t('noNotifications')}
                                        </td>
                                    </tr>
                                )}
                            {notificationsData.notifications.map(
                                (notification) => (
                                    <tr
                                        key={
                                            notification.id
                                        }
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            if (
                                                !notification.seen
                                            ) {
                                                setNotificationSeenFunc(
                                                    notification.id
                                                );
                                            }
                                            setChosenNotification(
                                                notification
                                            );
                                            setShowDetail(
                                                true
                                            );
                                        }}
                                    >
                                        <td>
                                            {
                                                notification.title
                                            }
                                        </td>
                                        <td className="ta-center">
                                            <MaterialSymbol
                                                icon={
                                                    notification.seen
                                                        ? 'check_circle'
                                                        : 'cancel'
                                                }
                                                size={24}
                                                grade={-25}
                                                color={
                                                    notification.seen
                                                        ? 'green'
                                                        : 'red'
                                                }
                                            />
                                        </td>
                                        <td>
                                            {timestampToStringFNS(
                                                notification.createdAt
                                            )}
                                        </td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                </Row>
            </Col>

            {showDetail && (
                <Col>
                    <Row>
                        <h2>
                            {`Predmet: `}
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {chosenNotification?.title
                                    ? chosenNotification.title
                                    : t('noSubject')}
                            </span>
                        </h2>
                    </Row>
                    <Row>
                        <p>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >
                                {`${t('message')}: `}
                            </span>
                            {chosenNotification?.message
                                ? chosenNotification.message
                                : t('noMessage')}
                        </p>
                    </Row>
                    <Row xs="2">
                        <Col></Col>
                        <Col className="flex jc-flex-end">
                            <Button
                                color="danger"
                                block
                                onClick={() => {
                                    deleteNotificationFunc(
                                        chosenNotification.id
                                    );
                                }}
                            >
                                {t('remove')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    );
}
