import React from "react";
import QuestionsContent from "./QuestionsContent";

const QuestionsSection = () => {
  return (
    <section id="questions-section">
      <QuestionsContent />
    </section>
  );
};

export default QuestionsSection;
