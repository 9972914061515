import React, { useState } from 'react';
import { useQuery } from '@apollo/client';

import { GET_TESTED_TOKEN } from 'apollo/queries';

import { setIsLoggedIn, setTestedToken } from 'apollo/localSchema/actions';
import refreshToken from 'apollo/refreshToken';

import {
  Spinner,
} from 'reactstrap';

import LogoIcon from "svg/LogoIcon";
import LandingSectionSignInForm from "./LandingSectionSignInForm";
import LandingSectionSignWith from "./LandingSectionSignWith";

const LandingSectionContentRight = () => {

  const { data: testedTokenData, loading: testedTokenLoading } = useQuery(GET_TESTED_TOKEN);

  const [signingIn, setSigningIn] = useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!testedTokenData.testedToken) {
      setTestedToken(true);
      tryLogin();
    }
  }, [testedTokenLoading, testedTokenData.testedToken]);

  const tryLogin = () => {
    setSigningIn(true);
    refreshToken()
      .then((response) => {
        const { ok, accessToken } = response.data;
        if (ok) {
          sessionStorage.setItem('acctok', accessToken);
          setIsLoggedIn(true);
          setSigningIn(false);
        } else {
          sessionStorage.removeItem('acctok');
          setIsLoggedIn(false);
          setSigningIn(false);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
        setSigningIn(false);
      });
  };

  if (signingIn) {
    return (
      <div className="landing-section-content-right">
        <LogoIcon />
        <Spinner className='m-t-72' />
      </div>
    )
  }

  return (
    <div className="landing-section-content-right">
      <LogoIcon />
      <LandingSectionSignInForm setSigningIn={setSigningIn} error={error} setError={setError} />
      <LandingSectionSignWith />
    </div>
  );
};

export default LandingSectionContentRight;
