import BannerContent from "./BannerContent";

const BannerContentWrapper = () => {
  return (
    <div className="banner-content-wrapper">
      <BannerContent />
    </div>
  );
};

export default BannerContentWrapper;
