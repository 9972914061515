import { gql } from '@apollo/client';

export const GET_RECIPE_EXTRA_ATTRIBUTES = gql`query recipeExtraAttributes($available: Boolean, $mealTypesAvailable2: Boolean, $preparationTimesAvailable2: Boolean) {
    diets(available: $available) {
      id
      order
      title
    }
    mealTypes(available: $mealTypesAvailable2) {
      id
      order
      title
    }
    preparationTimes(available: $preparationTimesAvailable2) {
      id
      order
      title
    }
  }`