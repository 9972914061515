import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Modal,
} from 'reactstrap';
import Input from 'components/Input';
import { CHEF_CHANGE_PHONE_NUMBER } from 'queries';
import { useTranslation } from 'react-i18next';
import {
    isValidPhoneNumber
} from 'utils';

export default function ChangePhoneNumber(props) {
    const { t } = useTranslation();
    const [chefChangePhoneNumber] = useMutation(
        CHEF_CHANGE_PHONE_NUMBER
    );

    const [phoneNumber, setPhoneNumber] = useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [numberChanged, setNumberChanged] =
        useState(false);

    const [confirmationCode, setConfirmationCode] =
        useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [
        resentConfirmationCode,
        setResentConfirmationCode,
    ] = useState(false);

    const toggle = () => {
        setNumberChanged(false);
        setSaveError(null);
        setRequestSent(false);
        setConfirmationCode('');
        setPhoneNumber('');

        setModalOpen(!modalOpen);
    };

    const chefChangePhoneNumberFunc = () => {
        let data = {
            newPhoneNumber: phoneNumber,
        };
        if (requestSent) {
            data = {
                ...data,
                confirmationCode,
            };
        }
        chefChangePhoneNumber({
            variables: data,
        })
            .then(() => {
                if (requestSent) {
                    setNumberChanged(true);
                } else {
                    setRequestSent(true);
                }
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    return (
        <>
            <button
                className="btn-link"
                onClick={(e) => {
                    e.preventDefault();
                    toggle();
                }}
            >
                {t('changePhoneNumber')}
            </button>
            <Modal
                isOpen={modalOpen}
                toggle={toggle}
                size='xl'
                contentClassName='settings-modal'
            >
                <div className="settings-header">
                    <h1>
                        {t('phoneNumberChange')}
                    </h1>
                </div>
                <form>
                    <Input
                        label={t('newPhoneNumber')}
                        id="chef-phone-number"
                        type="text"
                        placeholder="+99 999 999 999"
                        error={phoneNumber.length > 0 && !isValidPhoneNumber(phoneNumber) ? t('invalidPhoneNumber') : null}
                        value={phoneNumber}
                        setValue={setPhoneNumber}
                    />

                    {requestSent && (
                        <label>
                            {t('confirmationCodeSent')}
                        </label>
                    )}
                    {requestSent && (
                        <Input
                            label={t('confirmationCode')}
                            id="chef-phone-number-confirmation"
                            type="text"
                            value={confirmationCode}
                            onChange={(e) => {
                                setConfirmationCode(
                                    e.target.value
                                );
                            }}
                        />
                    )}

                    {requestSent && (
                        <button
                            className="btn-link"
                            onClick={() => {
                                setResentConfirmationCode(
                                    true
                                );
                                chefChangePhoneNumber(
                                    {
                                        variables: {
                                            newPhoneNumber:
                                                phoneNumber,
                                        },
                                    }
                                );
                            }}
                        >
                            {t('resendCode')}
                        </button>
                    )}
                    {resentConfirmationCode && (
                        <p>
                            {t('codeResent')}
                        </p>
                    )}
                    {saveError && (
                        <p className='error'>{saveError}</p>
                    )}

                    {numberChanged && (
                        <p>
                            {t('phoneNumberChanged')}
                        </p>
                    )}

                    <div className='button-row'>
                        <button
                            className="btn-solid"
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            {t('discardChanges')}
                        </button>
                        <button
                            className="btn-solid"
                            disabled={
                                phoneNumber.length ===
                                0 || numberChanged
                            }
                            onClick={() => {
                                chefChangePhoneNumberFunc();
                            }}
                        >
                            {t('saveChanges')}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
}
