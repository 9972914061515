import ResponsibilityGrid from "./ResponsibilityGrid";
import Button from "../ui/Button";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const ResponsibilityContent = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="responsibilty-content">
      <h2>{t('weTakeResponsibility')}</h2>
      <ResponsibilityGrid />
      <Button type="button" className="resposibility-btn" onClick={() => { navigate(`/contact-us`); }}>
        {t('getAccess')}
      </Button>
    </div>
  );
};

export default ResponsibilityContent;
