export const Video = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <g clipPath="url(#clip0_79_18970)">
            <path d="M6.16634 6.1665L9.24967 12.3332H13.8747L10.7913 6.1665H13.8747L16.958 12.3332H21.583L18.4997 6.1665H21.583L24.6663 12.3332H29.2913L26.208 6.1665H30.833C31.6809 6.1665 32.4055 6.47484 33.0068 7.07609C33.608 7.67734 33.9163 8.40192 33.9163 9.24984V27.7498C33.9163 28.5978 33.608 29.3223 33.0068 29.9236C32.4055 30.5248 31.6809 30.8332 30.833 30.8332H6.16634C5.31842 30.8332 4.59384 30.5248 3.99259 29.9236C3.39134 29.3223 3.08301 28.5978 3.08301 27.7498V9.24984C3.08301 8.40192 3.39134 7.67734 3.99259 7.07609C4.59384 6.47484 5.31842 6.1665 6.16634 6.1665ZM6.16634 15.4165V27.7498H30.833V15.4165H6.16634Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_79_18970">
                <rect width="37" height="37" fill="white" />
            </clipPath>
        </defs>
    </svg>
)