import React, { useEffect, useState } from 'react';
import { useQuery, useSubscription, useMutation } from '@apollo/client';
import { FileUploader } from "react-drag-drop-files";
import Input from 'components/Input';
import RecipeHeader from 'components/RecipeHeader';
import { REST_URL } from 'configs/restAPI';

import {
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';

import { baseStages } from 'constants';

import { Player } from "video-react";
import ReactPlayer from 'react-player/vimeo';

import Carousel from 'react-multi-carousel';
import { Spinner } from 'reactstrap';
import { getItemFromTranslation, getLabel, useGetMyData } from 'utils';
import { ArrowLeft, ArrowRight, RectangleHorizontal, RectangleVertical } from 'svg';

import {
    ADD_RECIPE,
    UPDATE_RECIPE_STEP_1,
} from 'queries';
import { Trans, useTranslation } from 'react-i18next';

let fakeID = -1;

const responsive = {
    desktop: {
        breakpoint: { max: 5000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const videoOrientationType = Object.freeze({
    landscape: "landscape",
    portrait: "portrait",
});

export default function RecipeMedia(props) {
    const {
        recipe,
        onNext,
        onPrevious,
        modalFullscreen,
        setModalFullscreen,
        setSaving,
        closeModal,
        uploadMedia,
    } = props;

    const { t } = useTranslation();

    const currentChef = useGetMyData();

    const [addRecipe] = useMutation(ADD_RECIPE);
    const [updateRecipeStep1] = useMutation(UPDATE_RECIPE_STEP_1);

    const uniqueID = 'x'
        .repeat(5)
        .replace(
            /./g,
            (c) =>
                'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'[
                Math.floor(Math.random() * 62)
                ]
        );


    const [title, setTitle] = useState('');
    const [language, setLanguage] = useState(null);
    const [description, setDescription] = useState("");

    //max 5mb a 5ks
    const [images, setImages] = useState([]);
    const [titleImage, setTitleImage] = useState(null)
    const [addMorePictures, setAddMorePictures] = useState(false);
    const [video, setVideo] = useState(null);
    const [videoSrc, setVideoSrc] = useState("");
    const [videoOrientation, setVideoOrientation] = useState(null);
    const [videoLoading, setVideoLoading] = useState(false);
    const [mediaLoading, setMediaLoading] = useState(false);

    const [imageSizeTooBig, setImageSizeTooBig] =
        useState(false);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    useEffect(() => {
        if (recipe && currentChef && languagesData) {
            setDescription(getLabel(recipe.recipeTranslations, currentChef.language.id, "description", `${t('noDescription')} (${currentChef.language.languageCode})`));
            setTitle(getLabel(recipe.recipeTranslations, currentChef.language.id));
            const chefLanguage = languagesData.languages.find((lang) => lang.id === currentChef.language.id);
            setLanguage(chefLanguage ? ({
                ...chefLanguage,
                value: chefLanguage.id,
                label: `${t(chefLanguage.languageCode)} (${chefLanguage.languageCode})`,
            }) : ({
                ...languagesData.languages[0],
                value: languagesData.languages[0].id,
                label: `${t(languagesData.languages[0].languageCode)} (${languagesData.languages[0].languageCode})`,
            }));
            const recipeVideo = getItemFromTranslation(recipe.recipeTranslations, currentChef.language.id, "video");
            if (recipeVideo) {
                setVideo(recipeVideo);
                setVideoSrc(recipeVideo);
                setVideoOrientation(recipe.videoVertical ? videoOrientationType.portrait : videoOrientationType.landscape);
                setTitleImage({
                    ...recipe.images[0],
                    image: `${REST_URL}/${recipe.images[0].path}-300.png`
                });
                setVideoLoading(true)
            } else {
                const sortedImages = [...recipe.images].sort((i1, i2) => i1.order > i2.order ? -1 : 1).map((image) => ({
                    ...image,
                    image: `${REST_URL}/${image.path}-300.png`
                }));
                setVideoLoading(false);
                if (sortedImages.length > 0) {
                    let element = document.getElementById("main-form-content");
                    element.style.backgroundImage = `url(${REST_URL}/${sortedImages[0].path}-300.png)`;
                }
                setImages(sortedImages);
            }
        }
        if (!recipe && currentChef && languagesData) {
            const chefLanguage = languagesData.languages.find((lang) => lang.id === currentChef.language.id);
            setLanguage(chefLanguage ? ({
                ...chefLanguage,
                value: chefLanguage.id,
                label: `${t(chefLanguage.languageCode)} (${chefLanguage.languageCode})`,
            }) : ({
                ...languagesData.languages[0],
                value: languagesData.languages[0].id,
                label: `${t(languagesData.languages[0].languageCode)} (${languagesData.languages[0].languageCode})`,
            }));
        }
    }, [recipe, currentChef, t, languagesData])

    const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    async function setImageAsBase64(file, isTitleImage) {
        if (file) {
            if (file.size < 10 * 1024 * 1024) {
                let newFile = file;
                setImageSizeTooBig(false);
                let base64 = await blobToBase64(newFile);
                if (isTitleImage) {
                    setTitleImage({
                        image: base64,
                        file: newFile,
                        order: 0
                    })
                    setMediaLoading(false);
                } else {
                    if (images.length === 0) {
                        let element = document.getElementById("main-form-content");
                        element.style.backgroundImage = `url(${base64})`;
                    }
                    setImages([...images, {
                        id: fakeID--,
                        image: base64,
                        order: images.length,
                        file: newFile,
                    }])
                    setMediaLoading(false);
                    setTitleImage(null);
                    setVideo(null);
                    setVideoSrc("");
                    if (addMorePictures) {
                        setMediaLoading(true);
                        setTimeout(() => {
                            setMediaLoading(false);
                        }, 500)
                    }
                }
            } else {
                setImageSizeTooBig(true);
                setMediaLoading(false);
            }
        }
    }

    const handleFile = (file, isTitleImage) => {
        if (recipe) {
            return;
        }
        if (file && file.type.includes("video")) {
            setVideo(file);
            new FileReader();
            let url = URL.createObjectURL(file);
            setVideoSrc(url);
            setImages([]);
            setImageSizeTooBig(false);
            setMediaLoading(false)
        } else if (file && file.type.includes("image") && images.length < 5) {
            setImageAsBase64(file, isTitleImage);
        }
    }

    const createRecipe = () => {
        setSaving(true);
        addRecipe({
            variables: {
                description,
                title,
                languageId: language.value,
                videoVertical: videoOrientation.portrait === videoOrientationType.portrait,
            },
        }).then((response) => {
            uploadMedia(response.data.addRecipe.id, titleImage, images, video);
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }

    const updateRecipeStep1Func = () => {
        setSaving(true);
        updateRecipeStep1({
            variables: {
                updateRecipeStep1Id: recipe.id,
                description,
                title,
            },
        }).then(() => {
            setSaving(false);
            setModalFullscreen(false);
            onNext();
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }

    const renderLoadingCarousel = () => (
        <div className="carousel-loader">
            <Spinner />
        </div>
    )

    const renderImages = () => (
        <div className="parent">
            <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={false}
                partialVisible={true}
                dotListClass="custom-dot-list-style"
            >
                {[...images].sort((i1, i2) => i1.order < i2.order ? -1 : 1).map((image, index) => {
                    return (
                        <div className="slider" key={index}>
                            <img src={image.image} alt={`recipe-stage-${index + 1}`} />
                            <div className={'carousel-btn-row'}>
                                {
                                    image.order > 0 &&
                                    <button className={'carousel-btn'} onClick={() => {
                                        let newImages = images.map((img) => {
                                            if (img.id === image.id) {
                                                if (image.order - 1 === 0) {
                                                    let element = document.getElementById("main-form-content");
                                                    element.style.backgroundImage = `url(${image.image})`;
                                                }
                                                return ({
                                                    ...img,
                                                    order: image.order - 1,
                                                })
                                            }
                                            if (img.order === image.order - 1) {
                                                return ({
                                                    ...img,
                                                    order: image.order,
                                                })
                                            }
                                            return img;
                                        })
                                        setImages(newImages);
                                    }}
                                    >
                                        <ArrowLeft />
                                    </button>
                                }
                                {
                                    image.order < images.length - 1 &&
                                    <button className={'carousel-btn'} onClick={() => {
                                        let newImages = images.map((img) => {
                                            if (img.id === image.id) {
                                                return ({
                                                    ...img,
                                                    order: image.order + 1,
                                                })
                                            }
                                            if (img.order === image.order + 1) {
                                                return ({
                                                    ...img,
                                                    order: image.order,
                                                })
                                            }
                                            return img;
                                        })
                                        setImages(newImages);
                                    }}
                                    >
                                        <ArrowRight />
                                    </button>
                                }
                                <button className={'carousel-btn'} onClick={() => {
                                    let newImages = images.filter((img) => img.id !== image.id).sort((i1, i2) => i1.order < i2.order ? -1 : 1).map((img, index) => ({
                                        ...img,
                                        order: index,
                                    }));

                                    if (image.order === 0 && newImages.length > 0) {
                                        let element = document.getElementById("main-form-content");
                                        element.style.backgroundImage = `url(${newImages[0].image})`;
                                    }

                                    if (newImages.length === 0) {
                                        let element = document.getElementById("main-form-content");
                                        element.style.backgroundImage = null;
                                        setAddMorePictures(false);
                                    }

                                    setImages(newImages);
                                }}
                                >
                                    {t('remove')}
                                </button>
                            </div>
                        </div>
                    );
                })}
                {
                    images.length < 5 &&
                    <div
                        className="slider add-new-image"
                        key={-1}
                    >
                        <label htmlFor={`upload-media-${uniqueID}-2`}>{t('addAnotherImage')}</label>
                        <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            invalid={imageSizeTooBig ? "true" : 'false'}
                            id={`upload-media-${uniqueID}-2`}
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                if (!recipe) {
                                    let files = e.target.files;
                                    setMediaLoading(true);
                                    handleFile(files[0]);
                                }
                            }}
                        />
                    </div>
                }
            </Carousel>
        </div>
    )

    const renderVideoOrientationButtons = () => (
        <div className='video-orientation-button-row'>
            <button className="btn-link" onClick={() => {
                setVideoOrientation(videoOrientationType.landscape);
            }}
            >
                <RectangleHorizontal color={videoOrientation === videoOrientationType.landscape ? "white" : "#555"} />
            </button>
            <button className="btn-link" onClick={() => {
                setVideoOrientation(videoOrientationType.portrait);
            }}
            >
                <RectangleVertical color={videoOrientation === videoOrientationType.portrait ? "white" : "#555"} />
            </button>
        </div>
    )

    const renderMediaUpload = () => {
        return (
            <div className={images.length > 0 ? 'main-form-content img-background' : "main-form-content"} id="main-form-content">
                {
                    videoLoading &&
                    <Spinner />
                }
                {video &&
                    renderVideoOrientationButtons()}
                {
                    video && !recipe &&
                    <Player
                        playsInline
                        src={videoSrc}
                        preload={"auto"}
                        fluid={false}
                        autoPlay={true}
                        className="recipe-video"
                    />
                }
                {
                    video && recipe &&
                    <ReactPlayer
                        url={videoSrc}
                        onReady={() => { console.log("Ready"); setVideoLoading(false) }}
                        controls={true}
                        className={videoLoading ? "recipe-video" : "recipe-video transition-test shown"}
                        style={videoLoading ? { display: "none", opacity: 0, transition: 'opacity 5s' } : { opacity: 1 }}
                    />
                }

                {
                    !recipe &&
                    <FileUploader
                        handleChange={(file) => {
                            handleFile(file);
                        }}
                        name="file"
                        types={["JPG", "JPEG", "PNG", "MP4", "MOV"]}
                        classes={images.length > 0 ? "invisible-media-input" : (video ? "absolute-media-input" : "media-input")}
                        hoverTitle={""}
                    >
                        {
                            (images.length > 0 || video) &&
                            <div></div>
                        }
                        {
                            images.length === 0 && !video &&
                            <>
                                <label className='info-text'>
                                    <Trans
                                        i18nKey="dragMediaOr"
                                    />
                                </label>
                                <label className='btn-outline' htmlFor={`upload-media-${uniqueID}`}>{t('loadFromFiles')}</label>

                                <input
                                    type="file"
                                    accept="image/png, image/jpeg, image/jpg, video/mp4"
                                    invalid={imageSizeTooBig ? "true" : "false"}
                                    id={`upload-media-${uniqueID}`}
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        let files = e.target.files;
                                        setMediaLoading(true);
                                        handleFile(files[0]);
                                    }}
                                />
                            </>
                        }
                    </FileUploader>
                }

                {
                    images.length > 0 &&
                    <div className={'btn-row-small'}>
                        <button className={addMorePictures ? 'btn-small-active' : 'btn-small'} onClick={() => {
                            setAddMorePictures(!addMorePictures);
                        }}
                        >
                            {t('addImages')}
                        </button>
                    </div>
                }
                <div className='button-row'>
                    <button className="btn-solid" onClick={() => {
                        onPrevious()
                    }}
                    >
                        {t('cancel')}
                    </button>
                    <button className="btn-solid" onClick={() => {

                        if (!modalFullscreen && (video || images.length > 0)) {
                            setModalFullscreen(true);
                        }
                        if (modalFullscreen && ((video && titleImage && (videoOrientation !== null)) || images.length > 0)) {
                            if (recipe) {
                                updateRecipeStep1Func()
                            } else {
                                createRecipe();
                            }
                        }

                    }}
                    >
                        {t('continueArrow')}
                    </button>
                </div>
            </div>
        )
    }


    const languageOptions = languagesData && languagesData.languages ? languagesData.languages.map((lang) => ({
        ...lang,
        value: lang.id,
        label: `${t(lang.languageCode)} (${lang.languageCode})`,
    })) : [];

    const renderExtraMediaInfo = () => (
        <div>
            <form className='recipe-form-large'>
                <Input
                    label={t('title')}
                    id="recipe-title"
                    type="text"
                    placeholder={""}
                    value={title}
                    setValue={setTitle}
                    limit={255}
                />
                <Input
                    label={t('description')}
                    type="textarea"
                    placeholder={t('addDescription')}
                    value={description}
                    setValue={setDescription}
                    limit={255}
                    rows={8}
                />
                <Input
                    label={t('recipeLanguage')}
                    id="recipe-language"
                    type="select"
                    isClearable={false}
                    isSearchable={true}
                    placeholder={""}
                    value={language}
                    setValue={setLanguage}
                    options={languageOptions}
                />
                {
                    video &&
                    <div className={'form-group'}>
                        <label>
                            {`${t('titleImage')}*`}
                        </label>
                        {
                            titleImage &&
                            <div className='title-image'>
                                <img src={titleImage ? titleImage.image : null} alt={`title`} />
                            </div>
                        }
                        {
                            !recipe &&
                            <label className='btn-link' htmlFor={`upload-media-title`}>{t('loadFromFiles')}</label>
                        }
                        {
                            !recipe &&
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, video/mp4"
                                invalid={imageSizeTooBig ? "true" : "false"}
                                id={`upload-media-title`}
                                style={{ display: 'none' }}
                                onChange={(e) => {
                                    let files = e.target.files;
                                    setMediaLoading(true);
                                    handleFile(files[0], true);
                                }}
                            />
                        }
                    </div>
                }
            </form>
        </div>
    )
    if (
        languagesLoading
    ) {
        return (
            <div className='modal-body-media'>
                <RecipeHeader stage={baseStages.recipe} closeModal={closeModal} />
                <div className='spinner'>
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div className='modal-body-media'>
            <div>
                <RecipeHeader stage={baseStages.media} closeModal={closeModal} />
                {
                    addMorePictures && !mediaLoading &&
                    renderImages()
                }
                {
                    addMorePictures && mediaLoading &&
                    renderLoadingCarousel()
                }
                {renderMediaUpload()}
            </div>
            {
                modalFullscreen &&
                renderExtraMediaInfo()
            }
        </div>
    );
}
