const { gql } = require('@apollo/client');

export const NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription notificationsSubscription {
        notificationsSubscription
    }
`;

export const GET_NOTIFICATIONS = gql`
    query notifications {
        notifications {
            id
            createdAt
            seen
            title
            type
            message
        }
    }
`;

export const SET_NOTIFICATION_SEEN = gql`
    mutation setNotificationSeen(
        $setNotificationSeenId: Int!
    ) {
        setNotificationSeen(id: $setNotificationSeenId)
    }
`;

export const SET_ALL_NOTIFICATIONS_SEEN = gql`
    mutation setAllNotificationsSeen {
        setAllNotificationsSeen
    }
`;

export const DELETE_NOTIFICATION = gql`
    mutation deleteNotification(
        $deleteNotificationId: Int!
    ) {
        deleteNotification(id: $deleteNotificationId)
    }
`;
