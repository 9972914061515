import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  LOGIN_CHEF,
} from 'queries';

import { setIsLoggedIn } from 'apollo/localSchema/actions';

import { useTranslation } from 'react-i18next';

import Input from "../ui/Input";
import Button from "../ui/Button";

const LandingSectionSignInForm = (props) => {
  const { t } = useTranslation();

  const [loginChef] = useMutation(LOGIN_CHEF);

  const { setSigningIn, error, setError } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const login = () => {
    setSigningIn(true);
    setError(null);

    loginChef({
      variables: {
        email,
        password,
      },
    })
      .then((response) => {
        setSigningIn(false);
        sessionStorage.setItem('acctok', response.data.loginChef.accessToken);
        setIsLoggedIn(true);
      })
      .catch((err) => {
        setError(err.message);
        setSigningIn(false);
      });
  };

  return (
    <form action="" className="landing-section-form" onSubmit={(e) => { e.preventDefault(); login() }}>
      <Input
        placeholder={t('email')}
        name="email"
        type="email"
        onBlur={() => { }}
        onChange={(e) => { setEmail(e.target.value) }}
      />
      <Input
        placeholder={t('password')}
        type="password"
        name="password"
        onBlur={() => { }}
        onChange={(e) => { setPassword(e.target.value) }}
      />
      <button type="submit" className="landing-section-btn" >
        {t('login')}
      </button>
      {error && <p className='error' style={{ overflowWrap: "break-word", maxWidth: "300px" }}>{error}</p>}
    </form>
  );
};

export default LandingSectionSignInForm;
