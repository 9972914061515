import React from "react";

import Button from "../ui/Button";
import AppleStoreIcon from "svg/AppleStoreIcon";
import GooglePlayIcon from "svg/GooglePlayIcon";
import LineIcon from "svg/LineIcon";

import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const LandingSectionSignWith = () => {
  const { t } = useTranslation()

  const navigate = useNavigate();
  return (
    <>
      <div className="landing-section-sign-with">
        <div className="landing-section-or">
          <LineIcon />
          <p className="m-b-0">{t('or')}</p>
          <LineIcon />
        </div>
        <p className="not-signed-yet">
          {t('noAccount')} <span className="m-l-4" onClick={() => { navigate(`/contact-us`); }}>{t('registerNow')}</span>
        </p>
        <p className="sign-with-socials">
        </p>
      </div>
      <Button type="button" className="signup-btn" onClick={() => { navigate(`/contact-us`); }}>
        {t('noAccount')} <span>{t('registerNow')}</span>
      </Button>
      <div className="platforms">
        <AppleStoreIcon width={115} height={34} />
        <GooglePlayIcon width={115} height={34} />
      </div>
    </>
  );
};

export default LandingSectionSignWith;
