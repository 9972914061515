export const difficultyOptions = Object.freeze([
    Object.freeze({
        value: 0,
        label: "beginner"
    }),
    Object.freeze({
        value: 1,
        label: "intermediate"
    }),
    Object.freeze({
        value: 2,
        label: "professional"
    }),
]);