import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { NavLink as Link } from 'react-router-dom';
import { Notes, Bell, Cog } from 'svg';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
    const { t } = useTranslation();
    return (
        <Nav fill pills vertical>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/`}
                >
                    <Notes />{t('profile')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/notifications`}
                >
                    <Bell />{t('notifications')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                    tag={Link}
                    to={`/settings`}
                >
                    <Cog />{t('settings')}
                </NavLink>
            </NavItem>
        </Nav>
    );
}
