interface StarIconProps {
  width: number;
  height: number;
  color: string;
}

const StarIcon = ({ width, height, color }: StarIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_0_60)">
        <mask
          id="mask0_0_60"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={width}
          height={height}
        >
          <path d="M85 0H0V95H85V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_0_60)">
          <path
            d="M0.227218 47.8018C-0.0567778 47.7066 -0.0567778 47.2622 0.227218 47.1987L25.5029 39.8983C30.5012 38.47 34.4203 34.0898 35.6983 28.5034L42.2302 0.254169C42.3154 -0.0632385 42.713 -0.0632385 42.7698 0.254169L49.3017 28.5034C50.5797 34.0898 54.4989 38.47 59.4972 39.8983L84.7728 47.1987C85.0568 47.2939 85.0568 47.7383 84.7728 47.8018L59.4972 55.1022C54.4989 56.5305 50.5797 60.9107 49.3017 66.4971L42.7698 94.7463C42.6846 95.0637 42.287 95.0637 42.2302 94.7463L35.6983 66.4971C34.4203 60.9107 30.5012 56.5305 25.5029 55.1022L0.227218 47.8018Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_0_60">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StarIcon;
