const LineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="139"
      height="2"
      viewBox="0 0 139 2"
      fill="none"
    >
      <path d="M0.787109 1L138.864 1.00003" stroke="black" />
      <path
        d="M0.787109 1L138.864 1.00003"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M0.787109 1L138.864 1.00003"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M0.787109 1L138.864 1.00003"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M0.787109 1L138.864 1.00003"
        stroke="black"
        strokeOpacity="0.2"
      />
    </svg>
  );
};

export default LineIcon;
