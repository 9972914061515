import { ReactNode } from "react";
import ListItem from "./ListItem";
/*
interface ListProps {
  list: { id: number; icon: ReactNode; text: string }[];
  className: string;
}
*/
const List = ({ list, className }/*: ListProps*/) => {
  return (
    <div className={`list ${className}`}>
      {list.map(({ id, icon, value }) => (
        <ListItem key={id} id={id} icon={icon} value={value} />
      ))}
    </div>
  );
};

export default List;
