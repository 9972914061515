import { useTranslation } from "react-i18next";
import BannerStarIcon from "svg/BannerStarIcon";

const BannerContent = () => {
  const { t } = useTranslation();
  return (
    <div className="banner-item">
      <div className="banner-content">
        <div className="banner-text">
          <p>
            {t('teachPeople')}
          </p>
        </div>
      </div>
      <BannerStarIcon />
    </div>
  );
};

export default BannerContent;
