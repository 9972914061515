import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloProvider, useQuery } from '@apollo/client';
import createClient from 'apollo/createClient';
import { GET_IS_LOGGED_IN } from 'apollo/queries';

import Navigation from 'navigation';
import PublicNavigation from 'publicNavigation';

import './i18n';

import "../node_modules/video-react/dist/video-react.css";
import 'react-material-symbols/dist/rounded.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import './scss/index.scss';


const root = ReactDOM.createRoot(
    document.getElementById('root')
);

const client = createClient();

const IsLoggedIn = () => {
    const { data } = useQuery(GET_IS_LOGGED_IN);

    if (data.isLoggedIn) {
        //    document.getElementById('root').style.backgroundImage = `url("../../public/images/bkg2.svg")`;
    }

    return data.isLoggedIn ? (
        <Navigation />
    ) : (
        <PublicNavigation />
    );
};

root.render(
    <ApolloProvider client={client}>
        <IsLoggedIn />
    </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
