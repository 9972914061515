export const RectangleHorizontal = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" viewBox="0 0 37 37" fill="none">
        <g clipPath="url(#clip0_260_13720)">
            <path d="M6.08333 12.2012H10.7083H13.7917H18.4167H21.5H26.125H30.75C31.5979 12.2012 32.3225 12.5095 32.9237 13.1108C33.525 13.712 33.8333 14.4366 33.8333 15.2845V28.5839C33.8333 29.4318 33.525 30.1564 32.9237 30.7576C32.3225 31.3589 31.5979 31.6672 30.75 31.6672H6.08333C5.23542 31.6672 4.51083 31.3589 3.90958 30.7576C3.30833 30.1564 3 29.4318 3 28.5839V15.2845C3 14.4366 3.30833 13.712 3.90958 13.1108C4.51083 12.5095 5.23542 12.2012 6.08333 12.2012ZM6.08333 15.2848V28.5839H30.75V15.2848H6.08333Z" fill={color ? color : "white"} />
        </g>
        <defs>
            <clipPath id="clip0_260_13720">
                <rect width="37" height="37" fill={color ? color : "white"} />
            </clipPath>
        </defs>
    </svg>
)