export const questionsItems = [
  {
    id: 1,
    question:
      "<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean <br/> et est a dui semper facilisis?</p>",
    answer: "",
  },
  {
    id: 2,
    question:
      "<p>Pellentesque placerat elit a nunc. Nullam tortor odio, rutrum quis,<br/> egestas ut, posuere sed, felis?</p>",
    answer: "",
  },
  {
    id: 3,
    question:
      "<p>Vestibulum placerat feugiat nisl. Suspendisse lacinia, odio non <br/> feugiat?</p>",
    answer: "",
  },
  {
    id: 4,
    question: "<p>Aenean et est a dui semper facilisis?</p>",
    answer: "",
  },
  {
    id: 5,
    question:
      "<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean <br/> et est a dui semper facilisis?</p>",
    answer: "",
  },
];
