export const Notes = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
        <g opacity="0.6">
            <rect x="7" y="4.66669" width="15.1667" height="19.8333" rx="2.42" stroke="#8A8A8A" strokeWidth="1.25" />
            <path d="M17.5 11.6666V9.33329" stroke="#8A8A8A" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M4.66602 10.5H9.33268" stroke="#8A8A8A" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M4.66602 15.1666H9.33268" stroke="#8A8A8A" strokeWidth="1.25" strokeLinecap="round" />
            <path d="M4.66602 19.8333H9.33268" stroke="#8A8A8A" strokeWidth="1.25" strokeLinecap="round" />
        </g>
    </svg>
)