import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
    UPDATE_RECIPE_STEP_3,
    GET_RECIPE_EXTRA_ATTRIBUTES
} from 'queries';

import { Spinner } from 'reactstrap';

import Input from 'components/Input';
import RecipeHeader from 'components/RecipeHeader';
import { difficultyOptions, priceRange as priceRangeOptions, foodAllergens, baseStages } from 'constants';
import { useTranslation } from 'react-i18next';

export default function RecipeExtraInfo(props) {
    const {
        recipe,
        recipeId,
        setSaving,
        onNext,
        onPrevious,
        closeModal,
    } = props;

    const { t } = useTranslation();

    const [updateRecipeStep3] = useMutation(UPDATE_RECIPE_STEP_3);

    const [difficulty, setDifficulty] = useState({ ...difficultyOptions[1], label: t(difficultyOptions[1].label) });
    const [preparationTime, setPreparationTime] = useState(null);
    const [calories, setCalories] = useState(0);
    const [allergens, setAllergens] = useState([]);
    const [diets, setDiets] = useState([]);
    const [mealType, setMealType] = useState(null);
    const [priceRange, setPriceRange] = useState(null);

    const {
        data: extraData,
        loading: extraLoading,
    } = useQuery(GET_RECIPE_EXTRA_ATTRIBUTES, {
        variables: {
            available: true,
            mealTypesAvailable2: true,
            preparationTimesAvailable2: true
        },
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (recipe && extraData && extraData.diets && extraData.mealTypes && extraData.preparationTimes) {
            const diff = difficultyOptions.find((difficulty) => difficulty.value === recipe.difficulty);
            setDifficulty({ ...diff, label: t(diff.label) });
            setPreparationTime(recipe.preparationTime ? { ...recipe.preparationTime, value: recipe.preparationTime.id, label: t(diff.recipe.preparationTime.title) } : null);
            setCalories(recipe.calories);
            setAllergens(recipe.allergens.map((allergen) => foodAllergens.find((fa) => fa.value === allergen)));
            setMealType(recipe.mealType ? { ...recipe.mealType, value: recipe.mealType.id, label: t(diff.recipe.mealType.title) } : null);
            setDiets(recipe.diets.map((diet) => ({
                ...diet,
                value: diet.id,
                label: t(diet.title),
            })))
            const price = priceRangeOptions.find((p) => p.value === recipe.priceRange);
            setPriceRange({ ...price, label: t(price.label) });
        } else {
            setDifficulty({ ...difficultyOptions[1], label: t(difficultyOptions[1].label) });
            setPreparationTime(null);
            setCalories(0);
            setAllergens([]);
            setMealType(null);
            setDiets([]);
            setPriceRange({ ...priceRangeOptions[1], label: t(priceRangeOptions[1].label) });

        }
    }, [recipe, t, extraData]);

    const updateRecipeStep3Func = () => {
        setSaving(true);

        updateRecipeStep3({
            variables: {
                id: recipeId,
                difficulty: difficulty.value,
                calories: parseInt(calories),
                allergens: allergens.map((allergen) => allergen.value),
                priceRange: priceRange.value,
                mealTypeId: mealType?.value,
                preparationTimeId: preparationTime?.value,
                dietIds: diets.map((allergen) => allergen.value),
            },
        }).then(() => {
            setSaving(false);
            onNext();
        }).catch((err) => {
            console.log(err);
            setSaving(false);
        })
    }


    if (
        extraLoading
    ) {
        return (
            <div className='modal-body-extra'>
                <RecipeHeader stage={baseStages.extra} closeModal={closeModal} />
                <div className='spinner'>
                    <Spinner />
                </div>
            </div>
        );
    }

    const mealTypeOptions = extraData && extraData.mealTypes ? extraData.mealTypes.map((lang) => ({
        ...lang,
        value: lang.id,
        label: t(lang.title),
    })) : [];


    const dietOptions = extraData && extraData.diets ? extraData.diets.map((lang) => ({
        ...lang,
        value: lang.id,
        label: t(lang.title)
    })) : [];


    const preparationTimeOptions = extraData && extraData.preparationTimes ? extraData.preparationTimes.map((lang) => ({
        ...lang,
        value: lang.id,
        label: t(lang.title)
    })) : [];

    const renderForm = () => (
        <>
            <Input
                label={t('difficulty')}
                id="recipe-difficulty"
                type="select"
                isClearable={false}
                isSearchable={true}
                placeholder={""}
                value={difficulty}
                setValue={setDifficulty}
                options={difficultyOptions.map((diff) => ({ ...diff, label: t(diff.label) }))}
            />
            <div className='language-title-row no-gap'>
                <Input
                    label={t('prepTime')}
                    id="recipe-preparationTime"
                    type="select"
                    isClearable={false}
                    isSearchable={true}
                    placeholder={""}
                    value={preparationTime}
                    setValue={setPreparationTime}
                    options={preparationTimeOptions}
                />
                <Input
                    label={t('calories')}
                    id="recipe-calories"
                    type="number"
                    step={5}
                    value={calories}
                    setValue={setCalories}
                />
            </div>
            <Input
                label={t('allergens')}
                id="recipe-allergens"
                type="select"
                isMulti={true}
                isClearable={false}
                isSearchable={true}
                placeholder={""}
                value={allergens}
                setValue={setAllergens}
                options={foodAllergens}
            />
            <Input
                label={t('diets')}
                id="recipe-diets"
                type="select"
                isMulti={true}
                isClearable={false}
                isSearchable={true}
                placeholder={""}
                value={diets}
                setValue={setDiets}
                options={dietOptions}
            />
            <Input
                label={t('mealType')}
                id="recipe-type"
                type="select"
                isClearable={false}
                isSearchable={true}
                placeholder={""}
                value={mealType}
                setValue={setMealType}
                options={mealTypeOptions}
            />
            <Input
                label={t('priceRange')}
                id="recipe-price"
                type="select"
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                placeholder={""}
                value={priceRange}
                setValue={setPriceRange}
                options={priceRangeOptions.map((diff) => ({ ...diff, label: t(diff.label) }))}
            />
        </>
    )

    const renderButtonRow = () => (
        <div className='button-row'>
            <button className="btn-solid" onClick={() => {
                onPrevious();
            }}
            >
                {t('back')}
            </button>
            <button className="btn-solid" onClick={() => {
                updateRecipeStep3Func()
            }}
            >
                {t('continueArrow')}
            </button>
        </div>
    )

    return (
        <div className='modal-body-extra'>
            <div>
                <RecipeHeader stage={baseStages.extra} closeModal={closeModal} />
                <form className='recipe-form-medium'>
                    {renderForm()}
                    {renderButtonRow()}
                </form>
            </div>
        </div>
    )

}
