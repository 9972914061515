import React from "react";
import WhyUsList from "./WhyUsList";
import { useTranslation } from "react-i18next";

const WhyUsContent = () => {
  const { t } = useTranslation();
  return (
    <div className="why-us-content">
      <h1>{t('whyUs')}</h1>
      <WhyUsList />
    </div>
  );
};

export default WhyUsContent;
