import { gql } from '@apollo/client';

export const INGREDIENTS_SUBSCRIPTION = gql`
    subscription ingredientsSubscription {
        ingredientsSubscription
    }
`;

export const INGREDIENTS = gql`
    query ingredients($search: String, $languageId: Int) {
        ingredients(
            search: $search
            languageId: $languageId
        ) {
            id
            units
            translations {
                title
                language {
                    id
                    title
                    languageCode
                }
            }
        }
    }
`;
