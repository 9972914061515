/*interface InputProps {
  placeholder: string;
  name: string;
  type: "email" | "password" | "text";
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}*/

const Input = ({ placeholder, name, type, onChange, onBlur }/*: InputProps*/) => {
  return (
    <input
      name={name}
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      className="form-input"
    />
  );
};

export default Input;
