import React from 'react';

import RecipeHeader from 'components/RecipeHeader';
import { Trans, useTranslation } from 'react-i18next';

export default function RecipeThankYou(props) {
    const {
        toggle
    } = props;

    const { t } = useTranslation();

    const renderButtonRow = () => (
        <div className='button-row'>
            <button className="btn-solid" onClick={() => {
                toggle();
            }}
            >
                {t('close')}
            </button>
        </div>
    )

    return (
        <div className='modal-body-thank-you'>
            <div>
                <RecipeHeader stage={null} showLabel={false} closeModal={toggle} />
                <p>
                    <Trans
                        i18nKey="recipeCreationThankYou"
                    />

                </p>
                {renderButtonRow()}
            </div>
        </div>
    )

}
