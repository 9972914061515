import { useState } from "react";
import LandingSectionContentLeft from "./LandingSectionContentLeft";
import LandingSectionContentRight from "./LandingSectionContentRight";
import { useMediaQuery } from "react-responsive";
import SignInBtn from "./SignInBtn";
import SigninSidebar from "./SigninSidebar";

const LandingSectionContent = () => {
  const [clicked, setClicked] = useState(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1500px)",
  });
  return (
    <>
      <div className="landing-section-content">
        <LandingSectionContentLeft />
        {isDesktop && !clicked ? (
          <LandingSectionContentRight />
        ) : (
          <SignInBtn setClicked={setClicked} />
        )}
        {clicked && !isDesktop && <SigninSidebar setClicked={setClicked} />}
      </div>
    </>
  );
};

export default LandingSectionContent;
