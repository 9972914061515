import { responsibilityItems } from "configs/responsibilityItems";
import ResponsibiltyItem from "./ResponsibiltyItem";

const ResponsibilityGrid = () => {
  return (
    <div className="responsibility-grid">
      {responsibilityItems.map(({ id, className, value }) => (
        <ResponsibiltyItem key={id} className={className} value={value} />
      ))}
    </div>
  );
};

export default ResponsibilityGrid;
