interface MicrophoneIconProps {
  width: number;
  height: number;
}

const MicrophoneIcon = ({ width, height }: MicrophoneIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${height} ${width}`}
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M49.748 61.68C43.818 61.68 39.008 56.87 39.008 50.94V30.74C39.008 24.81 43.818 20 49.748 20C55.678 20 60.488 24.81 60.488 30.74V50.94C60.488 56.87 55.678 61.68 49.748 61.68Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.9031 40.8398V50.9398C65.9031 59.8499 58.6531 67.0999 49.7431 67.0999C40.8331 67.0999 33.5881 59.8499 33.5881 50.9398V40.8398"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.748 67.0947V81.4997"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.209 81.5H58.289"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0254 29.8301H43.7354"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0254 37.1709H43.7354"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0254 44.5098H43.7354"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.0254 51.8506H43.7354"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7637 29.8301H60.4737"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7637 37.1709H60.4737"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7637 44.5098H60.4737"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.7637 51.8506H60.4737"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.1152 28.7441C74.8852 32.5141 74.8852 38.6241 71.1152 42.3891"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.7539 24.1094C82.0839 30.4394 82.0839 40.6994 75.7539 47.0294"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3841 42.395C24.6141 38.625 24.6141 32.515 28.3841 28.75"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.7438 47.0294C17.4187 40.6994 17.4187 30.4394 23.7438 24.1094"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MicrophoneIcon;
