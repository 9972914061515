export const getLabel = (translations, languageId, attribute = "title", alt = "noTitle", t = null) => {
    if (!translations || translations.length === 0) {
        return t ? t(alt) : alt;
    }
    let translation = translations.find((translation) => translation.language?.id === languageId);
    if (!translation) {
        return t ? t(alt) : alt;
    }
    return translation[attribute] ? translation[attribute] : t ? t(alt) : alt;
}

export const getItemFromTranslation = (translations, languageId, attribute = "title") => {
    if (!translations || translations.length === 0) {
        return null;
    }
    let translation = translations.find((translation) => translation.language?.id === languageId);

    if (!translation) {
        return null;
    }
    return translation[attribute] ? translation[attribute] : null;
}