import React from "react";
import StarSectionWrapper from "./StarSectionWrapper";
import StarSectionBottom from "./StarSectionBottom";

const StarSection = () => {
  return (
    <section id="star-section">
      <StarSectionWrapper />
    </section>
  );
};

export default StarSection;
