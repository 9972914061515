import List from "../helpers/List";
import Button from "../ui/Button";
import { useNavigate } from 'react-router-dom';
import { starItems } from "configs/starItems";
import { Trans } from "react-i18next";
import { t } from "i18next";

const StarSectionRight = () => {
  const navigate = useNavigate();
  return (
    <div className="star-section-right">
      <h2>
        <Trans i18nKey='makeAStar' />
      </h2>
      <List list={starItems} className="star-section-list" />
      <Button type="button" className="star-btn" onClick={() => { navigate(`/contact-us`); }}>
        {t('imIn')}
      </Button>
    </div>
  );
};

export default StarSectionRight;
