import React, { useState } from 'react';
import {
    useQuery,
    useSubscription,
} from '@apollo/client';
import { Label, Spinner } from 'reactstrap';
import {
    MY_RECIPES,
    RECIPES_SUBSCRIPTION,
} from 'queries';
import RecipeBtn from 'pages/profile/recipes/form';
import List from './list';
import Grid from './grid';
import { Grid as GridSVG, List as ListSVG } from 'svg';
import { useTranslation } from 'react-i18next';
import { REST_URL } from 'configs/restAPI';
import axios from 'axios';

export const listOptions = Object.freeze({
    grid: 'grid',
    list: 'list',
});

export default function RecipesContainer() {

    const { t } = useTranslation();

    const [listStyle, setListStyle] = useState(listOptions.grid);
    const [mediaUploading, setMediaUploading] = useState(false);
    const [uploadingError, setUploadingError] = useState(null);

    const {
        data: recipesData,
        loading: recipesLoading,
        refetch: recipesRefetch,
    } = useQuery(MY_RECIPES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(RECIPES_SUBSCRIPTION, {
        onData: () => {
            recipesRefetch();
        },
    });


    const uploadVideo = (recipeId, titleImage, video) => {
        const formData = new FormData();

        formData.append(`file`, video);

        formData.append(
            'token',
            `${sessionStorage.getItem('acctok')}`
        );
        formData.append('recipeId', recipeId);
        try {
            axios
                .post(`${REST_URL}/api/upload_video`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    uploadImages(recipeId, titleImage);
                });
        } catch (e) {
            uploadImages(recipeId, titleImage);
            setUploadingError(e.message);
            console.log(e);
        }
    }

    const uploadImages = (recipeId, titleImage, images) => {
        const formData = new FormData();

        let imagesToSave = [];
        if (titleImage) {
            imagesToSave.push(titleImage);
        } else {
            imagesToSave = images;
        }
        imagesToSave.forEach((img) => {
            formData.append(`file`, img.file);
        });
        formData.append(`newImagesOrder`, JSON.stringify(imagesToSave.map((img) => img.order)));
        formData.append(`updateImagesData`, JSON.stringify([]));
        formData.append(`deleteImagesIds`, JSON.stringify([]));

        formData.append(
            'token',
            `${sessionStorage.getItem('acctok')}`
        );

        formData.append('recipeId', recipeId);
        try {
            axios
                .post(`${REST_URL}/api/upload_images`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    setMediaUploading(false);
                });
        } catch (e) {
            setUploadingError(e.message);
            console.log(e);
        }
    }

    if (
        recipesLoading
    ) {
        return (
            <div className='loading'>
                <Spinner />
            </div>
        );
    }

    const renderContent = () => {
        if (!recipesData || !recipesData.myRecipes || recipesData.myRecipes.length === 0) {
            return (<Label>{t('noRecipes')}</Label>)
        }
        switch (listStyle) {
            case listOptions.list:
                return <List recipes={recipesData && recipesData.myRecipes ? recipesData.myRecipes : []} setMediaUploading={setMediaUploading} uploadVideo={uploadVideo} uploadImages={uploadImages} />
            default:
                return <Grid recipes={recipesData && recipesData.myRecipes ? recipesData.myRecipes : []} setMediaUploading={setMediaUploading} uploadVideo={uploadVideo} uploadImages={uploadImages} />
        }
    }

    return (
        <div className='recipes-container'>
            <div className='heading'>
                <RecipeBtn setMediaUploading={setMediaUploading} uploadVideo={uploadVideo} uploadImages={uploadImages} />
                <div className='btn-row'>
                    <button className="carousel-btn" onClick={() => { setListStyle(listOptions.grid) }}>
                        <GridSVG color={listStyle === listOptions.grid ? "black" : "#8A8A8A"} />
                    </button>
                    <button className="carousel-btn" onClick={() => { setListStyle(listOptions.list) }}>
                        <ListSVG color={listStyle === listOptions.list ? "black" : "#8A8A8A"} />
                    </button>
                </div>
            </div>
            <hr />
            {
                renderContent()
            }
            {
                mediaUploading &&
                <div className='media-uploading'>
                    <Spinner /> <label>{t('mediaUploading')}</label>
                </div>
            }
        </div>
    );
}
