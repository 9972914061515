import { gql } from '@apollo/client';

export const LOGIN_CHEF = gql`
    mutation loginChef(
        $email: String!
        $password: String!
    ) {
        loginChef(
            email: $email
            password: $password
        ) {
            chef {
                id
                fullName
            }
            accessToken
        }
    }
`;

export const LOGOUT_CHEF = gql`
    mutation logoutChef {
        logoutChef
    }
`;

export const RESEND_CHEF_VERIFICATION_EMAIL = gql`
    mutation resendChefEmailVerification($email: String) {
        resendChefEmailVerification(email: $email)
    }
`;
