import { gql } from '@apollo/client';

export const RECIPES_SUBSCRIPTION = gql`
    subscription myRecipesSubscription {
        myRecipesSubscription
    }
`;

export const MY_RECIPES = gql`
    query myRecipes {
        myRecipes {
            id
            updatedAt
            images {
                id
                order
                path
            }
            inProgress
            difficulty
            progress
            visible
            recipeIngredients{
                id
            }
            recipeTranslations {
                id
                def
                language {
                    id
                    languageCode
                    title
                }
                title
                video
            }
            recipeSubtitles {
                path
                language {
                    id
                }
            }
        }
    }
`;

export const GET_RECIPE = gql`
    query recipe($recipeId: Int!) {
        recipe(id: $recipeId) {
            id
            videoVertical
            recipeTranslations {
                id
                def
                language {
                    id
                    languageCode
                }
                title
                video
                description
                preparation {
                    id
                    text
                    order
                }
            }
            recipeSubtitles {
                path
                language {
                    id
                }
            }
            allergens
            calories
            chef {
                fullName
            }
            difficulty
            servingIncrementType
            inProgress
            mealType{
                id
                title
            }
            diets{
                id
                order
                title
            }
            priceRange
            progress
            servings
            visible
            recipeIngredients {
                id
                amount
                prefix
                suffix
                unit
                ingredient {
                    id
                    units
                    translations {
                        title
                        language {
                            id
                            languageCode
                        }
                    }
                }
                alternatives {
                    id
                    order
                    translations {
                        language {
                            id
                            languageCode
                        }
                        title
                    }
                }
            }
            images {
                id
                order
                path
            }
        }
    }
`;

export const ADD_RECIPE = gql`
    mutation addRecipe(
        $title: String!
        $description: String!
        $languageId: Int!
        $videoVertical: Boolean
    ) {
  addRecipe(
  title: $title, 
  description: $description, 
  languageId: $languageId,
  videoVertical: $videoVertical
  ) {
            id
            recipeTranslations {
                id
            }
        }
    }
`;

export const UPDATE_RECIPE_STEP_1 = gql`
mutation updateRecipeStep1(
  $id: Int!
  $title: String!
  $description: String
) {
  updateRecipeStep1(
  id: $id, 
  title: $title, 
  description: $description
  ) {
    id
  }
}
`;

export const UPDATE_RECIPE_STEP_2 = gql`
mutation updateRecipeStep2(  
    $id: Int!
    $servings: Int
    $servingIncrementType: ServingIncrementTypeEnum
    $preparation: [PreparationStepInput!]
    $addIngredients: [AddRecipeIngredientInput!]!
    $updateIngredients: [UpdateRecipeIngredientInput!]!
    $deleteIngredients: [Int!]!
) {
  updateRecipeStep2(
    id: $id
    servings: $servings
    preparation: $preparation
    addIngredients: $addIngredients
    updateIngredients: $updateIngredients
    deleteIngredients: $deleteIngredients
    servingIncrementType: $servingIncrementType
  ) {
    id
  }
}
`;


export const UPDATE_RECIPE_STEP_3 = gql`
mutation updateRecipeStep3(
  $id: Int!
  $difficulty: Int
  $calories: Int
  $allergens: [Int!]
  $preparationTimeId: Int
  $mealTypeId: Int
  $dietIds: [Int!]
  $priceRange: Int
) {
  updateRecipeStep3(
  id: $id, 
  difficulty: $difficulty, 
  calories: $calories, 
  allergens: $allergens, 
  preparationTimeId: $preparationTimeId, 
  mealTypeId: $mealTypeId, 
  dietIds: $dietIds, 
  priceRange: $priceRange
  ) {
    id
  }
}
`;

export const UPDATE_RECIPE_STEP_4 = gql`
mutation updateRecipeStep4($id: Int!) {
  updateRecipeStep4(id: $id) {
    id
  }
}`;

export const UPDATE_RECIPE_STEP_6 = gql`
mutation updateRecipeStep6($id: Int!, $subtitles: String!) {
  updateRecipeStep6(id: $id, subtitles: $subtitles) {
    id
  }
}`;


export const DELETE_RECIPE = gql`
    mutation deleteRecipe($id: Int!) {
        deleteRecipe(id: $id) {
            id
        }
    }
`;
