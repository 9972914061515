import { Swiper, SwiperSlide } from "swiper/react";
import SwiperItem from "./SwiperItem";
import { swiperItems } from "configs/swiperItems";
import SwiperButtons from "./SwiperButtons";
import { swiperBreakPoints } from "configs/swiperBreakPoints";

import 'swiper/css';

const SwiperList = () => {
  return (
    <div className="swiper-list">
      <Swiper
        breakpoints={swiperBreakPoints()}
        slidesOffsetBefore={-120}
        loop={true}
        spaceBetween={60}
      >
        <SwiperButtons />
        {swiperItems.map(({ id, className, content }) => (
          <SwiperSlide key={id}>
            <SwiperItem id={id} className={className} content={content} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperList;
