import { ReactNode } from "react";
import parse from "html-react-parser";
import { Trans, useTranslation } from "react-i18next";
/*interface AboutUsItemProps {
  id: number;
  icon: ReactNode;
  text: string;
}*/

const AboutUsItem = ({ id, icon, value }/*: AboutUsItemProps*/) => {

  const { t } = useTranslation();

  return (
    <div className="about-us-item">
      {icon}
      <Trans
        i18nKey={value}
      />
    </div>
  );
};

export default AboutUsItem;
