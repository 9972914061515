import React from "react";
import BusinessSectionContent from "./BusinessSectionContent";

const BusinessSection = () => {
  return (
    <section id="business-section">
      <BusinessSectionContent />
    </section>
  );
};

export default BusinessSection;
