import { aboutUsItems } from 'configs/aboutUsItems';
import AboutUsItem from "./AboutUsItem";

const AboutUsList = () => {
  return (
    <div className="about-us-list">
      {aboutUsItems.map(({ id, icon, value }) => (
        <AboutUsItem key={id} id={id} icon={icon} value={value} />
      ))}
    </div>
  );
};

export default AboutUsList;
