import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Modal,
} from 'reactstrap';
import { CHEF_CHANGE_EMAIL } from 'queries';
import Input from 'components/Input';
import { isEmail } from 'utils';
import { useTranslation } from 'react-i18next';

export default function ChangeEmail() {
    const { t } = useTranslation();

    const [chefChangeEmail] = useMutation(
        CHEF_CHANGE_EMAIL
    );

    const [email, setEmail] = useState('');

    const [confirmationCode, setConfirmationCode] =
        useState('');

    const [modalOpen, setModalOpen] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [emailChanged, setEmailChanged] = useState(false);
    const [requestSent, setRequestSent] = useState(false);
    const [
        resentConfirmationCode,
        setResentConfirmationCode,
    ] = useState(false);

    const toggle = () => {
        setEmail('');
        setConfirmationCode('');
        setSaveError(null);
        setEmailChanged(false);
        setRequestSent(false);

        setModalOpen(!modalOpen);
    };

    const chefChangeEmailFunc = () => {
        let data = {
            email,
        };
        if (requestSent) {
            data = {
                ...data,
                confirmationCode,
            };
        }
        chefChangeEmail({
            variables: data,
        })
            .then((response) => {
                if (requestSent) {
                    setEmailChanged(true);
                } else {
                    setRequestSent(true);
                }
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    return (
        <>
            <button
                className="btn-link"
                onClick={(e) => {
                    e.preventDefault();
                    toggle();
                }}
            >
                {t('changeEmail')}

            </button>
            <Modal
                isOpen={modalOpen}
                toggle={toggle}
                size='xl'
                contentClassName='settings-modal'
            ><div className="settings-header">
                    <h1>
                        {t('emailChange')}
                    </h1>
                </div>
                <form>
                    <Input
                        label={t('newEmail')}
                        id="chef-email"
                        type="text"
                        placeholder="example@mail.com"
                        error={
                            email.length > 0 && !isEmail(email) ? t('invalidEmail') : null
                        }
                        value={email}
                        setValue={(value) => {
                            setEmail(
                                value
                            );
                            setEmailChanged(
                                false
                            );
                            setSaveError(null);
                        }}
                    />

                    {requestSent && (
                        <label>
                            {t('confirmationCodeSent')}
                        </label>
                    )}
                    {requestSent && (
                        <Input
                            label={t('confirmationCode')}
                            id="chef-password-confirmation"
                            invalid={saveError}
                            type="text"
                            value={confirmationCode}
                            setValue={(value) => {
                                setConfirmationCode(
                                    value
                                );
                            }}
                        />
                    )}

                    {requestSent && (
                        <button
                            className="btn-link"
                            onClick={() => {
                                if (
                                    isEmail(email)
                                ) {
                                    setResentConfirmationCode(
                                        true
                                    );
                                    chefChangeEmail(
                                        {
                                            variables:
                                            {
                                                email,
                                            },
                                        }
                                    );
                                }
                            }}
                        >
                            {t('resendCode')}

                        </button>
                    )}
                    {resentConfirmationCode && (
                        <p>
                            {t('codeResent')}
                        </p>
                    )}

                    {saveError && (
                        <p className='error'>{saveError}</p>
                    )}

                    {emailChanged && (
                        <label>
                            {t('emailChanged')}
                        </label>
                    )}


                    <div className='button-row'>
                        <button
                            className="btn-solid"
                            onClick={(e) => {
                                e.preventDefault();
                                toggle();
                            }}
                        >
                            {t('discardChanges')}
                        </button>
                        <button
                            className="btn-solid"
                            disabled={email.length === 0 || (email.length > 0 && !isEmail(email))}
                            onClick={() => {
                                chefChangeEmailFunc();
                            }}
                        >
                            {t('saveChanges')}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    );
}
