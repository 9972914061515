const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M70.12 19H29V80.5H70.12V19Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3208 25.5801H40.2308L38.4358 29.4001L36.6458 25.5801H34.5508V32.5301H36.1008V28.1551L37.9658 31.9501H38.8908L40.7708 28.1551V32.5301H42.3208V25.5801Z"
        fill="#373E44"
      />
      <path
        d="M49.0393 25.5801H44.0293V32.5301H49.1443V31.1551H45.5793V29.7151H48.6893V28.3951H45.5793V26.9651H49.0393V25.5801Z"
        fill="#373E44"
      />
      <path
        d="M56.9393 25.5801H55.3893V30.0551L51.9793 25.5801H50.5293V32.5301H52.0793V28.1751L55.3893 32.5301H56.9393V25.5801Z"
        fill="#373E44"
      />
      <path
        d="M64.5019 25.5801H62.9519V29.4151C62.9519 29.9701 62.8219 30.4101 62.5719 30.7301C62.3169 31.0501 61.9719 31.2101 61.5319 31.2101C61.0919 31.2101 60.7469 31.0501 60.4869 30.7301C60.2269 30.4101 60.0969 29.9701 60.0969 29.4151V25.5801H58.5469V29.4651C58.5469 30.4651 58.8269 31.2351 59.3869 31.7801C59.9469 32.3251 60.6619 32.5951 61.5269 32.5951C62.3919 32.5951 63.1069 32.3251 63.6619 31.7851C64.2169 31.2451 64.4969 30.4701 64.4969 29.4651V25.5801H64.5019Z"
        fill="#373E44"
      />
      <path
        d="M34.5508 42.3252H46.5908"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5312 42.3252H64.5713"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5508 52.8555H46.5908"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5312 52.8555H64.5713"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5508 63.3848H46.5908"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5312 63.3848H64.5713"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5508 73.915H46.5908"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5312 73.915H64.5713"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
