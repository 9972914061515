import ListItemIcon from "../svg/ListItemIcon";
export const starItems = [
  {
    id: 1,
    icon: <ListItemIcon />,
    value: 'star-1',
    text: "<p>It doesn’t matter, who you are, where you </br> come from and which language you speak.</p>",
  },
  {
    id: 2,
    icon: <ListItemIcon />,
    value: 'star-2',
    text: "<p>Thanks to AI, your recipes and videos will be </br> localized into the languages ​​of the users.</p>",
  },
  {
    id: 3,
    icon: <ListItemIcon />,
    value: 'star-3',
    text: "<p>Nothing limits you, no borders, no language </br> barrier. Just you, your imagination, and a global</br> audience.</p>",
  },
  {
    id: 4,
    icon: <ListItemIcon />,
    value: 'star-4',
    text: "<p>Become a part of every modern household.</p>",
  },
];
