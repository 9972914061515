import { ReactNode } from "react";
/*
interface SwiperItemProps {
  id: number;
  className: string;
  content: ReactNode;
}
*/
const SwiperItem = ({ id, className, content }/*: SwiperItemProps*/) => {
  return (
    <>
      {" "}
      <div className={`swiper-item ${className}`}></div> {content}
    </>
  );
};

export default SwiperItem;
