import React from "react";
import AboutUsSectionContent from "./AboutUsSectionContent";
import AboutUsBackground from "./AboutUsBackground";

const AboutUsSection = () => {
  return (
    <section id="about-us-section">
      <AboutUsBackground />
    </section>
  );
};

export default AboutUsSection;
