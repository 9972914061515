const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M79.945 19H21V71.5H79.945V19Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.5648 25.0293H27.3848V60.4743H73.5648V25.0293Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.32 71.5049H40.625C38.14 71.5049 36.125 73.5199 36.125 76.0049C36.125 78.4899 38.14 80.5049 40.625 80.5049H60.32C62.805 80.5049 64.82 78.4899 64.82 76.0049C64.82 73.5199 62.805 71.5049 60.32 71.5049Z"
        stroke="#303C42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.2793 57.0707V31.8057"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4902 57.0699V37.0898"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.7051 41.2646V57.0697"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.5438 40.7299C69.5338 44.5249 68.0688 49.2149 64.2738 51.2049C60.4788 53.1949 55.7888 51.7299 53.7988 47.9349C51.8088 44.1399 53.2738 39.4499 57.0688 37.4599C60.8638 35.4699 65.5538 36.9349 67.5438 40.7299Z"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.8503 73.4195L64.9702 53.1545L64.1152 51.5244"
        stroke="#303C42"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
