import { gql } from '@apollo/client';

export const CHEF_DATA_SUBSCRIPTION = gql`
    subscription chefDataSubscription {
        chefDataSubscription
    }
`;

export const GET_MY_DATA = gql`
    query {
        chefGetMyData {
          id
          active
          deactivationDate
          email
          fullName
          name
          surname
          individual
          phoneNumber
          iconPath
          title
          description
          location
          ordersCount
          followersCount
          recipesCount
          language {
            id
            languageCode
            title
          }
          instagram
          nickname
          youtube
        }
    }
`;