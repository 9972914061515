export const CheckMarkCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
        <circle cx="15" cy="15" r="11.25" stroke="black" strokeWidth="1.5625" />
        <circle cx="15" cy="15" r="11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <circle cx="15" cy="15" r="11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <circle cx="15" cy="15" r="11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <circle cx="15" cy="15" r="11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <path d="M10 15L13.75 18.75L20 11.25" stroke="black" strokeWidth="1.5625" />
        <path d="M10 15L13.75 18.75L20 11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <path d="M10 15L13.75 18.75L20 11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <path d="M10 15L13.75 18.75L20 11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
        <path d="M10 15L13.75 18.75L20 11.25" stroke="black" strokeOpacity="0.2" strokeWidth="1.5625" />
    </svg>
)