import React from "react";
import BusinessLeft from "./BusinessLeft";
import BusinessRight from "./BusinessRight";

const BusinessSectionContent = () => {
  return (
    <div className="business-section-content">
      <BusinessLeft />
      <BusinessRight />
    </div>
  );
};

export default BusinessSectionContent;
