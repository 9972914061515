import Chef from "assets/images/star_section/chef.png";
import { Trans, useTranslation } from "react-i18next";

const StarSectionBottom = () => {
  const { t } = useTranslation();
  return (
    <div className="star-section-bottom">
      <h1>
        <Trans
          i18nKey="worldIsYours"
        />
      </h1>
      <img src={Chef} alt="chef" />
    </div>
  );
};

export default StarSectionBottom;
