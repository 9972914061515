import React from "react";
import SwiperList from "./SwiperList";

const SwiperSection = () => {
  return (
    <section id="swiper-section">
      <SwiperList />
    </section>
  );
};

export default SwiperSection;
