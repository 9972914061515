import ListItemIcon from "../svg/ListItemIcon";

export const aboutUsItems = [
  {
    id: 1,
    icon: <ListItemIcon />,
    value: 'aboutUsItem-1',
    text: "<p>Become part of our community, which shapes<br/>global gastronomy, without borders.</p>",
  },
  {
    id: 2,
    icon: <ListItemIcon />,
    value: 'aboutUsItem-2',
    text: "<p>Build your fan base regardless of language <br/> and location, spread your ideas and recipes<br/>across all countries.</p>",
  },
  {
    id: 3,
    icon: <ListItemIcon />,
    value: 'aboutUsItem-3',
    text: "<p>Inspire people on the other side of the globe<br/>and share your cooking experience.</p>",
  },
  {
    id: 4,
    icon: <ListItemIcon />,
    value: 'aboutUsItem-4',
    text: "<p>Last but not least, make money from it.</p>",
  },
];
