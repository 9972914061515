export const PlusCircle = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill={color ? color : "none"}>
        <circle cx="24" cy="24" r="18" stroke="black" strokeWidth="1.5" />
        <circle cx="24" cy="24" r="18" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <circle cx="24" cy="24" r="18" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <circle cx="24" cy="24" r="18" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <circle cx="24" cy="24" r="18" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <path d="M24 30L24 18" stroke="#222222" strokeWidth="1.5" strokeLinecap="square" />
        <path d="M30 24L18 24" stroke="black" strokeWidth="1.5" strokeLinecap="square" />
        <path d="M30 24L18 24" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="square" />
        <path d="M30 24L18 24" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="square" />
        <path d="M30 24L18 24" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="square" />
        <path d="M30 24L18 24" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" strokeLinecap="square" />
    </svg>
)