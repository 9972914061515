import StripImage from "assets/images/strip/strip.png";
/*
interface StripProps {
  alt: string;
  className: string;
}*/

const Strip = ({ alt, className }/*: StripProps*/) => {
  return <img src={StripImage} alt={alt} className={className} />;
};

export default Strip;
