
export const responsibilityItems = [
  {
    id: 1,
    className: "item-1",
    value: 'responsibility-1',
    text: "<p>We clean nature</p>",
  },
  {
    id: 2,
    className: "item-2",
    value: 'responsibility-2',
    text: "<p>we fight against <br/> food waste</p>",
  },
  {
    id: 3,
    className: "item-3",
    value: 'responsibility-3',
    text: `<p>we reduce the carbon <br/> footprint created by <br/> individual purchases</p>`,
  },
];
