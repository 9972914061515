import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

import {
    UPDATE_PROFILE,
} from 'queries';

import { REST_URL } from 'configs/restAPI';

import {
    useGetMyData,
} from 'utils';
import Input from 'components/Input';
import {
    GET_LANGUAGES,
    LANGUAGES_SUBSCRIPTION,
} from 'queries';
import { Spinner } from 'reactstrap';
import ChangePhoneNumber from './changePhoneNumber';
import ChangePassword from './changePassword';
import ChangeEmail from './changeEmail';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Settings(props) {
    const { toggleSettings } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const currentChef = useGetMyData();

    const [updateProfile] = useMutation(UPDATE_PROFILE);

    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [nickname, setNickname] = useState("");
    const [title, setTitle] = useState("");
    const [location, setLocation] = useState("");
    const [language, setLanguage] = useState(null);
    const [youtube, setYoutube] = useState("");
    const [instagram, setInstagram] = useState("");
    const [description, setDescription] = useState("");

    const [tempImage, setTempImage] = useState(null);
    const [imageSizeTooBig, setImageSizeTooBig] =
        useState(false);

    const [mediaLoading, setMediaLoading] = useState(false);

    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [error, setError] = useState(null);

    const {
        data: languagesData,
        loading: languagesLoading,
        refetch: languagesRefetch,
    } = useQuery(GET_LANGUAGES, {
        fetchPolicy: 'network-only',
    });

    useSubscription(LANGUAGES_SUBSCRIPTION, {
        onData: () => {
            languagesRefetch();
        },
    });

    const languageOptions = useMemo(() => {
        return languagesData && languagesData.languages ? languagesData.languages.map((lang) => ({
            ...lang,
            value: lang.id,
            label: `${t(lang.languageCode)} (${lang.languageCode})`,
        })) : [];
    }, [languagesData, t])

    useEffect(() => {
        if (currentChef && languageOptions) {
            setName(currentChef.name);
            setSurname(currentChef.surname);
            setNickname(currentChef.nickname);
            setTitle(currentChef.title);
            setLocation(currentChef.location);
            setDescription(currentChef.description);
            setLanguage({
                value: currentChef.language.id,
                label: `${t(currentChef.language.languageCode)} (${currentChef.language.languageCode})`
            });
            setInstagram(currentChef.instagram);
            setYoutube(currentChef.youtube);
            setError(null);
        }
    }, [currentChef, languageOptions, t])


    const blobToBase64 = (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    async function handleFile(file) {
        if (!file) {
            setMediaLoading(false);
            return;
        }
        if (file.size < 5 * 1024 * 1024) {
            setImageSizeTooBig(false);
            let blob = await blobToBase64(file);
            setTempImage(blob);
            setMediaLoading(false);
        } else {
            setImageSizeTooBig(true);
            setMediaLoading(false);
        }
    }

    const updateChefFunc = () => {

        let variables = {
            languageId: language.value,
            name: name.length === 0 ? currentChef.name : name,
            surname: surname.length === 0 ? currentChef.surname : surname,
            nickname: nickname.length === 0 ? currentChef.nickname : nickname,
            title,
            location,
            description,
            instagram,
            youtube,
        };

        if (tempImage) {
            variables.icon = tempImage;
        }

        updateProfile({
            variables,
        }).then(() => {
            setError(null)
            setSaving(false);
            setSaved(true);
            navigate(`/`);
        }).catch((err) => {
            setError(err.message);
            setSaved(false);
        });
    };

    const deactivateAccount = () => {
        if (window.confirm(t('areYouSureDeactivateAccount'))) {

        }
    };

    if (languagesLoading || saving) {
        return (
            <div className='loading'>
                <Spinner />
            </div>
        )
    }

    return (
        <form className='settings'>
            <div>
                <div>

                    <Input
                        label={t('firstName')}
                        id="name"
                        type="text"
                        value={name}
                        setValue={setName}
                    />
                    <Input
                        label={t('surname')}
                        id="surname"
                        type="text"
                        value={surname}
                        setValue={setSurname}
                    />
                    <Input
                        label={t('nickname')}
                        id="nickname"
                        type="text"
                        value={nickname}
                        setValue={setNickname}
                    />
                    <Input
                        label={t('chefTitle')}
                        id="title"
                        type="text"
                        value={title}
                        setValue={setTitle}
                    />
                    <Input
                        label={t('description')}
                        id="description"
                        type="textarea"
                        value={description}
                        setValue={setDescription}
                    />
                    <Input
                        label={t('location')}
                        id="location"
                        type="text"
                        value={location}
                        setValue={setLocation}
                    />
                    <Input
                        label={t('recipeLanguage')}
                        id="language"
                        type="select"
                        options={languageOptions}
                        value={language}
                        setValue={setLanguage}
                    />
                    <Input
                        label={t('instagram')}
                        id="instagram"
                        type="text"
                        value={instagram}
                        setValue={setInstagram}
                    />
                    <Input
                        label={t('youtube')}
                        id="youtube"
                        type="text"
                        value={youtube}
                        setValue={setYoutube}
                    />
                    <ChangePassword />
                    <ChangeEmail />
                    <ChangePhoneNumber />

                    {
                        false &&
                        <button className='btn-link danger' onClick={deactivateAccount}>
                            {t('deactivateAccount')}
                        </button>
                    }
                    {
                        saved &&
                        <label>{t('changedSaved')}</label>
                    }
                    {
                        error &&
                        <label className='error'>{error}</label>
                    }
                </div>
                <div>
                    <div className="user-image">
                        <img
                            src={
                                tempImage
                                    ? tempImage
                                    : `${REST_URL}/${currentChef.iconPath}-300.png`
                            }
                            alt=""
                        />
                        <label
                            htmlFor={`upload-user-image`}
                        >{t('edit')}</label>
                        <label
                            className='hidden'
                            htmlFor={`upload-user-image`}
                        ></label>
                        <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            invalid={
                                imageSizeTooBig
                                    ? 'true'
                                    : 'false'
                            }
                            id={`upload-user-image`}
                            style={{ display: 'none' }}
                            onChange={(e) => {
                                let files = e.target.files;
                                setMediaLoading(true);
                                handleFile(files[0]);
                            }}
                        />
                        {mediaLoading && <Spinner />}
                    </div>
                </div>
            </div>


            <div className='button-row'>
                <button className='btn-solid' onClick={(e) => {
                    e.preventDefault();
                    setSaved(false);
                    toggleSettings()
                }}>
                    {t('discardChanges')}
                </button>
                <button className='btn-solid' onClick={(e) => {
                    e.preventDefault()
                    updateChefFunc()
                }}>
                    {t('saveChanges')}
                </button>
            </div>

        </form>
    );
}
