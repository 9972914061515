import React, { useMemo } from 'react';
import { REST_URL } from 'configs/restAPI';
import { recipeHasVideo, useGetMyData, getLabel } from 'utils';
import RecipeBtn from './form';
import { Images, Video } from 'svg';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

export const listOptions = Object.freeze({
    grid: 'grid',
    list: 'list',
});

export default function Grid(props) {
    const { recipes, setMediaUploading, uploadImages, uploadVideo } = props;

    const { t } = useTranslation();
    const currentChef = useGetMyData();

    const getTitleImage = (images) => {
        const sortedImages = [...images].sort((i1, i2) => i1.order > i2.order ? -1 : 1);
        if (sortedImages.length > 0) {
            return `${REST_URL}/${sortedImages[0].path}-300.png?=${Math.floor(Math.random() * 10000)}`;
        }
        return null;
    }

    const mappedRecipes = useMemo(() => {
        if (recipes) {
            return [...recipes].sort((r1, r2) => r1.updatedAt < r2.updatedAt ? 1 : -1).map((recipe) => {
                const alt = getLabel(recipe.recipeTranslations, currentChef.language.id, `${t('noTitle')} (${currentChef.language.languageCode})`);
                return ({
                    ...recipe,
                    hasVideo: recipeHasVideo(recipe.recipeTranslations, currentChef.language.id),
                    titleImage: getTitleImage(recipe.images),
                    isConcept: recipe.progress < 6,
                    alt: alt ? alt : recipe.id
                })
            })
        }
        return [];
    }, [currentChef.language.id, currentChef.language.languageCode, recipes, t])

    const getIcon = (video) => {
        if (video) {
            return (
                <div>
                    <Video />
                </div>
            )
        }
        return (
            <div>
                <Images />
            </div>
        )
    }

    const getRecipeItem = (recipe) => (
        <div
            className='recipe-item'
            key={recipe.id}
            onClick={(e) => {
                e.preventDefault()
            }}
        >
            {
                !recipe.titleImage &&
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                    <Spinner />
                </div>
            }
            {
                recipe.titleImage &&
                <img src={recipe.titleImage} alt={`recipe-${recipe.alt}`} />
            }

            <RecipeBtn recipeId={recipe.id} stageId={recipe.progress} fullSizeBtn={true} setMediaUploading={setMediaUploading} uploadVideo={uploadVideo} uploadImages={uploadImages} />
            <div className='info-row'>
                {
                    recipe.isConcept &&
                    <label>{t('draft')}</label>
                }
                {
                    getIcon(recipe.hasVideo)
                }
            </div>
        </div>
    )

    return (
        <div className='recipes-grid'>
            {
                mappedRecipes.map(getRecipeItem)
            }
        </div>
    );
}
