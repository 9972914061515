import { baseStages, extraStages, difficultyOptions } from "constants";
import { getItemFromTranslation } from "./translations";

export const getStageLabel = (stage, t) => {
    switch (stage) {
        case 0:
        case baseStages.media:
            return t("stage-media"); //"Koncept 1/6: Médiá";
        case 1:
        case baseStages.recipe:
            return t("stage-recipe"); //"Koncept 2/6: Recept";
        case 2:
        case baseStages.extra:
            return t("stage-extra"); //"Koncept 3/6: Doplňujúce info";
        case 3:
        case baseStages.preview:
            return t("stage-preview"); //"Koncept 4/6: Náhľad";
        case 4:
        case extraStages.translation:
            return t("stage-translation"); //"Prekladá sa";
        case 5:
        case extraStages.published:
            return t("stage-published"); //"Publikovaný";
        default:
            return t('stage-unknown'); //"Koncept ?/6";
    }
}


export const getStage = (stage) => {
    switch (stage) {
        case 0:
            return baseStages.media;
        case 1:
            return baseStages.recipe;
        case 2:
            return baseStages.extra;
        case 3:
            return baseStages.preview;
        case 4:
            return extraStages.translation;
        case 5:
            return extraStages.published;

        default:
            return null;
    }
}

export const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
        case 0:
        case 1:
        case 2:
            return difficultyOptions[difficulty].label;
        default:
            return "invalidDifficulty";
    }
}

export const recipeHasVideo = (translations, languageId) => {
    const video = getItemFromTranslation(translations, languageId, "video");
    return !!video;
}
