import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    CONFIRM_EMAIL,
    RESEND_POTENTIAL_CHEF_VERIFICATION_EMAIL
} from 'queries';
import { useParams } from 'react-router-dom';
import { isEmail } from 'utils';
import Input from 'components/Input';
import { useTranslation } from 'react-i18next';

export default function EmailVerification() {
    const { confirmationData } = useParams();
    const { t } = useTranslation();

    const [confirmEmail] = useMutation(
        CONFIRM_EMAIL
    );
    const [resendCode] = useMutation(
        RESEND_POTENTIAL_CHEF_VERIFICATION_EMAIL
    );

    const [confirmationCode, setConfirmationCode] = useState('');
    const [email, setEmail] = useState('');
    const [showResendOption, setShowResendOption] = useState(false);
    const [error, setError] = React.useState(null);
    const [message, setMessage] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    useEffect(() => {
        setConfirmationCode(confirmationData);
    }, [confirmationData]);

    const confirmEmailFunc = () => {
        confirmEmail({
            variables: {
                confirmationCode
            },
        })
            .then(() => {
                setSuccess(true)
            })
            .catch((err) => {
                setError(err.message)
            });
    };

    const resendCodeFunc = () => {
        resendCode({
            email,
        })
            .then(() => {
                setMessage(t("codeResent"))
            })
            .catch((err) => {
                setError(err.message)
            });
    };

    return (
        <div className='login-container'>
            <h1>
                {t('emailVerification')}
            </h1>
            <form>
                <Input
                    id={"confirmationCode"}
                    label={t('confirmationCode')}
                    type="text"
                    placeholder=""
                    value={confirmationCode}
                    setValue={setConfirmationCode}
                    onKeyUp={() => {
                        if (confirmationCode.length > 0) {
                            confirmEmailFunc()
                        }
                    }}
                />

                <button className='btn-link' onClick={(e) => {
                    e.preventDefault();
                    setShowResendOption(true);
                }}>{t('clickHereToResendCode')}</button>
                {
                    showResendOption &&
                    <Input
                        id={"email"}
                        label={t('email')}
                        type="text"
                        placeholder=""
                        value={email}
                        setValue={setEmail}
                    />
                }
                {
                    isEmail(email) &&
                    <button
                        className='btn-link'
                        onClick={(e) => {
                            e.preventDefault()
                            resendCodeFunc()
                        }}
                    >
                        {t('forgottenPassword')}
                    </button>
                }
                {message && <p className='btn-label'>{message}</p>}
                {error && <p className='error'>{error}</p>}
                {
                    success &&
                    <p className='btn-label'>
                        {t('emailVerified')}

                    </p>
                }
                {
                    !success &&
                    <button
                        className='btn-outline'
                        onClick={(e) => {
                            e.preventDefault()
                            confirmEmailFunc();
                        }}
                    >
                        {t('verifyEmail')}
                    </button>
                }
            </form>
        </div>
    );
}
