import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
    Container,
    CardHeader,
    CardBody,
    FormGroup,
    InputGroup,
    Label,
    Input,
    Form,
    Button,
    Card,
    Row,
    Col,
} from 'reactstrap';
import { CHEF_CHANGE_PASSWORD } from 'queries';
import { MaterialSymbol } from 'react-material-symbols';
import { useParams } from 'react-router-dom';
import { useGetMyData } from 'utils';
import { useTranslation } from 'react-i18next';

export default function ChangePassword() {
    const { confirmationData } = useParams();
    const { t } = useTranslation();

    const currentChef = useGetMyData();

    const [chefChangePassword] = useMutation(
        CHEF_CHANGE_PASSWORD
    );

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] =
        useState(false);

    const [newPassword2, setNewPassword2] = useState('');
    const [newPassword2Visible, setNewPassword2Visible] =
        useState(false);

    const [passwordErrors, setPasswordErrors] = useState(
        []
    );

    const [saveError, setSaveError] = useState(null);
    const [passChanged, setPassChanged] = useState(false);

    const checkPasswords = (calledFromFirstPassword) => {
        if (
            newPassword.length + newPassword2.length ===
            0
        ) {
            return;
        }
        let newErrors = [];
        if (
            (!calledFromFirstPassword &&
                newPassword !== newPassword2) ||
            (newPassword !== newPassword2 &&
                newPassword2.length > 0)
        ) {
            newErrors.push(t('differentPassowrds'));
        }
        if (newPassword.length < 6) {
            newErrors.push(
                t('passwordMinSixChar')

            );
        }
        setPasswordErrors(newErrors);
    };

    const chefChangePasswordFunc = () => {
        const separatorIndex =
            confirmationData.indexOf('|');

        const email = confirmationData.substring(
            0,
            separatorIndex
        );
        const confirmationCode = confirmationData.substring(
            separatorIndex + 1
        );

        let variables = {
            newPass: newPassword,
            confirmationCode,
        };

        if (!currentChef) {
            variables = {
                ...variables,
                email,
            };
        }

        chefChangePassword({
            variables,
        })
            .then(() => {
                setPassChanged(true);
                setSaveError(null);
            })
            .catch((error) => {
                setSaveError(error.message);
            });
    };

    if (!confirmationData && !currentChef) {
        return <div>{t('incorrectQuery')}</div>;
    }

    return (
        <Container>
            <Row>
                <Col
                    className=""
                    md={{
                        offset: 3,
                        size: 6,
                    }}
                    sm="12"
                >
                    <Card className="my-2">
                        <CardHeader>{t('passwordChange')}</CardHeader>
                        <CardBody>
                            <Form>
                                <FormGroup>
                                    <Label htmlFor="user-new-pass">
                                        {t('newPassword')}
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="user-new-pass"
                                            invalid={
                                                passwordErrors.length >
                                                0
                                            }
                                            value={
                                                newPassword
                                            }
                                            type={
                                                newPasswordVisible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewPassword(
                                                    e.target
                                                        .value
                                                );
                                                setSaveError(
                                                    null
                                                );

                                                setPassChanged(
                                                    false
                                                );
                                            }}
                                            onBlur={() =>
                                                checkPasswords(
                                                    true
                                                )
                                            }
                                        />
                                        <Button
                                            color="primary"
                                            className="flex"
                                            onClick={() => {
                                                setNewPasswordVisible(
                                                    !newPasswordVisible
                                                );
                                            }}
                                        >
                                            <MaterialSymbol
                                                icon={
                                                    newPasswordVisible
                                                        ? 'visibility_off'
                                                        : 'visibility'
                                                }
                                                size={24}
                                                fill
                                                grade={-25}
                                                color="white"
                                            />
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label htmlFor="user-new-pass-2">
                                        {t('repeatPassword')}
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="user-new-pass-2"
                                            value={
                                                newPassword2
                                            }
                                            invalid={
                                                passwordErrors.length >
                                                0
                                            }
                                            type={
                                                newPassword2Visible
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            onChange={(
                                                e
                                            ) => {
                                                setNewPassword2(
                                                    e.target
                                                        .value
                                                );
                                                setSaveError(
                                                    null
                                                );
                                                setPassChanged(
                                                    false
                                                );
                                            }}
                                            onBlur={() =>
                                                checkPasswords()
                                            }
                                        />
                                        <Button
                                            color="primary"
                                            className="flex"
                                            onClick={() => {
                                                setNewPassword2Visible(
                                                    !newPassword2Visible
                                                );
                                            }}
                                        >
                                            <MaterialSymbol
                                                icon={
                                                    newPassword2Visible
                                                        ? 'visibility_off'
                                                        : 'visibility'
                                                }
                                                size={24}
                                                fill
                                                grade={-25}
                                                color="white"
                                            />
                                        </Button>
                                    </InputGroup>
                                </FormGroup>

                                {passwordErrors.map(
                                    (error, index) => (
                                        <p key={index}>
                                            {error}
                                        </p>
                                    )
                                )}
                            </Form>
                            {saveError && (
                                <Row>
                                    <Col>
                                        <p>{saveError}</p>
                                    </Col>
                                </Row>
                            )}

                            {passChanged && (
                                <Row>
                                    <Col>
                                        <p>
                                            {t('passwordChanged')}

                                        </p>
                                    </Col>
                                </Row>
                            )}

                            <Row xs="12">
                                <Col>
                                    <Button
                                        color="primary"
                                        block
                                        disabled={
                                            passwordErrors.length >
                                            0 ||
                                            passChanged
                                        }
                                        onClick={() => {
                                            chefChangePasswordFunc();
                                        }}
                                    >
                                        {t('save')}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}
