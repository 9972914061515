import React, { useState } from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import { useQuery } from '@apollo/client';
import {
    GET_TESTED_TOKEN,
} from 'apollo/queries';
import { setIsLoggedIn, setTestedToken } from 'apollo/localSchema/actions';
import refreshToken from 'apollo/refreshToken';

import { Spinner } from 'reactstrap';
import Redirect from 'redirect';
import ChangePassword from 'pages/intro/forgotten-password';
import Login from 'pages/intro/login';
import EmailVerification from 'pages/intro/email-verification';

//import MainPage from 'pages/intro/main-page';
import Landing from 'pages/intro/landing';
import ContactForm from 'pages/intro/contact-form';

export default function PublicNavigation() {

    const { data: testedTokenData, loading: testedTokenLoading } = useQuery(GET_TESTED_TOKEN);

    const [signingIn, setSigningIn] = useState(false);

    React.useEffect(() => {
        if (!testedTokenData.testedToken) {
            setTestedToken(true);
            tryLogin();
        }
    }, [testedTokenLoading, testedTokenData.testedToken]);

    const tryLogin = () => {
        setSigningIn(true);
        refreshToken()
            .then((response) => {
                const { ok, accessToken } = response.data;
                if (ok) {
                    sessionStorage.setItem('acctok', accessToken);
                    setIsLoggedIn(true);
                    setSigningIn(false);
                } else {
                    sessionStorage.removeItem('acctok');
                    setIsLoggedIn(false);
                    setSigningIn(false);
                }
            })
            .catch(() => {
                setIsLoggedIn(false);
                setSigningIn(false);
            });
    };

    if (signingIn) {
        return (
            <div className='login-container m-t-120'>
                <Spinner />
            </div>
        )
            ;
    }

    //todo - ak je link na overenie kodu neprihlasuj
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/contact-us" element={<ContactForm />} />
                <Route path="/login" element={<Login />} />

                <Route
                    path="/password_reset/:confirmationData"
                    element={<ChangePassword />}
                />
                <Route
                    path="/registration_chef_confirm/:confirmationData"
                    element={<EmailVerification />}
                />

                <Route path="/*" element={<Redirect />} />
            </Routes>
        </BrowserRouter>
    );
}
