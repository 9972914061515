import React, { useMemo } from 'react';
import { useGetMyData } from 'utils';
import { getLabel, getStageLabel, getDifficultyLabel } from 'utils';
import RecipeBtn from './form';
import { CheckMarkCircle, TrashCircle, XMarkCircle } from 'svg';
import { useMutation } from '@apollo/client';
import { DELETE_RECIPE } from 'queries';
import { useTranslation } from 'react-i18next';

export const listOptions = Object.freeze({
    grid: 'grid',
    list: 'list',
});

export default function List(props) {
    const { recipes, setMediaUploading, uploadImages, uploadVideo } = props;
    const currentChef = useGetMyData();
    const { t } = useTranslation();

    const [deleteRecipe] = useMutation(DELETE_RECIPE);

    const getDefRecipeLanguage = (recipeTranslations) => {
        const defaultLanguage = recipeTranslations.find((translation) => translation.def);
        if (defaultLanguage) {
            return defaultLanguage.language;
        }

        if (currentChef) {
            const recipeHasChefLanguage = recipeTranslations.any((translation) => translation.language.id === currentChef.language.id);
            if (recipeHasChefLanguage) {
                return currentChef.language;
            }
        }
        const backupTranslation = recipeTranslations[0];
        return backupTranslation ? backupTranslation.language : {};
    }

    const mappedRecipes = useMemo(() => {
        if (recipes) {
            return [...recipes].sort((r1, r2) => r1.updatedAt < r2.updatedAt ? 1 : -1).map((recipe) => {
                const recipeDefLanguage = getDefRecipeLanguage(recipe.recipeTranslations);
                return {
                    ...recipe,
                    label: getLabel(recipe.recipeTranslations, recipeDefLanguage.id, "title", `${t('noTitle')} (${recipeDefLanguage.languageCode})`),
                    stageLabel: getStageLabel(recipe.progress, t),
                    difficultyLabel: t(getDifficultyLabel(recipe.difficulty)),
                }
            })
        }
        return [];
    }, [currentChef.language.languageCode, currentChef.language.id, recipes, t])

    const onDeleteRecipe = (id) => {
        deleteRecipe({
            variables: {
                id
            }
        }).then(() => {
        }).catch((err) => {
            console.log(err.message);
        })
    }

    const getVisibility = (visible, inProgress) => {
        if (inProgress) {
            return;
        }
        if (visible) {
            return (
                <CheckMarkCircle />
            )
        }
        return (
            <XMarkCircle />
        )
    }

    const getRecipeRow = (recipe) => (
        <tr key={recipe.id}>
            <td width="15%">{getVisibility(recipe.visible, recipe.inProgress)}</td>
            <td width="">{recipe.label}</td>
            <td width="30%">{recipe.stageLabel}</td>
            <td width="25%">{recipe.difficultyLabel}</td>
            <td>
                <RecipeBtn recipeId={recipe.id} stageId={recipe.progress} setMediaUploading={setMediaUploading} uploadVideo={uploadVideo} uploadImages={uploadImages} />
                <button
                    className='carousel-btn'
                    style={{ paddingLeft: "5px" }}
                    onClick={() => {
                        if (window.confirm(t('areYouSureDeleteRecipe'))) {
                            onDeleteRecipe(recipe.id)
                        }
                    }}
                >
                    <TrashCircle />
                </button>
            </td>
        </tr>
    )

    return (
        <table>
            <thead>
                <tr>
                    <th>{t('visible')}</th>
                    <th>{t('myRecipes')}</th>
                    <th>{t('state')}</th>
                    <th>{t('difficulty')}</th>
                    <th>{t('actions')}</th>
                </tr>
            </thead>
            <tbody>
                {
                    mappedRecipes.map(getRecipeRow)
                }
            </tbody>
        </table>
    );
}
