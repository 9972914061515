import React from 'react';
import { useTranslation } from 'react-i18next';
import { XMark } from 'svg/x-mark';
import { getStageLabel } from 'utils';

export default function RecipeHeader(props) {
    const { stage, closeModal, showLabel = true } =
        props;

    const { t } = useTranslation();

    return (
        <div className="recipe-header">
            {
                showLabel &&
                <h1>
                    {getStageLabel(stage, t)}
                </h1>
            }
            <button className='carousel-btn' onClick={(e) => {
                e.preventDefault();
                closeModal();
            }}>
                <XMark />
            </button>
        </div>
    );
}
