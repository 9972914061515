import React from "react";
import StarSectionLeft from "./StarSectionLeft";
import StarSectionRight from "./StarSectionRight";
import StarSectionBottom from "./StarSectionBottom";
const StarSectionWrapper = () => {
  return (
    <div className="star-section-wrapper">
      <div className="star-section-row">
        <StarSectionLeft />
        <StarSectionRight />
      </div>
      <StarSectionBottom />
    </div>
  );
};

export default StarSectionWrapper;
