import { gql } from '@apollo/client';

export const CHEF_DATA_SUBSCRIPTION = gql`
    subscription chefDataSubscription {
        chefDataSubscription
    }
`;

export const UPDATE_PROFILE = gql`
    mutation chefUpdateProfile(
        $name: String, 
        $surname: String, 
        $title: String, 
        $youtube: String, 
        $instagram: String, 
        $nickname: String, 
        $description: String, 
        $location: String, 
        $icon: String, 
        $languageId: Int
    ) {
        chefUpdateProfile(
            name: $name, 
            surname: $surname, 
            title: $title, 
            youtube: $youtube, 
            instagram: $instagram, 
            nickname: $nickname, 
            description: $description, 
            location: $location, 
            icon: $icon, 
            languageId: $languageId
        ) {
            id
            fullName  
            name
            surname          
            iconPath
            title
            description
            location
            nickname
            youtube
            instagram
            language{
                id
                languageCode
            }
        }
    }
`;

export const CHEF_CHANGE_EMAIL = gql`
    mutation chefChangeEmail(
        $email: String!
        $confirmationCode: String
    ) {
        chefChangeEmail(
            email: $email
            confirmationCode: $confirmationCode
        ) {
          accessToken
        }
    }
`;

export const CHEF_CHANGE_PASSWORD = gql`
mutation chefChangePassword(
        $newPass: String!
        $confirmationCode: String
        $email: String
    ) {
        chefChangePassword(
            newPass: $newPass
            confirmationCode: $confirmationCode
            email: $email
        ) {
          accessToken
        }
    }
`;

export const CHEF_CHANGE_PHONE_NUMBER = gql`
    mutation chefChangePhoneNumber(
        $newPhoneNumber: String!
        $confirmationCode: String
    ) {
        chefChangePhoneNumber(
            newPhoneNumber: $newPhoneNumber
            confirmationCode: $confirmationCode
        ) {
          id
        }
    }
`;

export const CHEF_RESET_PASSWORD = gql`
    mutation chefResetPassword($email: String!) {
        chefResetPassword(email: $email)
    }
`;

export const CHANGE_CHEF_ACTIVE = gql`
    mutation changeChefActive($id: Int) {
        changeChefActive(id: $id) {
            id
        }
    }
`;
