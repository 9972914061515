import ListItemIcon from "../svg/ListItemIcon";

export const businessItems = [
  {
    id: 1,
    icon: <ListItemIcon />,
    value: 'growYourBusiness-1',
    text: "<p>increase in followers and value of IG posts<br/> x-fold</p>",
  },
  {
    id: 2,
    icon: <ListItemIcon />,
    value: 'growYourBusiness-2',
    text: "<p>earn from ads</p>",
  },
  {
    id: 3,
    icon: <ListItemIcon />,
    value: 'growYourBusiness-3',
    text: "<p>earn money from your recipes, for B2C, but <br/> also B2B</p>",
  },
  {
    id: 4,
    icon: <ListItemIcon />,
    value: 'growYourBusiness-4',
    text: "<p>be a partner, mentor, consultant,<br/> supervisor for gastro concepts<br/> (restaurants, canteens, street food, bistros,<br/> catering)</p>",
  },
];
