import parse from "html-react-parser";
import { Trans } from "react-i18next";
import Img from "assets/images/responsibility/responsibility_3.png";

/*
interface ResponsibilityItemProps {
  className: string;
  text: string;
}*/

const ResponsibiltyItem = ({ className, value }/*: ResponsibilityItemProps*/) => {
  return (
    <div className={`responsibility-item ${className}`}>
      <Trans i18nKey={value} />
      {
        value === 'responsibility-3' &&
        <div className="img-wrapper"><img src={Img} alt="cart" /></div>
      }
    </div>
  );
};

export default ResponsibiltyItem;
