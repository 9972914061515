import SwiperItemText from "pages/intro/landing/components/swiperSection/SwiperItemText";

export const swiperItems = [
  {
    id: 1,
    className: "swiper-item-1",
    content: (
      <>
        <SwiperItemText className="swiper-text-1">
          <p>Quis aute iure repreh enderit in voluptate velit esse cillum.</p>
        </SwiperItemText>
        <SwiperItemText className="swiper-text-2">
          <p>Ecepteur sint obcaecat cupiditat non proident.</p>
          <div>Mark, 38</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 2,
    className: "swiper-item-2",
    content: (
      <>
        <SwiperItemText className="swiper-text-3">
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquid ex ea commodi.
          </p>
          <div>Marína, 26</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 3,
    className: "swiper-item-3",
    content: (
      <>
        <SwiperItemText className="swiper-text-4">
          <p>
            Nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi!
          </p>
          <div>Mark, 40</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 4,
    className: "swiper-item-4",
    content: (
      <>
        <SwiperItemText className="swiper-text-5">
          <p>
            Suis nostrud exercitation ullamco laboris nisi ut aliquid ex ea
            commodi.
          </p>
          <div>Marry, 43</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 5,
    className: "swiper-item-1",
    content: (
      <>
        <SwiperItemText className="swiper-text-1">
          <p>Quis aute iure repreh enderit in voluptate velit esse cillum.</p>
        </SwiperItemText>
        <SwiperItemText className="swiper-text-2">
          <p>Ecepteur sint obcaecat cupiditat non proident.</p>
          <div>Mark, 38</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 6,
    className: "swiper-item-2",
    content: (
      <>
        <SwiperItemText className="swiper-text-3">
          <p>
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquid ex ea commodi.
          </p>
          <div>Marína, 26</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 7,
    className: "swiper-item-3",
    content: (
      <>
        <SwiperItemText className="swiper-text-4">
          <p>
            Nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi!
          </p>
          <div>Mark, 40</div>
        </SwiperItemText>
      </>
    ),
  },
  {
    id: 8,
    className: "swiper-item-4",
    content: (
      <>
        <SwiperItemText className="swiper-text-5">
          <p>
            Suis nostrud exercitation ullamco laboris nisi ut aliquid ex ea
            commodi.
          </p>
          <div>Marry, 43</div>
        </SwiperItemText>
      </>
    ),
  },
];
