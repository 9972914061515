import React from "react";
import { businessItems } from 'configs/businessItems'; //"../../config/businessItems";
import List from "../helpers/List";
import { Trans, useTranslation } from "react-i18next";

const BusinessRight = () => {
  const { t } = useTranslation();
  return (
    <div className="business-right">
      <div className="business-right-wrapper">
        <h2>{t('growYourBusiness')}</h2>
        <p>
          <Trans
            i18nKey="successDeservesAReward"
          />
        </p>
        <List list={businessItems} className="business-section-list" />
      </div>
    </div>
  );
};

export default BusinessRight;
