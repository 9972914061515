import React, { useState } from 'react';
import Profile from './view';
import Recipes from './recipes';

export default function ProfileContainer() {

    return (
        <div className='profile-container'>
            <Profile />
            <Recipes />
        </div>
    );
}
