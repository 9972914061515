export const priceRange = Object.freeze([
    Object.freeze({
        value: 0,
        label: "affordable"
    }),
    Object.freeze({
        value: 1,
        label: "normal"
    }),
    Object.freeze({
        value: 2,
        label: "expensive"
    }),
]);