import { whyUsItems } from "configs/whyUsItems";
import WhyUsItem from "./WhyUsItem";
import WhyUsStrip from "assets/images/why_us/why_us_strip.png";

const WhyUsList = () => {
  return (
    <>
      <div className="why-us-list">
        {whyUsItems.map(({ id, icon, value }) => (
          <WhyUsItem key={id} icon={icon} value={value} />
        ))}
        <img src={WhyUsStrip} className="strip-1" alt="strip" />
        <img src={WhyUsStrip} className="strip-2" alt="strip" />
        <img src={WhyUsStrip} className="strip-3" alt="strip" />
      </div>
    </>
  );
};

export default WhyUsList;
