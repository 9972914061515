interface SmilingEmojiIconProps {
  width: number;
  height: number;
}

const SmilingEmojiIcon = ({ width, height }: SmilingEmojiIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <circle opacity="0.5" cx="50" cy="50" r="50" fill="white" />
      <path
        d="M49.75 80.5C66.7328 80.5 80.5 66.7328 80.5 49.75C80.5 32.7673 66.7328 19 49.75 19C32.7672 19 19 32.7673 19 49.75C19 66.7328 32.7672 80.5 49.75 80.5Z"
        stroke="#373E44"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5552 45.0499C38.4906 45.0499 40.8702 42.6703 40.8702 39.7349C40.8702 36.7995 38.4906 34.4199 35.5552 34.4199C32.6198 34.4199 30.2402 36.7995 30.2402 39.7349C30.2402 42.6703 32.6198 45.0499 35.5552 45.0499Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.255 39.7349C69.255 42.6699 66.875 45.0499 63.94 45.0499C61.005 45.0499 58.625 42.6699 58.625 39.7349C58.625 36.7999 61.005 34.4199 63.94 34.4199C66.875 34.4199 69.255 36.7999 69.255 39.7349Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.8594 58.1553C31.8594 64.8103 39.8694 70.2003 49.7494 70.2003C59.6294 70.2003 67.6394 64.8053 67.6394 58.1553H31.8594Z"
        stroke="#373E44"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SmilingEmojiIcon;
