export const MarkerCircle = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
        <path d="M22.1257 36.7265C24.4535 36.3161 26.6778 35.4511 28.6714 34.181C30.665 32.911 32.3889 31.2607 33.7447 29.3244C35.1006 27.3881 36.0617 25.2037 36.5733 22.8959C37.0849 20.5881 37.137 18.2022 36.7265 15.8743C36.3161 13.5464 35.4511 11.3222 34.181 9.32861C32.911 7.335 31.2607 5.61108 29.3244 4.25526C27.3881 2.89945 25.2037 1.93829 22.8959 1.42667C20.5881 0.915053 18.2022 0.862992 15.8743 1.27346C13.5464 1.68393 11.3222 2.54889 9.3286 3.81896C7.335 5.08902 5.61108 6.73932 4.25526 8.67563C2.89945 10.6119 1.93829 12.7963 1.42667 15.1041C0.915053 17.4119 0.862992 19.7978 1.27346 22.1257C1.68393 24.4536 2.54889 26.6778 3.81896 28.6714C5.08902 30.665 6.73932 32.3889 8.67563 33.7447C10.6119 35.1006 12.7963 36.0617 15.1041 36.5733C17.4119 37.0849 19.7978 37.137 22.1257 36.7265L22.1257 36.7265Z" stroke="black" strokeWidth="1.5" />
        <path d="M22.1257 36.7265C24.4535 36.3161 26.6778 35.4511 28.6714 34.181C30.665 32.911 32.3889 31.2607 33.7447 29.3244C35.1006 27.3881 36.0617 25.2037 36.5733 22.8959C37.0849 20.5881 37.137 18.2022 36.7265 15.8743C36.3161 13.5464 35.4511 11.3222 34.181 9.32861C32.911 7.335 31.2607 5.61108 29.3244 4.25526C27.3881 2.89945 25.2037 1.93829 22.8959 1.42667C20.5881 0.915053 18.2022 0.862992 15.8743 1.27346C13.5464 1.68393 11.3222 2.54889 9.3286 3.81896C7.335 5.08902 5.61108 6.73932 4.25526 8.67563C2.89945 10.6119 1.93829 12.7963 1.42667 15.1041C0.915053 17.4119 0.862992 19.7978 1.27346 22.1257C1.68393 24.4536 2.54889 26.6778 3.81896 28.6714C5.08902 30.665 6.73932 32.3889 8.67563 33.7447C10.6119 35.1006 12.7963 36.0617 15.1041 36.5733C17.4119 37.0849 19.7978 37.137 22.1257 36.7265L22.1257 36.7265Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <path d="M22.1257 36.7265C24.4535 36.3161 26.6778 35.4511 28.6714 34.181C30.665 32.911 32.3889 31.2607 33.7447 29.3244C35.1006 27.3881 36.0617 25.2037 36.5733 22.8959C37.0849 20.5881 37.137 18.2022 36.7265 15.8743C36.3161 13.5464 35.4511 11.3222 34.181 9.32861C32.911 7.335 31.2607 5.61108 29.3244 4.25526C27.3881 2.89945 25.2037 1.93829 22.8959 1.42667C20.5881 0.915053 18.2022 0.862992 15.8743 1.27346C13.5464 1.68393 11.3222 2.54889 9.3286 3.81896C7.335 5.08902 5.61108 6.73932 4.25526 8.67563C2.89945 10.6119 1.93829 12.7963 1.42667 15.1041C0.915053 17.4119 0.862992 19.7978 1.27346 22.1257C1.68393 24.4536 2.54889 26.6778 3.81896 28.6714C5.08902 30.665 6.73932 32.3889 8.67563 33.7447C10.6119 35.1006 12.7963 36.0617 15.1041 36.5733C17.4119 37.0849 19.7978 37.137 22.1257 36.7265L22.1257 36.7265Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <path d="M22.1257 36.7265C24.4535 36.3161 26.6778 35.4511 28.6714 34.181C30.665 32.911 32.3889 31.2607 33.7447 29.3244C35.1006 27.3881 36.0617 25.2037 36.5733 22.8959C37.0849 20.5881 37.137 18.2022 36.7265 15.8743C36.3161 13.5464 35.4511 11.3222 34.181 9.32861C32.911 7.335 31.2607 5.61108 29.3244 4.25526C27.3881 2.89945 25.2037 1.93829 22.8959 1.42667C20.5881 0.915053 18.2022 0.862992 15.8743 1.27346C13.5464 1.68393 11.3222 2.54889 9.3286 3.81896C7.335 5.08902 5.61108 6.73932 4.25526 8.67563C2.89945 10.6119 1.93829 12.7963 1.42667 15.1041C0.915053 17.4119 0.862992 19.7978 1.27346 22.1257C1.68393 24.4536 2.54889 26.6778 3.81896 28.6714C5.08902 30.665 6.73932 32.3889 8.67563 33.7447C10.6119 35.1006 12.7963 36.0617 15.1041 36.5733C17.4119 37.0849 19.7978 37.137 22.1257 36.7265L22.1257 36.7265Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <path d="M22.1257 36.7265C24.4535 36.3161 26.6778 35.4511 28.6714 34.181C30.665 32.911 32.3889 31.2607 33.7447 29.3244C35.1006 27.3881 36.0617 25.2037 36.5733 22.8959C37.0849 20.5881 37.137 18.2022 36.7265 15.8743C36.3161 13.5464 35.4511 11.3222 34.181 9.32861C32.911 7.335 31.2607 5.61108 29.3244 4.25526C27.3881 2.89945 25.2037 1.93829 22.8959 1.42667C20.5881 0.915053 18.2022 0.862992 15.8743 1.27346C13.5464 1.68393 11.3222 2.54889 9.3286 3.81896C7.335 5.08902 5.61108 6.73932 4.25526 8.67563C2.89945 10.6119 1.93829 12.7963 1.42667 15.1041C0.915053 17.4119 0.862992 19.7978 1.27346 22.1257C1.68393 24.4536 2.54889 26.6778 3.81896 28.6714C5.08902 30.665 6.73932 32.3889 8.67563 33.7447C10.6119 35.1006 12.7963 36.0617 15.1041 36.5733C17.4119 37.0849 19.7978 37.137 22.1257 36.7265L22.1257 36.7265Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="-10 -9 38 38" fill="none">
            <path d="M11.1666 2.43955L2.9725 10.6336C2.95993 10.6462 2.94726 10.6588 2.93451 10.6715C2.77825 10.8271 2.61024 10.9945 2.49123 11.2047L3.13421 11.5687L2.49122 11.2047C2.37221 11.4149 2.31515 11.6451 2.26209 11.8591C2.25776 11.8766 2.25346 11.894 2.24915 11.9112L1.47451 15.0098C1.47189 15.0202 1.46921 15.0309 1.46649 15.0417C1.42867 15.1925 1.38229 15.3774 1.36665 15.5373C1.34897 15.718 1.34739 16.0745 1.63649 16.3636C1.92558 16.6527 2.28209 16.6511 2.4628 16.6335C2.62275 16.6178 2.80761 16.5714 2.9584 16.5336C2.96923 16.5309 2.97989 16.5282 2.99035 16.5256L6.08892 15.751C6.10615 15.7467 6.12351 15.7424 6.14098 15.738C6.35504 15.685 6.58522 15.6279 6.79542 15.5089C7.00562 15.3899 7.17297 15.2219 7.3286 15.0656C7.3413 15.0528 7.35393 15.0402 7.36649 15.0276L15.5606 6.83354L15.5922 6.8019C15.8983 6.49589 16.1789 6.21532 16.3765 5.95637C16.5941 5.67121 16.7802 5.32602 16.7802 4.889C16.7802 4.45197 16.5941 4.10678 16.3765 3.82162C16.1789 3.56268 15.8983 3.28211 15.5922 2.9761L15.5606 2.94445L15.0557 2.43955L15.024 2.4079C14.718 2.10182 14.4374 1.82118 14.1785 1.62361C13.8933 1.40604 13.5481 1.21988 13.1111 1.21988C12.6741 1.21988 12.3289 1.40604 12.0437 1.62361C11.7848 1.82118 11.5042 2.10182 11.1982 2.4079L11.1666 2.43955Z" stroke="black" strokeWidth="1.5" />
            <path d="M11.1666 2.43955L2.9725 10.6336C2.95993 10.6462 2.94726 10.6588 2.93451 10.6715C2.77825 10.8271 2.61024 10.9945 2.49123 11.2047L3.13421 11.5687L2.49122 11.2047C2.37221 11.4149 2.31515 11.6451 2.26209 11.8591C2.25776 11.8766 2.25346 11.894 2.24915 11.9112L1.47451 15.0098C1.47189 15.0202 1.46921 15.0309 1.46649 15.0417C1.42867 15.1925 1.38229 15.3774 1.36665 15.5373C1.34897 15.718 1.34739 16.0745 1.63649 16.3636C1.92558 16.6527 2.28209 16.6511 2.4628 16.6335C2.62275 16.6178 2.80761 16.5714 2.9584 16.5336C2.96923 16.5309 2.97989 16.5282 2.99035 16.5256L6.08892 15.751C6.10615 15.7467 6.12351 15.7424 6.14098 15.738C6.35504 15.685 6.58522 15.6279 6.79542 15.5089C7.00562 15.3899 7.17297 15.2219 7.3286 15.0656C7.3413 15.0528 7.35393 15.0402 7.36649 15.0276L15.5606 6.83354L15.5922 6.8019C15.8983 6.49589 16.1789 6.21532 16.3765 5.95637C16.5941 5.67121 16.7802 5.32602 16.7802 4.889C16.7802 4.45197 16.5941 4.10678 16.3765 3.82162C16.1789 3.56268 15.8983 3.28211 15.5922 2.9761L15.5606 2.94445L15.0557 2.43955L15.024 2.4079C14.718 2.10182 14.4374 1.82118 14.1785 1.62361C13.8933 1.40604 13.5481 1.21988 13.1111 1.21988C12.6741 1.21988 12.3289 1.40604 12.0437 1.62361C11.7848 1.82118 11.5042 2.10182 11.1982 2.4079L11.1666 2.43955Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
            <path d="M11.1666 2.43955L2.9725 10.6336C2.95993 10.6462 2.94726 10.6588 2.93451 10.6715C2.77825 10.8271 2.61024 10.9945 2.49123 11.2047L3.13421 11.5687L2.49122 11.2047C2.37221 11.4149 2.31515 11.6451 2.26209 11.8591C2.25776 11.8766 2.25346 11.894 2.24915 11.9112L1.47451 15.0098C1.47189 15.0202 1.46921 15.0309 1.46649 15.0417C1.42867 15.1925 1.38229 15.3774 1.36665 15.5373C1.34897 15.718 1.34739 16.0745 1.63649 16.3636C1.92558 16.6527 2.28209 16.6511 2.4628 16.6335C2.62275 16.6178 2.80761 16.5714 2.9584 16.5336C2.96923 16.5309 2.97989 16.5282 2.99035 16.5256L6.08892 15.751C6.10615 15.7467 6.12351 15.7424 6.14098 15.738C6.35504 15.685 6.58522 15.6279 6.79542 15.5089C7.00562 15.3899 7.17297 15.2219 7.3286 15.0656C7.3413 15.0528 7.35393 15.0402 7.36649 15.0276L15.5606 6.83354L15.5922 6.8019C15.8983 6.49589 16.1789 6.21532 16.3765 5.95637C16.5941 5.67121 16.7802 5.32602 16.7802 4.889C16.7802 4.45197 16.5941 4.10678 16.3765 3.82162C16.1789 3.56268 15.8983 3.28211 15.5922 2.9761L15.5606 2.94445L15.0557 2.43955L15.024 2.4079C14.718 2.10182 14.4374 1.82118 14.1785 1.62361C13.8933 1.40604 13.5481 1.21988 13.1111 1.21988C12.6741 1.21988 12.3289 1.40604 12.0437 1.62361C11.7848 1.82118 11.5042 2.10182 11.1982 2.4079L11.1666 2.43955Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
            <path d="M11.1666 2.43955L2.9725 10.6336C2.95993 10.6462 2.94726 10.6588 2.93451 10.6715C2.77825 10.8271 2.61024 10.9945 2.49123 11.2047L3.13421 11.5687L2.49122 11.2047C2.37221 11.4149 2.31515 11.6451 2.26209 11.8591C2.25776 11.8766 2.25346 11.894 2.24915 11.9112L1.47451 15.0098C1.47189 15.0202 1.46921 15.0309 1.46649 15.0417C1.42867 15.1925 1.38229 15.3774 1.36665 15.5373C1.34897 15.718 1.34739 16.0745 1.63649 16.3636C1.92558 16.6527 2.28209 16.6511 2.4628 16.6335C2.62275 16.6178 2.80761 16.5714 2.9584 16.5336C2.96923 16.5309 2.97989 16.5282 2.99035 16.5256L6.08892 15.751C6.10615 15.7467 6.12351 15.7424 6.14098 15.738C6.35504 15.685 6.58522 15.6279 6.79542 15.5089C7.00562 15.3899 7.17297 15.2219 7.3286 15.0656C7.3413 15.0528 7.35393 15.0402 7.36649 15.0276L15.5606 6.83354L15.5922 6.8019C15.8983 6.49589 16.1789 6.21532 16.3765 5.95637C16.5941 5.67121 16.7802 5.32602 16.7802 4.889C16.7802 4.45197 16.5941 4.10678 16.3765 3.82162C16.1789 3.56268 15.8983 3.28211 15.5922 2.9761L15.5606 2.94445L15.0557 2.43955L15.024 2.4079C14.718 2.10182 14.4374 1.82118 14.1785 1.62361C13.8933 1.40604 13.5481 1.21988 13.1111 1.21988C12.6741 1.21988 12.3289 1.40604 12.0437 1.62361C11.7848 1.82118 11.5042 2.10182 11.1982 2.4079L11.1666 2.43955Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
            <path d="M11.1666 2.43955L2.9725 10.6336C2.95993 10.6462 2.94726 10.6588 2.93451 10.6715C2.77825 10.8271 2.61024 10.9945 2.49123 11.2047L3.13421 11.5687L2.49122 11.2047C2.37221 11.4149 2.31515 11.6451 2.26209 11.8591C2.25776 11.8766 2.25346 11.894 2.24915 11.9112L1.47451 15.0098C1.47189 15.0202 1.46921 15.0309 1.46649 15.0417C1.42867 15.1925 1.38229 15.3774 1.36665 15.5373C1.34897 15.718 1.34739 16.0745 1.63649 16.3636C1.92558 16.6527 2.28209 16.6511 2.4628 16.6335C2.62275 16.6178 2.80761 16.5714 2.9584 16.5336C2.96923 16.5309 2.97989 16.5282 2.99035 16.5256L6.08892 15.751C6.10615 15.7467 6.12351 15.7424 6.14098 15.738C6.35504 15.685 6.58522 15.6279 6.79542 15.5089C7.00562 15.3899 7.17297 15.2219 7.3286 15.0656C7.3413 15.0528 7.35393 15.0402 7.36649 15.0276L15.5606 6.83354L15.5922 6.8019C15.8983 6.49589 16.1789 6.21532 16.3765 5.95637C16.5941 5.67121 16.7802 5.32602 16.7802 4.889C16.7802 4.45197 16.5941 4.10678 16.3765 3.82162C16.1789 3.56268 15.8983 3.28211 15.5922 2.9761L15.5606 2.94445L15.0557 2.43955L15.024 2.4079C14.718 2.10182 14.4374 1.82118 14.1785 1.62361C13.8933 1.40604 13.5481 1.21988 13.1111 1.21988C12.6741 1.21988 12.3289 1.40604 12.0437 1.62361C11.7848 1.82118 11.5042 2.10182 11.1982 2.4079L11.1666 2.43955Z" stroke="black" strokeOpacity="0.2" strokeWidth="1.5" />
            <path d="M10.332 3.22222L13.6654 1L16.9987 4.33333L14.7765 7.66667L10.332 3.22222Z" fill="black" />
            <path d="M10.332 3.22222L13.6654 1L16.9987 4.33333L14.7765 7.66667L10.332 3.22222Z" fill="black" fillOpacity="0.2" />
            <path d="M10.332 3.22222L13.6654 1L16.9987 4.33333L14.7765 7.66667L10.332 3.22222Z" fill="black" fillOpacity="0.2" />
            <path d="M10.332 3.22222L13.6654 1L16.9987 4.33333L14.7765 7.66667L10.332 3.22222Z" fill="black" fillOpacity="0.2" />
            <path d="M10.332 3.22222L13.6654 1L16.9987 4.33333L14.7765 7.66667L10.332 3.22222Z" fill="black" fillOpacity="0.2" />
        </svg>
    </svg>
)